.una-returns-container {
    padding: 25px;
    margin-top: 20px;
  }
  
  .una-returns-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  }
  
  .una-returns-title {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--neon-cyan);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.4);
  }
  
  .una-returns-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 16px;
    font-size: 14px;
  }
  
  .una-returns-table thead {
    background: rgba(13, 14, 26, 0.7);
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .una-returns-table th {
    text-align: left;
    padding: 16px;
    color: var(--neon-cyan);
    font-weight: 600;
    letter-spacing: 0.5px;
    border-bottom: 2px solid rgba(0, 255, 255, 0.2);
    white-space: nowrap;
    font-size: 13px;
    text-transform: uppercase;
  }
  
  .una-returns-table td {
    padding: 16px;
    background: rgba(13, 14, 26, 0.5);
    color: rgba(255, 255, 255, 0.85);
    border-bottom: 1px solid rgba(0, 255, 157, 0.05);
    text-align: left;
    vertical-align: middle;
    transition: background 0.2s ease;
  }
  
  .una-returns-table tr:hover td {
    background: rgba(0, 255, 157, 0.05);
    cursor: pointer;
  }
  
  .una-return-product {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .una-return-product-image {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid rgba(0, 255, 157, 0.2);
  }
  
  .una-return-status {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .una-return-status-pending {
    background: rgba(255, 166, 0, 0.1);
    color: #ffa600;
    border: 1px solid rgba(255, 166, 0, 0.2);
  }
  
  .una-return-status-approved {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-green);
    border: 1px solid rgba(0, 255, 157, 0.2);
  }
  
  .una-return-status-rejected {
    background: rgba(255, 62, 62, 0.1);
    color: #ff3e3e;
    border: 1px solid rgba(255, 62, 62, 0.2);
  }
  
  .una-returns-error {
    color: var(--neon-magenta);
    text-shadow: 0 0 5px rgba(255, 0, 255, 0.4);
    text-align: center;
    padding: 20px;
    background: rgba(255, 0, 255, 0.1);
    border-radius: 12px;
    border: 1px solid rgba(255, 0, 255, 0.2);
  }
  
  .una-returns-loading,
  .una-returns-empty {
    text-align: center;
    padding: 20px;
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    background: rgba(0, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 255, 0.1);
  }

  .una-returns-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
  }

  .una-return-card {
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .una-return-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 255, 157, 0.2);
    border-color: rgba(0, 255, 157, 0.3);
  }

  .una-return-card:focus {
    outline: none;
    border-color: var(--neon-cyan);
    box-shadow: 0 0 0 2px rgba(0, 255, 255, 0.3);
  }

  .una-return-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .una-return-date {
    color: var(--neon-cyan);
    font-size: 0.9em;
  }

  .una-return-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .una-return-item {
    padding: 10px;
    border-radius: 8px;
    background: rgba(0, 255, 157, 0.05);
  }

  .una-return-item-details h3 {
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 8px;
  }

  .una-return-item-details p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9em;
    margin: 4px 0;
  }

  .una-return-tracking {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 255, 157, 0.1);
  }

  .una-return-tracking p {
    color: var(--neon-cyan);
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .una-return-label-btn {
    display: inline-block;
    padding: 8px 16px;
    background: rgba(0, 255, 157, 0.1);
    border: 1px solid rgba(0, 255, 157, 0.2);
    border-radius: 8px;
    color: var(--neon-green);
    text-decoration: none;
    font-size: 0.9em;
    transition: all 0.3s ease;
  }

  .una-return-label-btn:hover {
    background: rgba(0, 255, 157, 0.2);
    transform: translateY(-1px);
  }

  .una-status-badge.shipped {
    background-color: #27ae60;
    color: white;
  }

  .una-returns-loading-screen {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    border-radius: 20px;
    padding: 25px;
    margin: 20px auto;
    max-width: 1400px;
  }

  .una-loading-content {
    text-align: center;
    padding: 40px;
    width: 100%;
    max-width: 600px;
  }

  .una-loading-icons {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto 30px;
  }

  .una-loading-icons svg {
    position: absolute;
    width: 60px;
    height: 60px;
    filter: drop-shadow(0 0 10px currentColor);
  }

  .una-loading-icons .icon-primary {
    top: 0;
    left: 30px;
    color: var(--neon-cyan);
    animation: floatIcon 3s infinite;
  }

  .una-loading-icons .icon-secondary {
    bottom: 0;
    left: 0;
    color: var(--neon-green);
    animation: floatIcon 3s infinite 1s;
  }

  .una-loading-icons .icon-tertiary {
    bottom: 0;
    right: 0;
    color: var(--neon-magenta);
    animation: floatIcon 3s infinite 2s;
  }

  .una-loading-content h2 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 2em;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .una-loading-progress {
    width: 100%;
    height: 4px;
    background: rgba(0, 255, 157, 0.1);
    border-radius: 2px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;
  }

  .una-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    background: var(--neon-cyan);
    animation: progress 2s infinite;
  }

  .una-progress-glow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
    animation: glow 1.5s infinite;
  }

  .una-loading-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }

  .una-metric-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    color: rgba(255, 255, 255, 0.8);
    animation: fadeIn 0.5s ease-out forwards;
    opacity: 0;
  }

  .una-metric-item svg {
    color: var(--neon-cyan);
    font-size: 1.2em;
  }

  .una-metric-item:nth-child(1) { animation-delay: 0.2s; }
  .una-metric-item:nth-child(2) { animation-delay: 0.4s; }
  .una-metric-item:nth-child(3) { animation-delay: 0.6s; }
  .una-metric-item:nth-child(4) { animation-delay: 0.8s; }

  @keyframes floatIcon {
    0%, 100% { 
      transform: translateY(0) scale(1); 
      opacity: 1;
    }
    50% { 
      transform: translateY(-10px) scale(1.1); 
      opacity: 0.8;
    }
  }

  @keyframes progress {
    0% { left: -30%; }
    100% { left: 100%; }
  }

  @keyframes glow {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }

  @keyframes fadeIn {
    from { 
      opacity: 0;
      transform: translateY(10px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Table controls */
  .table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filters-container {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .status-filter label {
    margin-right: 10px;
    color: var(--neon-cyan);
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .status-filter select {
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(0, 255, 157, 0.2);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    transition: all 0.2s ease;
    min-width: 180px;
  }

  .status-filter select:focus {
    border-color: var(--neon-green);
    box-shadow: 0 0 0 2px rgba(0, 255, 157, 0.2);
    outline: none;
  }

  /* Status badges */
  .status-badge {
    padding: 5px 10px;
    border-radius: 12px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.7px;
    display: inline-block;
    text-align: center;
    min-width: 110px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  .status-badge.pending {
    background: rgba(255, 166, 0, 0.1);
    color: #ffa600;
    border: 1px solid rgba(255, 166, 0, 0.2);
  }

  .status-badge.approved {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-green);
    border: 1px solid rgba(0, 255, 157, 0.2);
  }

  .status-badge.rejected {
    background: rgba(255, 62, 62, 0.1);
    color: #ff3e3e;
    border: 1px solid rgba(255, 62, 62, 0.2);
  }

  .status-badge.completed {
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-cyan);
    border: 1px solid rgba(0, 255, 255, 0.2);
  }

  /* Pagination */
  .pagination {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .pagination button {
    background: transparent;
    border: 1px solid var(--neon-cyan);
    color: var(--neon-cyan);
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
  }

  .pagination button:hover:not(:disabled) {
    background: rgba(0, 255, 255, 0.1);
    transform: translateY(-1px);
  }

  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .pagination span {
    color: var(--neon-cyan);
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 500;
  }

  /* Table cell styling */
  .primary-info {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0.2px;
  }

  .secondary-info {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
  }

  .tertiary-info {
    font-weight: 400;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
  }

  .date-info {
    white-space: nowrap;
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  .visual-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  .no-tracking {
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
    font-size: 13px;
  }

  .una-action-btn {
    display: inline-block;
    padding: 8px 16px;
    background: rgba(0, 255, 255, 0.1);
    border: 1px solid rgba(0, 255, 255, 0.2);
    border-radius: 8px;
    color: var(--neon-cyan);
    text-decoration: none;
    font-size: 0.9em;
    transition: all 0.3s ease;
  }

  .una-action-btn:hover {
    background: rgba(0, 255, 255, 0.2);
    transform: translateY(-1px);
  }

  /* Empty state */
  .una-returns-empty-state {
    text-align: center;
    padding: 50px 20px;
    color: var(--neon-cyan);
    background: rgba(13, 14, 26, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 255, 0.1);
    margin-top: 20px;
  }

  .una-returns-empty-state .empty-icon {
    font-size: 48px;
    margin-bottom: 20px;
    color: var(--neon-cyan);
    opacity: 0.7;
  }

  .una-returns-empty-state p {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.8);
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .table-controls {
      flex-wrap: wrap;
      gap: 16px;
    }
    
    .filters-container {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 992px) {
    .una-returns-table {
      display: block;
      overflow-x: auto;
    }
  }

  @media (max-width: 768px) {
    .table-controls {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .status-filter {
      width: 100%;
      margin-bottom: 12px;
    }
    
    .status-filter select {
      width: 100%;
    }
    
    .pagination {
      margin-top: 16px;
      width: 100%;
      justify-content: space-between;
    }
    
    .una-returns-table th,
    .una-returns-table td {
      padding: 12px 10px;
      font-size: 14px;
    }
  }