:root {
  --message-sent: linear-gradient(135deg, rgba(98, 0, 234, 0.1), rgba(98, 0, 234, 0.2));
  --message-received: linear-gradient(135deg, rgba(26, 26, 46, 0.7), rgba(26, 26, 46, 0.8));
  --message-automated: linear-gradient(135deg, rgba(255, 152, 0, 0.1), rgba(255, 152, 0, 0.2));
}

.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 20px;
  gap: 20px;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

.message-window-layout {
  flex: 1 1 70% !important;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 20px;
  overflow: hidden;
  min-width: 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  max-width: 70% !important;
  transition: all 0.3s ease;
  position: relative;
}

.message-window-layout::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  pointer-events: none;
  box-shadow: inset 0 0 0 1px rgba(0, 255, 255, 0.15);
}

.message-window-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  position: relative;
}

.message-window-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  pointer-events: none;
  background: radial-gradient(circle at top right, rgba(0, 255, 255, 0.05), transparent 70%);
}

.message-window-messages {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px;
  min-height: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 255, 255, 0.3) rgba(26, 26, 46, 0.7);
}

.message-window-messages .message {
  position: relative;
  padding: 15px 20px;
  border-radius: 12px;
  margin-bottom: 15px;
  max-width: 75%;
  line-height: 1.5;
  font-size: 0.9rem;
  letter-spacing: 0.01rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  border: none;
}

.message-window-messages .message:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.message-window-messages .message.sent {
  background: linear-gradient(135deg, rgba(26, 26, 46, 0.7), rgba(26, 26, 46, 0.8));
  margin-left: auto;
  color: var(--text-color);
  position: relative;
}

.message-window-messages .message.sent::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  pointer-events: none;
  box-shadow: inset 0 0 0 1px rgba(98, 0, 234, 0.2);
}

.message-window-messages .message.received {
  background: linear-gradient(135deg, rgba(0, 255, 255, 0.08), rgba(0, 255, 255, 0.05));
  margin-right: auto;
  color: var(--text-color);
  position: relative;
}

.message-window-messages .message.received::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  pointer-events: none;
  box-shadow: inset 0 0 0 1px rgba(0, 255, 255, 0.15);
}

.message-window-messages .message.automated {
  background: linear-gradient(135deg, rgba(255, 152, 0, 0.08), rgba(255, 152, 0, 0.05));
  margin-right: auto;
  color: var(--text-color);
  position: relative;
}

.message-window-messages .message.automated::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  pointer-events: none;
  box-shadow: inset 0 0 0 1px rgba(255, 152, 0, 0.15);
}

/* Message tails refinement */
.message-window-messages .message.sent::after {
  content: '';
  position: absolute;
  bottom: 8px;
  right: -8px;
  width: 16px;
  height: 16px;
  background: rgba(26, 26, 46, 0.8);
  transform: rotate(-45deg);
  clip-path: polygon(0 0, 100% 100%, 0 100%);
}

.message-window-messages .message.received::after {
  content: '';
  position: absolute;
  bottom: 8px;
  left: -8px;
  width: 16px;
  height: 16px;
  background: rgba(0, 255, 255, 0.05);
  transform: rotate(45deg);
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.message-window-messages .message.automated::after {
  content: '';
  position: absolute;
  bottom: 8px;
  left: -8px;
  width: 16px;
  height: 16px;
  background: rgba(255, 152, 0, 0.05);
  transform: rotate(45deg);
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

/* Improved message typography */
.message-content {
  max-width: 100%;
  color: var(--text-color);
  line-height: 1.6;
  font-size: 0.95rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
}

/* Message metadata improvements */
.message-meta {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 6px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.message-time {
  opacity: 0.8;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
}

.message-status-badge {
  font-size: 0.65rem;
  padding: 2px 6px;
  border-radius: 10px;
  background-color: rgba(255, 152, 0, 0.2);
  color: var(--neon-orange);
  margin-bottom: 0;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid var(--neon-orange);
}

.side-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 30%;
  max-width: 30%;
  background: rgba(26, 26, 46, 0.8);
  border: none;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  position: relative;
}

.side-panel::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  pointer-events: none;
  box-shadow: inset 0 0 0 1px rgba(255, 0, 255, 0.15);
}

.input-container {
  padding: 20px !important;
  background: rgba(26, 26, 46, 0.9) !important;
  border-top: 1px solid rgba(0, 255, 255, 0.1) !important;
  min-width: 0 !important;
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
}

.message-window-textarea {
  flex: 1 !important;
  min-height: 40px !important;
  max-height: 120px !important;
  padding: 12px 15px !important;
  background: rgba(26, 26, 46, 0.7) !important;
  border: 1px solid rgba(0, 255, 255, 0.15) !important;
  border-radius: 12px !important;
  resize: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--text-color) !important;
  transition: all 0.3s ease !important;
  box-shadow: none !important;
}

.message-window-textarea:focus {
  outline: none !important;
  background: rgba(26, 26, 46, 0.9) !important;
  border-color: rgba(0, 255, 255, 0.3) !important;
  box-shadow: 0 0 0 1px rgba(0, 255, 255, 0.1) !important;
}

.message-window-action-button {
  padding: 10px 20px !important;
  height: 40px !important;
  border-radius: 12px !important;
  border: none !important;
  background: rgba(26, 26, 46, 0.7) !important;
  color: var(--text-color) !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  position: relative !important;
}

.message-window-action-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  pointer-events: none;
  box-shadow: inset 0 0 0 1px rgba(0, 255, 255, 0.15);
  transition: all 0.3s ease;
}

.message-window-action-button:hover {
  background: rgba(0, 255, 255, 0.1) !important;
  transform: translateY(-2px) !important;
}

.message-window-action-button:hover::before {
  box-shadow: inset 0 0 0 1px rgba(0, 255, 255, 0.3);
}

/* Send button refinement */
.message-window-send {
  color: var(--neon-green) !important;
}

.message-window-send::before {
  box-shadow: inset 0 0 0 1px rgba(0, 255, 157, 0.15);
}

.message-window-send:hover {
  background: rgba(0, 255, 157, 0.1) !important;
}

.message-window-send:hover::before {
  box-shadow: inset 0 0 0 1px rgba(0, 255, 157, 0.3);
}

/* AI help button refinement */
.message-window-ai-help {
  color: var(--neon-magenta) !important;
}

.message-window-ai-help::before {
  box-shadow: inset 0 0 0 1px rgba(255, 0, 255, 0.15);
}

.message-window-ai-help:hover {
  background: rgba(255, 0, 255, 0.1) !important;
}

.message-window-ai-help:hover::before {
  box-shadow: inset 0 0 0 1px rgba(255, 0, 255, 0.3);
}

.response-actions {
  display: flex !important;
  gap: 8px !important;
  flex-shrink: 0 !important;
}

.ticket-actions {
  display: flex !important;
  gap: 10px !important;
  padding: 15px 20px !important;
  border-top: 1px solid #e0e0e0 !important;
}


.tab-buttons {
  flex: 0 0 auto;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.tab-button {
  flex: 1;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
  font-weight: 500;
}


.tab-button.active {
  color: #6200ea;
  border-bottom: 2px solid #6200ea;
}

.message-window-action-button {
  padding: 8px 12px !important;
  height: 40px !important;
  border-radius: 8px !important;
  border: none !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  white-space: nowrap !important;
}

.message-window-resolve {
  background-color: #28a745 !important;
  color: white !important;
}

.message-window-resolve:hover {
  background-color: #218838 !important;
  box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2) !important;
}

.message-window-close {
  background-color: #dc3545 !important;
  color: white !important;
}

.message-window-close:hover {
  background-color: #c82333 !important;
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2) !important;
}

.ticket-header {
  padding: 16px 24px !important;
  background: rgba(26, 26, 46, 0.85) !important;
  border-bottom: 1px solid rgba(0, 255, 255, 0.15) !important;
  position: relative !important;
  backdrop-filter: blur(8px) !important;
  overflow: visible !important;
}

.ticket-title-row {
  display: flex !important;
  align-items: center !important;
  gap: 16px !important;
  margin-bottom: 14px !important;
}

.ticket-title-container {
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  gap: 16px !important;
  min-width: 0 !important;
}

.ticket-header h2 {
  margin: 0 !important;
  padding: 10px 16px !important;
  background: rgba(26, 26, 46, 0.6) !important;
  border: none !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  backdrop-filter: blur(5px) !important;
  flex: 1 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  letter-spacing: 0.3px !important;
  position: relative !important;
}

/* Subtle glow effect for the title */
.ticket-header h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 255, 255, 0.2);
  pointer-events: none;
}

.header-actions {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  margin-left: auto !important;
  flex-shrink: 0 !important;
  overflow: visible !important;
  position: relative;
}

.ticket-file-indicators {
  display: flex !important;
  gap: 8px !important;
  flex-wrap: nowrap !important;
  position: relative;
  overflow: visible !important;
}

.ticket-meta {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
  flex-wrap: wrap;
}

.ticket-status {
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(26, 26, 46, 0.5);
  border: none;
  box-shadow: none;
  transition: all 0.2s ease;
}

/* Status-specific styling with more subtle indicators */
.ticket-status.pending {
  color: var(--neon-blue);
  border-left: 2px solid var(--neon-blue);
}

.ticket-status.automated {
  color: var(--neon-cyan);
  border-left: 2px solid var(--neon-cyan);
}

.ticket-status.resolved {
  color: var(--neon-green);
  border-left: 2px solid var(--neon-green);
}

.ticket-status.closed {
  color: var(--neon-magenta);
  border-left: 2px solid var(--neon-magenta);
}

.ticket-status.ai_resolved {
  color: var(--neon-orange);
  border-left: 2px solid var(--neon-orange);
}

/* Employee badge refinement */
.ticket-employee {
  padding: 6px 12px;
  background: rgba(26, 26, 46, 0.5);
  border: none;
  border-left: 2px solid var(--neon-cyan);
  border-radius: 6px;
  font-weight: 500;
  font-size: 11px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.language-selector-container {
  display: none;
}

.translating-message {
  color: #666;
  font-size: 14px;
  font-style: italic;
}

/* Ensure flex items don't overflow their containers */
.message-window-layout, .message-window-content, .message-window-messages, .input-container {
  min-width: 0;
}

.action-menu-container {
  position: relative !important;
  margin-left: auto !important; /* Push to the right */
}

.action-menu-trigger {
  padding: 6px 12px;
  background: rgba(26, 26, 46, 0.5);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 11px;
  transition: all 0.2s ease;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.action-menu-trigger:hover {
  background: rgba(0, 255, 255, 0.08);
  color: var(--neon-cyan);
}

.action-menu {
  position: absolute !important;
  top: 0 !important;
  right: 100% !important;
  margin-right: 8px !important;
  background: rgba(26, 26, 46, 0.95) !important;
  border: 1px solid rgba(0, 255, 255, 0.15) !important;
  border-radius: 8px !important;
  min-width: 200px !important;
  z-index: 1000 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(10px) !important;
  animation: menuAppear 0.3s ease !important;
  overflow: hidden !important;
}

.action-menu button,
.action-menu-item {
  width: 100%;
  padding: 12px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-size: 12px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.action-menu button:hover,
.action-menu-item:hover {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.language-submenu {
  position: absolute;
  left: -100% !important;
  top: 0;
  margin-left: -10px !important;
  background: rgba(26, 26, 46, 0.95);
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  min-width: 150px;
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(10px);
  animation: menuAppear 0.3s ease;
}

.draft-actions {
  display: flex !important;
  gap: 8px !important;
  margin-top: 8px !important;
}

.draft-message-button {
  padding: 8px 16px !important;
  border-radius: 12px !important;
  background: rgba(26, 26, 46, 0.8) !important;
  border: 1px solid var(--neon-cyan) !important;
  color: var(--text-color) !important;
  font-size: 12px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  backdrop-filter: blur(5px) !important;
}

.draft-message-button:hover {
  transform: translateY(-2px) !important;
}

.draft-message-button.send {
  border-color: var(--neon-green) !important;
  color: var(--neon-green) !important;
  box-shadow: var(--glow-green) !important;
}

.draft-message-button.send:hover {
  background: rgba(0, 255, 157, 0.1) !important;
}

.draft-message-button.delete {
  border-color: var(--neon-magenta) !important;
  color: var(--neon-magenta) !important;
  box-shadow: var(--glow-magenta) !important;
}

.draft-message-button.delete:hover {
  background: rgba(255, 0, 255, 0.1) !important;
}

.draft-message-button.modify {
  border-color: var(--neon-cyan) !important;
  color: var(--neon-cyan) !important;
  box-shadow: var(--glow-cyan) !important;
}

.draft-message-button.modify:hover {
  background: rgba(0, 255, 255, 0.1) !important;
}

/* Animations */
@keyframes headerGlow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes menuAppear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tab-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.pdf-preview-link {
    display: inline-block;
    margin: 10px 0;
    text-decoration: none;
    color: var(--neon-cyan);
    transition: transform 0.2s ease;
}

.pdf-preview-link:hover {
    transform: translateY(-2px);
}

.pdf-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 120px;
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    margin-bottom: 5px;
    background: rgba(26, 26, 46, 0.8);
    box-shadow: var(--glow-cyan);
    transition: all 0.3s ease;
}

.pdf-preview:hover {
    background: rgba(0, 255, 255, 0.1);
}

.pdf-preview i {
    font-size: 40px;
    margin-bottom: 8px;
}

.pdf-preview .pdf-text {
    font-size: 14px;
    font-weight: 500;
}

.ticket-files {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.file-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.file-button:hover {
    background-color: #e0e0e0;
}

.file-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.file-overlay-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    height: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.file-overlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.file-overlay-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    padding: 4px;
}

.file-overlay-body {
    flex: 1;
    padding: 16px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-overlay-body img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.file-download {
    text-align: center;
}

.download-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 16px;
}

.download-button:hover {
    background-color: #0056b3;
}

.file-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.file-overlay-content {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.close-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.context-tab {
    padding: 20px !important;
    height: 100% !important;
    overflow-y: auto !important;
    background: rgba(26, 26, 46, 0.8) !important;
}

.context-content {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
}

.context-section {
    background: rgba(26, 26, 46, 0.9) !important;
    padding: 20px !important;
    border-radius: 12px !important;
    border: 1px solid var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(10px) !important;
    transition: all 0.3s ease !important;
}

.context-section:hover {
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3) !important;
    transform: translateY(-2px) !important;
}

.context-section h3 {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    border-bottom: 1px solid var(--neon-cyan) !important;
    padding-bottom: 10px !important;
    text-shadow: var(--glow-cyan) !important;
}

.context-section h4 {
    color: var(--neon-magenta) !important;
    font-size: 13px !important;
    margin: 12px 0 8px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-shadow: var(--glow-magenta) !important;
}

.context-section p {
    margin: 8px 0 !important;
    font-size: 13px !important;
    color: var(--text-color) !important;
    line-height: 1.5 !important;
}

.context-section strong {
    color: var(--neon-purple) !important;
    font-weight: 500 !important;
    text-shadow: var(--glow-purple) !important;
}

.ticket-indicators {
    display: flex !important;
    gap: 15px !important;
    margin-top: 15px !important;
    flex-wrap: wrap !important;
}

.ticket-indicator {
    padding: 8px 16px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 12px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    backdrop-filter: blur(5px) !important;
    transition: all 0.3s ease !important;
}

.ticket-indicator.tracking {
    border-color: var(--neon-cyan) !important;
    color: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
}

.ticket-indicator.client {
    border-color: var(--neon-cyan) !important;
    color: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
}

.ticket-indicator.carrier {
    border-color: var(--neon-yellow) !important;
    color: var(--neon-yellow) !important;
    box-shadow: var(--glow-yellow) !important;
}

.ticket-indicator.status {
    border-color: var(--neon-green) !important;
    color: var(--neon-green) !important;
    box-shadow: var(--glow-green) !important;
}

.ticket-indicator.status.off {
    border-color: var(--neon-magenta) !important;
    color: var(--neon-magenta) !important;
    box-shadow: var(--glow-magenta) !important;
    opacity: 0.7 !important;
}

.ticket-indicator:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.2) !important;
}

.ticket-indicator i {
    font-size: 14px !important;
}

/* Add new styles for file indicators */
.ticket-file-indicators {
    display: flex !important;
    gap: 8px !important;
    flex-wrap: nowrap !important;
}

.ticket-file-indicator {
    padding: 8px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-purple) !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    box-shadow: var(--glow-purple) !important;
    position: relative !important;
    z-index: 8000;
    margin: 0 8px;
    overflow: visible !important;
}

.ticket-file-indicator:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.2) !important;
}

.ticket-file-indicator svg {
    font-size: 18px !important;
    color: var(--neon-purple) !important;
}

.ticket-file-indicator.pdf {
    border-color: var(--neon-magenta) !important;
}

.ticket-file-indicator.pdf svg {
    color: var(--neon-magenta) !important;
}

.ticket-file-indicator.image {
    border-color: var(--neon-cyan) !important;
}

.ticket-file-indicator.image svg {
    color: var(--neon-cyan) !important;
}

.ticket-file-indicator.document {
    border-color: var(--neon-blue) !important;
}

.ticket-file-indicator.document svg {
    color: var(--neon-blue) !important;
}

.ticket-file-tooltip {
    position: absolute !important;
    top: 100% !important;
    transform: translateX(-50%) !important;
    z-index: 9999 !important; /* Ensure tooltip appears above all elements */
    pointer-events: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease !important;
    background-color: rgba(26, 26, 46, 0.95);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    padding: 8px 12px;
    box-shadow: var(--glow-cyan);
    backdrop-filter: blur(5px);
    width: max-content;
    max-width: 250px;
}

.ticket-file-tooltip::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 6px 6px 6px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.85) transparent;
}

.ticket-file-tooltip .tooltip-filename {
    margin: 0 0 4px 0;
    font-weight: bold;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 4px;
}

.file-metadata-content {
    font-size: 0.9em;
}

.metadata-item {
    margin: 3px 0;
    line-height: 1.4;
}

.metadata-item strong {
    font-weight: 600;
    margin-right: 4px;
}

.warehouse-tab {
    padding: 1rem;
}

.warehouse-email-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.warehouse-input,
.warehouse-textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.warehouse-textarea {
    resize: vertical;
    min-height: 100px;
}

.warehouse-submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
}

.warehouse-submit-button:hover {
    background-color: #0056b3;
}

.warehouse-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* User Tab Styles */
.user-tab {
    padding: 20px !important;
    height: 100% !important;
    overflow-y: auto !important;
    background: rgba(26, 26, 46, 0.8) !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
}

.user-section {
    background: rgba(26, 26, 46, 0.9) !important;
    padding: 20px !important;
    border-radius: 12px !important;
    backdrop-filter: blur(10px) !important;
    transition: all 0.3s ease !important;
}

.user-section:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3) !important;
}

.user-section h3 {
    margin: 0 0 15px 0 !important;
    padding-bottom: 10px !important;
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    border-bottom: 1px solid var(--neon-cyan) !important;
    text-shadow: var(--glow-cyan) !important;
}

.user-section h4 {
    margin: 15px 0 10px 0 !important;
    color: var(--neon-magenta) !important;
    font-size: 13px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-shadow: var(--glow-magenta) !important;
}

.user-section p {
    margin: 8px 0 !important;
    font-size: 13px !important;
    color: var(--text-color) !important;
    line-height: 1.5 !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}

.user-section strong {
    color: var(--neon-purple) !important;
    font-weight: 500 !important;
    text-shadow: var(--glow-purple) !important;
    min-width: 100px !important;
}

.user-section:nth-child(1) {
    border-color: var(--neon-magenta) !important;
    box-shadow: var(--glow-magenta) !important;
}

.user-section:nth-child(2) {
    border-color: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
}

.user-section:nth-child(3) {
    border-color: var(--neon-green) !important;
    box-shadow: var(--glow-green) !important;
}

/* Update the icon styling to target both p and div elements */
.user-section p svg,
.user-section .package-content svg {
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    opacity: 0.8 !important;
}

/* Package Content Hover Effect */
.package-content {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}

.package-content-truncated {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 200px !important;
    cursor: pointer !important;
    color: var(--text-color) !important;
}

.package-content-full {
    position: absolute !important;
    left: 0 !important;
    top: 100% !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 8px !important;
    padding: 12px !important;
    min-width: 250px !important;
    max-width: 400px !important;
    z-index: 1000 !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(10px) !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: all 0.3s ease !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    color: var(--text-color) !important;
}

.package-content:hover .package-content-full {
    visibility: visible !important;
    opacity: 1 !important;
    transform: translateY(5px) !important;
}

.track-tab {
    padding: 20px !important;
    height: 100% !important;
    overflow-y: auto !important;
    background: rgba(26, 26, 46, 0.8) !important;
}


.tracking-section h3 {
    margin: 0 0 15px 0 !important;
    padding-bottom: 10px !important;
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    border-bottom: 1px solid var(--neon-cyan) !important;
    text-shadow: var(--glow-cyan) !important;
}

.tracking-section h4 {
    color: var(--neon-magenta) !important;
    font-size: 13px !important;
    margin: 15px 0 10px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-shadow: var(--glow-magenta) !important;
}

.tracking-section p {
    margin: 8px 0 !important;
    padding: 8px 16px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-purple) !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    color: var(--text-color) !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    box-shadow: var(--glow-purple) !important;
}

.tracking-section strong {
    color: var(--neon-purple) !important;
    font-weight: 500 !important;
    text-shadow: var(--glow-purple) !important;
    min-width: 120px !important;
}

.tracking-events {
    position: relative !important;
    padding: 20px 0 !important;
    margin-left: 20px !important;
}

/* Neon path line */
.tracking-events::before {
    content: '' !important;
    position: absolute !important;
    left: 15px !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 2px !important;
    background: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
    z-index: 1 !important;
}

.tracking-event {
    position: relative !important;
    margin: 30px 0 !important;
    padding-left: 45px !important;
}

/* Neon dot for each event */
.tracking-event::before {
    content: '' !important;
    position: absolute !important;
    left: 8px !important;
    top: 50% !important;
    width: 16px !important;
    height: 16px !important;
    background: var(--neon-cyan) !important;
    border-radius: 50% !important;
    transform: translateY(-50%) !important;
    box-shadow: var(--glow-cyan) !important;
    z-index: 2 !important;
}

.event-date {
    font-size: 12px !important;
    color: var(--neon-magenta) !important;
    margin-bottom: 4px !important;
    text-shadow: var(--glow-magenta) !important;
    font-weight: 500 !important;
}

.event-location {
    font-size: 13px !important;
    color: var(--neon-cyan) !important;
    margin-bottom: 4px !important;
    text-shadow: var(--glow-cyan) !important;
}

.event-status {
    font-size: 14px !important;
    color: var(--neon-cyan) !important;
    padding: 12px 20px !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-magenta) !important;
    border-radius: 8px !important;
    box-shadow: var(--glow-magenta) !important;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5) !important;
    letter-spacing: 0.5px !important;
    font-weight: 500 !important;
    transition: all 0.3s ease !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    max-width: 100% !important;
}

.event-status:hover {
    background: rgba(26, 26, 46, 0.8) !important;
    transform: translateY(-2px) !important;
    box-shadow: 0 0 15px var(--neon-magenta) !important;
}

/* Animation for events */
.tracking-event {
  animation: eventAppear 0.5s ease forwards;
  opacity: 1;
  transform: translateX(-20px);
}


@keyframes eventAppear {
    to {
        opacity: 1 !important;
        transform: translateX(0) !important;
    }
}

/* Stagger animation delay for events */
.tracking-event:nth-child(1) { animation-delay: 0.1s !important; }
.tracking-event:nth-child(2) { animation-delay: 0.2s !important; }
.tracking-event:nth-child(3) { animation-delay: 0.3s !important; }
.tracking-event:nth-child(4) { animation-delay: 0.4s !important; }
.tracking-event:nth-child(5) { animation-delay: 0.5s !important; }

.file-metadata-tooltip {
    position: absolute !important;
    z-index: 9999 !important;
    pointer-events: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease !important;
}

.ticket-file-indicator:hover .file-metadata-tooltip {
    visibility: visible !important;
    opacity: 1 !important;
}

.metadata-title, .metadata-description {
    margin-bottom: 8px !important;
    line-height: 1.4 !important;
    color: var(--text-color) !important;
}

.metadata-title strong, .metadata-description strong {
    color: var(--neon-purple) !important;
    display: block !important;
    margin-bottom: 4px !important;
    text-shadow: var(--glow-purple) !important;
}

.metadata-description {
    font-size: 11px !important;
}

.niveau2-tab {
    padding: 15px;
}

.niveau2-email-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.niveau2-input,
.niveau2-textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.niveau2-textarea {
    resize: vertical;
    min-height: 100px;
}

.file-selection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.file-selection-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.file-selection-item:hover {
    background-color: #f5f5f5;
}

.file-selection-item.selected {
    background-color: #e3f2fd;
    border-color: #2196f3;
}

.file-selection-item .file-name {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.niveau2-submit-button {
    padding: 10px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
}

.niveau2-submit-button:hover {
    background-color: #1976d2;
}

.niveau2-submit-button:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
}

/* Scrollbar styling for message window */
.message-window-messages::-webkit-scrollbar {
  width: 6px;
}

.message-window-messages::-webkit-scrollbar-track {
  background: rgba(26, 26, 46, 0.7);
  border-radius: 3px;
}

.message-window-messages::-webkit-scrollbar-thumb {
  background: var(--neon-cyan);
  border-radius: 3px;
  box-shadow: 0 0 5px var(--neon-cyan);
}

.message-window-messages .message.sent.draft {
    background: var(--mondial-relay-light);
    box-shadow: var(--glow-magenta);
}

/* Fix the hover behavior */
.ticket-file-indicator:hover .ticket-file-tooltip {
    visibility: visible;
    opacity: 1;
}

/* Fix for tooltip visibility */
.ticket-header,
.ticket-title-row,
.header-actions,
.ticket-file-indicators {
    position: relative;
    z-index: auto;
    overflow: visible;
}

.ticket-file-indicator {
    position: relative !important;
    z-index: 8000 !important;
}

.ticket-file-tooltip {
    position: absolute !important;
    bottom: 120% !important; /* Position above the icon */
    left: 50% !important;
    transform: translateX(-50%) !important;
    z-index: 9999 !important; /* Ensure tooltip appears above all elements */
    pointer-events: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease !important;
    background-color: rgba(26, 26, 46, 0.95);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    padding: 8px 12px;
    box-shadow: var(--glow-cyan);
    backdrop-filter: blur(5px);
    width: max-content;
    max-width: 250px;
    color: white;
}

.file-metadata-tooltip {
    position: absolute !important;
    z-index: 9999 !important;
    pointer-events: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease !important;
}

.ticket-file-indicator:hover .ticket-file-tooltip,
.ticket-file-indicator:hover .file-metadata-tooltip {
    visibility: visible !important;
    opacity: 1 !important;
}

/* Warehouse conversation styles - matching carriers conversation styles */
.warehouse-conversation {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 0;
    flex: 1 1 auto;
    backdrop-filter: blur(10px);
    overflow: hidden;
    margin-bottom: 0;
}

.warehouse-conversation-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    min-height: 0;
}

.warehouse-conversation-messages {
    flex: 1;
    padding: 20px;
    position: relative;
    min-height: 0;
}

.warehouse-conversation-messages::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
    pointer-events: none;
}

.warehouse-conversation-message {
    max-width: 85%;
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
}

.warehouse-conversation-message--sent {
    background: rgba(233, 30, 99, 0.1);
    box-shadow: 0 0 10px rgba(233, 30, 99, 0.3);
    margin-left: auto;
    border-bottom-right-radius: 4px;
}

.warehouse-conversation-message--received {
    background: rgba(26, 26, 46, 0.6);
    box-shadow: 0 0 10px rgba(0, 188, 212, 0.3);
    margin-right: auto;
    border-bottom-left-radius: 4px;
}

.warehouse-conversation-message__sender {
    font-size: 12px;
    font-weight: 500;
    color: var(--neon-cyan);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-shadow: 0 0 5px rgba(0, 188, 212, 0.5);
}

.warehouse-conversation-message__content {
    margin-bottom: 8px;
    line-height: 1.4;
    word-break: break-word;
    font-size: 14px;
}

.warehouse-conversation-message__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #6c757d;
    margin-top: 4px;
}

.warehouse-conversation-message__timestamp {
    color: #888;
}

.warehouse-response__details-content {
    line-height: 1.6;
    color: var(--text-color);
    font-size: 14px;
    padding: 12px;
    background: rgba(26, 26, 46, 0.4);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 255, 0.1);
}

.warehouse-conversation-tracking {
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(26, 26, 46, 0.95);
    padding: 8px 16px;
    margin-bottom: 16px;
    text-align: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
}

.tracking-badge.warehouse {
    background-color: rgba(0, 150, 136, 0.1);
    color: #00bfa5;
    border-color: #00bfa5;
    display: flex;
    align-items: center;
    gap: 8px;
}

.warehouse-refresh {
    padding: 12px 20px;
    text-align: center;
}

.refresh-button {
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--neon-cyan);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 8px 16px;
    text-transform: uppercase;
    transition: all 0.2s ease;
}

.refresh-button:hover {
    background-color: rgba(0, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
    transform: translateY(-2px);
}

.empty-conversation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 30px;
    color: #888;
    font-style: italic;
    text-align: center;
}