.una-my-marketplace-container {
  min-height: 100vh;
  padding: 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.una-marketplace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.una-marketplace-header h2 {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 1.8rem;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.una-marketplace-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
  border-radius: 12px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.una-marketplace-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  transform: translateY(-2px);
}

.una-marketplace-stats-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 28px;
}

.una-stat-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 255, 157, 0.1);
  transition: all 0.3s ease;
  height: 100px;
}

.una-stat-card:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 255, 157, 0.15);
  transform: translateY(-3px);
}

.una-stat-icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 50%;
  font-size: 20px;
  color: var(--neon-green, #00ff9d);
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
}

.una-stat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.una-stat-value {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
  text-shadow: 0 0 8px rgba(0, 255, 157, 0.2);
  letter-spacing: 0.5px;
  line-height: 1.2;
}

.una-stat-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.3px;
  white-space: nowrap;
}

.una-product-stats {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 255, 255, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.una-product-stats h5 {
  margin-top: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--neon-cyan, #00ffff);
  font-size: 16px;
  letter-spacing: 0.5px;
}

.una-stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  flex: 1;
}

.una-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
  height: 82px;
  justify-content: center;
}

.una-stat-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
}

.una-stat-item.wider {
  grid-column: span 3;
  height: 82px;
}

.una-stat-item svg {
  color: var(--neon-cyan, #00ffff);
  margin-bottom: 8px;
  font-size: 18px;
}

.una-stat-item span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 5px;
  text-align: center;
}

.una-stat-item strong {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
}

.una-status-badge {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  backdrop-filter: blur(4px);
  z-index: 10;
}

.una-status-badge.active {
  background: rgba(0, 255, 157, 0.2);
  color: var(--neon-green, #00ff9d);
  border: 1px solid rgba(0, 255, 157, 0.3);
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.2);
  text-shadow: 0 0 5px rgba(0, 255, 157, 0.5);
}

.una-status-badge.inactive {
  background: rgba(255, 0, 0, 0.2);
  color: #ff5b5b;
  border: 1px solid rgba(255, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.una-create-button {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: 1px solid var(--neon-green, #00ff9d);
  color: var(--neon-green, #00ff9d);
  border-radius: 12px;
  padding: 12px 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.una-create-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.3);
  transform: translateY(-2px);
}

.una-marketplace-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 28px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  align-items: center;
}

.una-search-group {
  position: relative;
  flex: 1;
  min-width: 200px;
}

.una-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--neon-cyan, #00ffff);
  font-size: 16px;
  opacity: 0.7;
}

.una-search-input {
  width: 100%;
  padding: 12px 16px 12px 36px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
}

.una-search-input:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-search-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.una-filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-filter-label {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--neon-cyan, #00ffff);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.una-filter-select {
  padding: 10px 16px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  min-width: 160px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.una-filter-select:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-marketplace-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

.una-marketplace-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.una-marketplace-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 255, 157, 0.2);
  border-color: rgba(0, 255, 157, 0.2);
}

.una-product-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
  background: rgba(13, 14, 26, 0.5);
}

.una-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.una-marketplace-card:hover .una-product-image {
  transform: scale(1.1);
}

.una-product-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.3);
  font-size: 2.5rem;
}

.una-featured-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(255, 215, 0, 0.2);
  color: #FFD700;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid rgba(255, 215, 0, 0.3);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
  backdrop-filter: blur(4px);
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  letter-spacing: 0.5px;
  z-index: 10;
}

.una-product-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.una-product-details h4 {
  margin-top: 0;
  margin-bottom: 12px;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.una-product-meta {
  margin-bottom: 16px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  min-height: 110px;
}

.una-product-price {
  font-weight: 700;
  font-size: 18px;
  color: var(--neon-cyan, #00ffff);
  margin: 8px 0;
  letter-spacing: 0.5px;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.3);
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.2;
}

.una-product-price svg {
  min-width: 16px;
}

.una-product-commission {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 8px 0;
  line-height: 1.2;
}

.una-product-commission svg {
  min-width: 14px;
}

.una-product-expiry {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 8px 0;
  line-height: 1.2;
}

.una-product-expiry svg {
  min-width: 14px;
}

.una-product-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  min-height: 36px;
}

.una-category-tag {
  display: flex;
  align-items: center;
  gap: 5px;
  background: rgba(121, 80, 242, 0.1);
  color: #9d75ff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid rgba(121, 80, 242, 0.2);
  transition: all 0.2s ease;
  white-space: nowrap;
}

.una-category-tag:hover {
  background: rgba(121, 80, 242, 0.2);
  box-shadow: 0 0 8px rgba(121, 80, 242, 0.3);
}

.una-remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  background: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff5b5b;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: auto;
}

.una-remove-button:hover {
  background: rgba(255, 0, 0, 0.15);
  border-color: rgba(255, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
  transform: translateY(-2px);
}

.una-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.una-empty-state svg {
  font-size: 3rem;
  margin-bottom: 20px;
  color: var(--neon-cyan, #00ffff);
  opacity: 0.8;
}

.una-empty-state p {
  font-size: 18px;
  margin-bottom: 20px;
  max-width: 400px;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .una-marketplace-filters {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .una-search-group {
    width: 100%;
  }
  
  .una-filter-group {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .una-marketplace-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .una-marketplace-stats-summary {
    grid-template-columns: 1fr;
  }
  
  .una-marketplace-grid {
    grid-template-columns: 1fr;
  }
  
  .una-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .una-stat-item.wider {
    grid-column: span 2;
  }
}

@media (max-width: 480px) {
  .una-stats-grid {
    grid-template-columns: 1fr;
  }
  
  .una-stat-item.wider {
    grid-column: span 1;
  }
}

.una-marketplace-add-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-decoration: none;
  color: var(--neon-cyan, #00ffff);
  font-weight: 600;
  padding: 24px;
}

.una-marketplace-add-card svg {
  font-size: 24px;
}

.una-marketplace-add-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 255, 157, 0.2);
  border-color: rgba(0, 255, 157, 0.2);
  background: rgba(0, 255, 255, 0.1);
}

/* Error & Loading States */
.una-marketplace-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-lg, 16px);
  background: rgba(255, 0, 255, 0.05);
  border: 1px solid rgba(255, 0, 255, 0.2);
  border-radius: 12px;
  gap: 8px;
  margin: 16px auto;
  max-width: 500px;
  text-align: center;
}

.una-marketplace-error svg {
  color: var(--neon-magenta, #ff00ff);
  font-size: 2rem;
  margin-bottom: 8px;
}

.una-marketplace-error h2 {
  color: var(--neon-magenta, #ff00ff);
  margin: 0;
  font-size: 1.2rem;
}

.una-marketplace-error p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.una-marketplace-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
  width: 100%;
}

.una-marketplace-content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.una-loading-content {
  text-align: center;
  padding: 16px;
  width: 100%;
  max-width: 500px;
}

.una-loading-icons {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 16px;
}

.una-loading-icons svg {
  position: absolute;
  width: 40px;
  height: 40px;
  filter: drop-shadow(0 0 5px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 20px;
  color: var(--neon-cyan, #00ffff);
  animation: floatIcon 2s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green, #00ff9d);
  animation: floatIcon 2s infinite 0.5s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta, #ff00ff);
  animation: floatIcon 2s infinite 1s;
}

.una-loading-content h2 {
  color: var(--neon-cyan, #00ffff);
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.4);
  font-size: 1.2rem;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-loading-progress {
  width: 100%;
  height: 3px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 16px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan, #00ffff);
  animation: progress 1.5s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan, #00ffff), transparent);
  animation: glow 1.5s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 8px;
  margin-top: 16px;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 8px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan, #00ffff);
  font-size: 0.9rem;
}

.una-metric-item:nth-child(1) { animation-delay: 0.1s; }
.una-metric-item:nth-child(2) { animation-delay: 0.2s; }
.una-metric-item:nth-child(3) { animation-delay: 0.3s; }
.una-metric-item:nth-child(4) { animation-delay: 0.4s; }

/* Animations */
@keyframes floatIcon {
  0%, 100% { 
    transform: translateY(0); 
  }
  50% { 
    transform: translateY(-5px); 
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(5px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}