.una-customer-details-container {
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-customer-details-grid {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 30px;
  margin-top: 20px;
}

.una-customer-profile-card {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  text-align: center;
}

.una-customer-avatar {
  width: 80px;
  height: 80px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.una-customer-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.una-stat {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.una-stat-label-customer {
  color: var(--neon-cyan);
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
}

.una-stat-value-customer {
  color: var(--neon-green);
  font-size: 1.2em;
  text-shadow: var(--glow-green);
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.una-stat-label {
  color: var(--neon-cyan);
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-stat-value {
  color: var(--neon-green);
  font-size: 1.2em;
  text-shadow: var(--glow-green);
}

.una-contact-card,
.una-address-card,
.una-tags-card {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.una-contact-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.una-contact-actions button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-contact-actions button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
}

.una-orders-timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.una-timeline-order {
  cursor: pointer;
  transition: all 0.3s ease;
}

.una-order-card {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
}

.una-timeline-order:hover .una-order-card {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.una-order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.una-order-status {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  text-transform: uppercase;
}

.una-order-status.paid {
  color: var(--neon-green);
  border: 1px solid var(--neon-green);
  text-shadow: var(--glow-green);
}

.una-order-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.una-order-item {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
}

.una-order-total {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  text-shadow: var(--glow-green);
  font-weight: 500;
}

@media (max-width: 1024px) {
  .una-customer-details-grid {
    grid-template-columns: 1fr;
  }
}

.una-customer-profile-card h1 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 1.8em;
  margin: 15px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-contact-card h3,
.una-address-card h3,
.una-tags-card h3 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 1.2em;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-contact-details p,
.una-address-details p {
  color: rgba(255, 255, 255, 0.9);
  margin: 5px 0;
  font-size: 1.1em;
}

.una-section-header h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.una-order-header h4 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-order-date {
  color: var(--neon-magenta);
  text-shadow: var(--glow-magenta);
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.una-order-item span {
  color: rgba(255, 255, 255, 0.9);
}

.una-order-item span:last-child {
  color: var(--neon-green);
  text-shadow: var(--glow-green);
}