.carriers-instructions-container {
  padding: 20px;
  min-height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.header h2 {
  color: var(--primary-color);
  text-shadow: var(--glow-green);
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.carriers-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
  padding: 0 20px;
}

.carrier-card {
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--text-color);
  height: 220px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.carrier-card:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
}

.carrier-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.carrier-title {
  color: var(--primary-color);
  margin: 0 0 8px 0;
  font-size: 18px;
  text-shadow: var(--glow-green);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.carrier-emails-container {
  margin-bottom: 8px;
  flex: 1;
  min-height: 0;
  max-height: 80px;
  position: relative;
}

.carrier-emails-list {
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 5px;
}

.carrier-emails-list::-webkit-scrollbar {
  width: 4px;
}

.carrier-emails-list::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

.carrier-email {
  color: var(--primary-light);
  font-size: 0.9em;
  margin-bottom: 5px;
  opacity: 0.8;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carrier-email.no-emails {
  margin-top: 10px;
}

.carrier-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: auto;
  min-height: 85px;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.info-item:last-child {
  grid-column: span 2;
  margin-top: 10px;
}

.info-label {
  font-size: 12px;
  color: var(--text-color);
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.automation-status {
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  min-width: 60px;
}

.automation-status.enabled {
  background-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
  border: 1px solid #4CAF50;
}

.automation-status.disabled {
  background-color: rgba(158, 158, 158, 0.2);
  color: #9e9e9e;
  border: 1px solid #9e9e9e;
}

.status-item svg {
  color: var(--text-color);
  transition: color 0.3s ease;
  font-size: 18px;
}

.status-item svg.success {
  color: var(--primary-color);
}

.status-item svg.failure {
  color: #ff4d4d;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}


.page-info {
  color: var(--text-color);
  font-size: 14px;
}

.carrier-modal {
  max-width: 600px;
  width: 90%;
}

.modalai {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 14, 26, 0.95);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding-top: 50px;
  overflow-y: auto;
}

.modal-content {
  background: var(--gradient-card);
  border-radius: 16px;
  border: 1px solid var(--border-color);
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--primary-color);
  font-weight: 500;
}

.ai-agent-input, 
.ai-agent-textarea {
  width: 100%;
  padding: 12px;
  background: rgba(30, 31, 48, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.ai-agent-input:focus, 
.ai-agent-textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 255, 157, 0.2);
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.toggle-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.toggle-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: #ddd;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.2s;
}

.toggle-label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s;
}

.toggle-checkbox:checked + .toggle-label {
  background: #4CAF50;
}

.toggle-checkbox:checked + .toggle-label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 30px;
}


.search-container {
  flex: 1;
  max-width: 400px;
  min-width: 200px;
  margin: 0 20px;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 12px;
  color: var(--text-color);
  opacity: 0.6;
}

.search-input {
  width: 100%;
  padding: 10px 40px 10px 36px;
  background: rgba(30, 31, 48, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  color: var(--text-color);
  font-size: 14px;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 255, 157, 0.2);
}

.clear-search {
  position: absolute;
  right: 12px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results {
  grid-column: 1 / -1;
  text-align: center;
  padding: 40px 0;
  color: var(--text-color);
  opacity: 0.7;
  font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .carriers-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media (max-width: 768px) {
  .carriers-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .header {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-container {
    max-width: 100%;
    margin: 10px 0;
    order: -1;
  }
  
  .pagination {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .carriers-grid {
    grid-template-columns: 1fr;
    max-width: 280px;
    margin: 0 auto;
  }
  
  .carrier-card {
    height: 200px;
  }
}

.carrier-selection-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.selection-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selection-section h4 {
  color: var(--primary-color);
  margin: 0;
  font-size: 16px;
  text-shadow: var(--glow-green);
  letter-spacing: 1px;
}

.selection-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  position: relative;
}

.selection-divider::before,
.selection-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--border-color);
}

.selection-divider span {
  padding: 0 12px;
  color: var(--text-color);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.automated-carriers-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 5px;
}

.automated-carriers-list::-webkit-scrollbar {
  width: 4px;
}

.automated-carriers-list::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

.automated-carrier-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: rgba(30, 31, 48, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.automated-carrier-item:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: var(--glow-green);
}

.automated-carrier-item span {
  color: var(--text-color);
  font-weight: 500;
}

.automation-badge {
  font-size: 0.8rem;
  padding: 4px 10px;
  border-radius: 20px;
  background-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
  border: 1px solid #4CAF50;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Keep minimal styling for layout and positioning */
.add-email-btn, .remove-email-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.remove-email-btn {
  margin-left: 8px;
}