:root {
  --card-bg: rgba(13, 14, 26, 0.85);
  --card-border: rgba(0, 255, 157, 0.15);
  --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-tertiary: rgba(255, 255, 255, 0.5);
  --neon-green: #00ff9d;
  --neon-cyan: #00ffff;
  --neon-magenta: #ff00ff;
  --neon-yellow: #ffff00;
  --neon-green-glow: 0 0 5px rgba(0, 255, 157, 0.4);
  --neon-cyan-glow: 0 0 5px rgba(0, 255, 255, 0.4);
  --status-success: var(--neon-green);
  --status-pending: var(--neon-yellow);
  --status-danger: var(--neon-magenta);
  --status-neutral: var(--text-secondary);
  --border-radius-sm: 4px;
  --border-radius-md: 6px;
  --border-radius-lg: 8px;
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  --transition-fast: 0.15s ease;
  --glow-cyan: var(--neon-cyan-glow);
  --glow-green: var(--neon-green-glow);
  --glow-magenta: 0 0 5px rgba(255, 0, 255, 0.4);
}

/* Container */
.una-orders-container {
  padding: var(--spacing-md);
  max-width: 1600px;
  margin: 0 auto;
  font-family: 'Inter', 'Roboto', -apple-system, sans-serif;
}

/* Header Section */
.una-orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.una-orders-title {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--neon-cyan);
}

.una-orders-title h2 {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-cyan);
  margin: 0;
}

.una-orders-title svg {
  font-size: 1.2rem;
  filter: drop-shadow(var(--glow-cyan));
}

.una-orders-count {
  font-size: 0.8rem;
  color: var(--text-secondary);
  background: rgba(0, 255, 255, 0.1);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border-radius: var(--border-radius-md);
  margin-left: var(--spacing-sm);
}

.una-orders-actions {
  display: flex;
  gap: var(--spacing-md);
  flex-wrap: wrap;
}

/* Search & Filter Components */
.una-search-wrapper {
  position: relative;
  min-width: 250px;
}

.una-search-icon {
  position: absolute;
  left: var(--spacing-md);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.una-search-input {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) calc(var(--spacing-lg) + var(--spacing-md));
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border);
  background: rgba(13, 14, 26, 0.7);
  color: var(--text-primary);
  font-size: 0.9rem;
}

.una-search-input:focus {
  outline: none;
  border-color: rgba(0, 255, 255, 0.4);
  box-shadow: var(--glow-cyan);
}

.una-clear-search {
  position: absolute;
  right: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-tertiary);
  font-size: 1.2rem;
  cursor: pointer;
}

.una-filter-wrapper {
  position: relative;
  min-width: 180px;
}

.una-filter-icon {
  position: absolute;
  left: var(--spacing-md);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.una-filter-select {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) calc(var(--spacing-lg) + var(--spacing-md));
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border);
  background: rgba(13, 14, 26, 0.7);
  color: var(--text-primary);
  font-size: 0.9rem;
  appearance: none;
}

.una-filter-select:focus {
  outline: none;
  border-color: rgba(0, 255, 255, 0.4);
  box-shadow: var(--glow-cyan);
}

/* Table Styles */
.una-orders-table-container {
  overflow-x: auto;
  background: var(--card-bg);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border);
  box-shadow: var(--card-shadow);
}

.una-orders-table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 0.9rem;
}

.una-orders-table thead {
  background: rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 10;
}

.una-orders-table th,
.una-orders-table td {
  padding: var(--spacing-md);
  text-align: left;
  border-bottom: 1px solid var(--card-border);
}

.una-orders-table th {
  font-weight: 600;
  color: var(--neon-cyan);
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.una-orders-table th.sortable {
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-fast);
  position: relative;
  padding-right: var(--spacing-xl);
}

.una-orders-table th.sortable:hover {
  background-color: rgba(0, 255, 255, 0.05);
}

.sort-icon {
  position: absolute;
  right: var(--spacing-md);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
  font-size: 0.8rem;
}

.sort-icon.active {
  color: var(--neon-cyan);
}

.una-orders-table tbody tr {
  transition: background-color var(--transition-fast);
  cursor: pointer;
}

.una-orders-table tbody tr:hover {
  background-color: rgba(0, 255, 255, 0.05);
  box-shadow: inset 0 0 0 1px var(--neon-cyan);
}

/* Special Columns */
.order-number {
  font-weight: 600;
  color: var(--neon-cyan);
}

.status-column {
  min-width: 180px;
}

.items-column {
  text-align: center;
}

.price-column {
  text-align: right;
}

.actions-column {
  text-align: center;
  width: 60px;
}

/* Cell Styles */
.una-cell-with-icon {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.cell-icon {
  color: var(--text-tertiary);
  font-size: 0.9rem;
}

.status-cell {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}

.una-status-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.una-status-pill {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: 0.8rem;
  white-space: nowrap;
  text-transform: lowercase;
  border: 1px solid transparent;
  background: rgba(0, 0, 0, 0.2);
  width: fit-content;
}

.una-status-pill:first-letter {
  text-transform: uppercase;
}

.una-status-pill.una-status-success {
  color: var(--status-success);
  border-color: rgba(0, 255, 157, 0.3);
  background: rgba(0, 255, 157, 0.05);
}

.una-status-pill.una-status-pending {
  color: var(--status-pending);
  border-color: rgba(255, 255, 0, 0.3);
  background: rgba(255, 255, 0, 0.05);
}

.una-status-pill.una-status-danger {
  color: var(--status-danger);
  border-color: rgba(255, 0, 255, 0.3);
  background: rgba(255, 0, 255, 0.05);
}

.una-status-pill.una-status-neutral {
  color: var(--status-neutral);
  border-color: rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
}

.status-icon {
  font-size: 0.8rem;
}

.status-icon.status-success {
  color: var(--status-success);
}

.status-icon.status-pending {
  color: var(--status-pending);
}

.status-icon.status-danger {
  color: var(--status-danger);
}

.status-icon.status-neutral {
  color: var(--status-neutral);
}

.items-cell {
  text-align: center;
  position: relative;
}

.items-cell:hover .una-cell-tooltip {
  display: block;
}

.una-cell-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  background: rgba(13, 14, 26, 0.95);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  z-index: 100;
  box-shadow: var(--card-shadow);
}

.una-tooltip-items {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.una-tooltip-items li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.una-tooltip-items li:last-child {
  border-bottom: none;
}

.una-tooltip-items li > div {
  display: flex;
  gap: var(--spacing-md);
  color: var(--text-secondary);
  font-size: 0.8rem;
}

.price-cell {
  text-align: right;
  font-weight: 500;
  color: var(--neon-green);
}

.actions-cell {
  text-align: center;
}

.una-action-button {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--card-border);
  color: var(--neon-cyan);
  width: 30px;
  height: 30px;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.una-action-button:hover {
  background: rgba(0, 255, 255, 0.1);
  border-color: rgba(0, 255, 255, 0.3);
  box-shadow: var(--glow-cyan);
}

/* Empty State */
.una-orders-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-lg);
  text-align: center;
}

.una-orders-empty-state .empty-icon {
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
  color: var(--neon-cyan);
  opacity: 0.5;
}

.una-orders-empty-state p {
  font-size: 1rem;
  margin: 0 0 var(--spacing-md) 0;
  color: var(--text-secondary);
}

.una-clear-filters-button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.una-clear-filters-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
}

/* Footer */
.una-orders-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-md);
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--card-bg);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border);
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.una-pagination-info {
  font-size: 0.8rem;
}

/* Error & Loading States */
.una-order-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-lg);
  border: 1px solid rgba(255, 0, 255, 0.2);
  border-radius: var(--border-radius-md);
  gap: var(--spacing-sm);
  margin: var(--spacing-lg) auto;
  max-width: 500px;
  text-align: center;
}

.una-order-error svg {
  color: var(--status-danger);
  font-size: 2rem;
  margin-bottom: var(--spacing-sm);
}

.una-order-error h2 {
  color: var(--status-danger);
  margin: 0;
  font-size: 1.2rem;
}

.una-order-error p {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-sm);
  font-size: 0.9rem;
}

.una-order-loading-screen {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin: var(--spacing-lg) auto;
  max-width: 1200px;
}

.una-loading-content {
  text-align: center;
  padding: var(--spacing-lg);
  width: 100%;
  max-width: 500px;
}

.una-loading-logo {
  width: 120px;
  height: 120px;
  margin: 0 auto var(--spacing-lg);
  position: relative;
}

.una-loading-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: spinLogo 3s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 1.2rem;
  margin-bottom: var(--spacing-lg);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-loading-progress {
  width: 100%;
  height: 3px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: var(--spacing-lg) 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  animation: progress 1.5s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
  animation: glow 1.5s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: var(--spacing-sm);
  margin-top: var(--spacing-lg);
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm);
  background: rgba(13, 14, 26, 0.7);
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: var(--text-secondary);
  font-size: 0.8rem;
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 0.9rem;
}

.una-metric-item:nth-child(1) { animation-delay: 0.1s; }
.una-metric-item:nth-child(2) { animation-delay: 0.2s; }
.una-metric-item:nth-child(3) { animation-delay: 0.3s; }
.una-metric-item:nth-child(4) { animation-delay: 0.4s; }

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .una-orders-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .una-orders-actions {
    width: 100%;
    justify-content: space-between;
  }
  
  .una-search-wrapper,
  .una-filter-wrapper {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .una-orders-container {
    padding: var(--spacing-sm);
  }
  
  .una-orders-actions {
    flex-direction: column;
  }
  
  .una-search-wrapper,
  .una-filter-wrapper {
    width: 100%;
  }
  
  .status-column,
  .items-column {
    display: none;
  }
  
  .una-orders-table th,
  .una-orders-table td {
    padding: var(--spacing-sm);
  }
  
  .una-loading-metrics {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .una-orders-table thead {
    display: none;
  }
  
  .una-orders-table tbody,
  .una-orders-table tr,
  .una-orders-table td {
    display: block;
    width: 100%;
  }
  
  .una-orders-table tr {
    margin-bottom: var(--spacing-lg);
    border: 1px solid var(--card-border);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
  }
  
  .una-orders-table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    padding: var(--spacing-sm) 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .una-orders-table td::before {
    content: attr(data-label);
    font-weight: 600;
    color: var(--text-secondary);
    text-align: left;
  }
  
  .price-cell,
  .actions-cell {
    justify-content: flex-end;
  }
  
  .una-action-button {
    margin-left: auto;
  }
  
  .una-orders-table td.actions-cell::before { display: none; }
}

/* Animations */
@keyframes spinLogo {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(720deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(5px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tooltip arrow */
.una-cell-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--card-border) transparent transparent transparent;
}

/* Scrollbar styling */
.una-tooltip-items::-webkit-scrollbar {
  width: 6px;
}

.una-tooltip-items::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.una-tooltip-items::-webkit-scrollbar-thumb {
  background: var(--neon-cyan);
  border-radius: 3px;
}

.una-tooltip-items::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 255, 255, 0.8);
}

/* Add mobile adjustments for data attributes in table cells */
@media (max-width: 480px) {
  .una-orders-table td.order-number::before { content: "Order #"; }
  .una-orders-table td:nth-child(2)::before { content: "Date"; }
  .una-orders-table td:nth-child(3)::before { content: "Customer"; }
  .una-orders-table td.status-cell::before { content: "Status"; }
  .una-orders-table td.items-cell::before { content: "Items"; }
  .una-orders-table td.price-cell::before { content: "Total"; }
  .una-orders-table td.actions-cell::before { content: "Actions"; }
}

/* Tracking Status Pill and Tooltip */
.una-status-tracking {
  background: rgba(0, 255, 255, 0.05);
  border-color: rgba(0, 255, 255, 0.3);
  color: var(--neon-cyan);
  position: relative;
}

.una-status-tracking .status-icon {
  color: var(--neon-cyan);
}

.una-tracking-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  background: rgba(13, 14, 26, 0.95);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  z-index: 100;
  box-shadow: var(--card-shadow);
  text-align: left;
}

.una-status-tracking:hover .una-tracking-tooltip {
  display: block;
}

.una-tooltip-header {
  color: var(--neon-cyan);
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: var(--spacing-sm);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  padding-bottom: var(--spacing-sm);
}

.una-tracking-items {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}

.una-tracking-items li {
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.una-tracking-items li:last-child {
  border-bottom: none;
}

.una-tracking-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xxs);
}

.una-tracking-status {
  font-size: 0.7rem;
  padding: var(--spacing-xxs) var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  text-transform: lowercase;
}

.una-tracking-status:first-letter {
  text-transform: uppercase;
}

.una-tracking-status.fulfilled,
.una-tracking-status.delivered {
  background: rgba(0, 255, 157, 0.1);
  color: var(--status-success);
}

.una-tracking-status.in_transit,
.una-tracking-status.out_for_delivery,
.una-tracking-status.pending {
  background: rgba(255, 255, 0, 0.1);
  color: var(--status-pending);
}

.una-tracking-status.failure,
.una-tracking-status.exception,
.una-tracking-status.attempted_delivery {
  background: rgba(255, 0, 255, 0.1);
  color: var(--status-danger);
}

.una-tracking-number {
  font-size: 0.8rem;
  color: var(--text-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xxs);
}

.una-tracking-link {
  color: var(--neon-cyan);
  font-size: 0.7rem;
  border: 1px solid rgba(0, 255, 255, 0.3);
  padding: 2px 6px;
  border-radius: var(--border-radius-sm);
  text-decoration: none;
  transition: all var(--transition-fast);
}

.una-tracking-link:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
}

.una-tracking-details {
  font-size: 0.75rem;
  margin-top: var(--spacing-xs);
}

.una-tracking-status-label {
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.una-delivery-estimate {
  color: var(--neon-green);
  font-size: 0.7rem;
}

/* Tracking tooltip arrow */
.una-tracking-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--card-border) transparent transparent transparent;
}

@media (max-width: 768px) {
  .una-tracking-tooltip {
    width: 260px;
    left: 0;
    transform: none;
  }
  
  .una-tracking-tooltip::after {
    left: 20%;
  }
}