:root {
  --card-bg: rgba(13, 14, 26, 0.85);
  --card-bg-hover: rgba(20, 22, 40, 0.9);
  --card-border: rgba(0, 255, 157, 0.15);
  --card-border-hover: rgba(0, 255, 157, 0.3);
  --card-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  --header-border: rgba(0, 255, 255, 0.2);
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-tertiary: rgba(255, 255, 255, 0.5);
  --neon-green-glow: 0 0 10px rgba(0, 255, 157, 0.5);
  --neon-cyan-glow: 0 0 10px rgba(0, 255, 255, 0.5);
  --neon-magenta-glow: 0 0 10px rgba(255, 0, 255, 0.5);
  --status-success: rgba(0, 255, 157, 0.9);
  --status-warning: rgba(255, 165, 0, 0.9);
  --status-danger: rgba(255, 0, 85, 0.9);
  --button-bg: rgba(0, 255, 157, 0.1);
  --button-border: rgba(0, 255, 157, 0.3);
  --button-hover: rgba(0, 255, 157, 0.2);
  --toolbar-bg: rgba(13, 14, 26, 0.7);
  --input-bg: rgba(20, 22, 40, 0.7);
  --input-border: rgba(0, 255, 157, 0.2);
  --input-focus-border: rgba(0, 255, 255, 0.5);
  --badge-bg: rgba(0, 255, 157, 0.1);
  --tag-bg: rgba(0, 255, 255, 0.1);
  --tag-border: rgba(0, 255, 255, 0.3);
  --tag-text: var(--neon-cyan, #00ffff);
  --variant-bg: rgba(255, 0, 255, 0.1);
  --variant-border: rgba(255, 0, 255, 0.3);
  --variant-text: var(--neon-magenta, #ff00ff);
  --status-badge-bg: rgba(0, 0, 0, 0.6);
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
}

.una-products-container {
  padding: var(--spacing-xl);
  max-width: 1600px;
  margin: 0 auto;
  font-family: 'Inter', 'Roboto', -apple-system, sans-serif;
}

/* HEADER SECTION */
.una-products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--header-border);
}

.una-products-title {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.una-products-title h1 {
  color: var(--neon-cyan, #00ffff);
  font-size: 1.8rem;
  font-weight: 600;
  text-shadow: var(--neon-cyan-glow);
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-products-title svg {
  color: var(--neon-cyan, #00ffff);
  font-size: 1.5rem;
  filter: drop-shadow(var(--neon-cyan-glow));
}

.una-products-count {
  font-size: 0.9rem;
  color: var(--text-secondary);
  background: var(--badge-bg);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-md);
  margin-left: var(--spacing-sm);
}

.una-products-actions {
  display: flex;
  gap: var(--spacing-md);
}

.una-optimize-button, 
.una-create-button {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.una-optimize-button {
  background: transparent;
  border: 1px solid var(--button-border);
  color: var(--neon-green, #00ff9d);
}

.una-optimize-button:hover:not(:disabled) {
  background: var(--button-hover);
  box-shadow: var(--neon-green-glow);
  transform: translateY(-1px);
}

.una-optimize-button.active {
  background: var(--button-bg);
  box-shadow: var(--neon-green-glow);
}

.una-optimize-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.una-create-button {
  background: var(--neon-green, #00ff9d);
  border: none;
  color: var(--card-bg);
  font-weight: 600;
}

.una-create-button:hover {
  box-shadow: var(--neon-green-glow);
  transform: translateY(-1px);
}

.una-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 255, 157, 0.3);
  border-top-color: var(--neon-green, #00ff9d);
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
  margin-right: var(--spacing-xs);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* TOOLBAR SECTION */
.una-products-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
  background: var(--toolbar-bg);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--card-border);
}

.una-search-wrapper {
  position: relative;
  flex: 1;
  min-width: 240px;
}

.una-search-icon {
  position: absolute;
  left: var(--spacing-md);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.una-search-input {
  width: 100%;
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) calc(var(--spacing-xl) + var(--spacing-xs));
  border-radius: var(--border-radius-md);
  border: 1px solid var(--input-border);
  background: var(--input-bg);
  color: var(--text-primary);
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.una-search-input:focus {
  outline: none;
  border-color: var(--input-focus-border);
  box-shadow: var(--neon-cyan-glow);
}

.una-clear-search {
  position: absolute;
  right: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-tertiary);
  font-size: 1.2rem;
  cursor: pointer;
  padding: var(--spacing-xs);
  line-height: 0.5;
}

.una-filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  align-items: center;
}

.una-filter-group {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.una-filter-label {
  color: var(--text-secondary);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.una-filter-select {
  background: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: var(--border-radius-md);
  color: var(--text-primary);
  padding: calc(var(--spacing-sm) - 1px) var(--spacing-sm);
  font-size: 0.9rem;
  min-width: 140px;
}

.una-filter-select:focus {
  outline: none;
  border-color: var(--input-focus-border);
  box-shadow: var(--neon-cyan-glow);
}

.una-selected-info {
  color: var(--text-secondary);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.una-clear-selection {
  background: none;
  border: none;
  color: var(--neon-cyan, #00ffff);
  text-decoration: underline;
  cursor: pointer;
  margin-left: var(--spacing-sm);
  font-size: 0.9rem;
}

/* PRODUCT GRID */
.una-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

/* PRODUCT CARD */
.una-product-card {
  position: relative;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  box-shadow: var(--card-shadow);
}

.una-product-card:hover {
  transform: translateY(-4px);
  border-color: var(--card-border-hover);
  background: var(--card-bg-hover);
}

.una-product-card.out-of-stock {
  border-left: 3px solid var(--status-danger);
}

.una-product-card.low-stock {
  border-left: 3px solid var(--status-warning);
}

.una-product-card.in-stock {
  border-left: 3px solid var(--status-success);
}

.una-product-checkbox-wrapper {
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  z-index: 10;
}

.una-product-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 255, 157, 0.4);
  background: rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.una-product-checkbox:checked {
  background: var(--neon-green, #00ff9d);
  border-color: var(--neon-green, #00ff9d);
}

.una-product-checkbox:checked::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--card-bg);
  font-size: 0.8rem;
  font-weight: bold;
}

.una-product-checkbox:hover {
  border-color: var(--neon-green, #00ff9d);
}

.una-product-content {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

/* IMAGE SECTION */
.una-product-image-wrapper {
  height: 220px;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
}

.una-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.una-product-card:hover .una-product-image {
  transform: scale(1.05);
}

.una-product-no-image {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  color: var(--text-tertiary);
}

.una-product-no-image svg {
  font-size: 2rem;
  opacity: 0.5;
}

.una-product-status-badge {
  position: absolute;
  top: var(--spacing-sm);
  left: var(--spacing-sm);
  background: var(--status-badge-bg);
  color: var(--text-secondary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-md);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  backdrop-filter: blur(4px);
}

/* DETAILS SECTION */
.una-product-details {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.una-product-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-sm);
}

.una-product-title {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 1.3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
}

.una-stock-indicator {
  font-size: 0.8rem;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  white-space: nowrap;
}

.una-stock-indicator.out-of-stock {
  background: rgba(255, 0, 85, 0.1);
  color: var(--status-danger);
}

.una-stock-indicator.low-stock {
  background: rgba(255, 165, 0, 0.1);
  color: var(--status-warning);
}

.una-stock-indicator.in-stock {
  background: rgba(0, 255, 157, 0.1);
  color: var(--status-success);
}

.una-product-meta {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  margin-top: calc(var(--spacing-xs) * -1);
}

.una-product-type,
.una-product-vendor {
  background: var(--badge-bg);
  color: var(--text-secondary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-md);
  font-size: 0.8rem;
}

/* VARIANTS SECTION */
.una-variants-summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.una-variant-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.una-variant-label {
  color: var(--text-secondary);
  font-size: 0.8rem;
}

.una-variant-pills {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.una-variant-pill {
  background: var(--variant-bg);
  color: var(--variant-text);
  border: 1px solid var(--variant-border);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 12px;
  font-size: 0.8rem;
}

.una-variant-pill.more {
  background: rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.1);
  color: var(--text-secondary);
}

/* TAGS SECTION */
.una-product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.una-product-tag {
  background: var(--tag-bg);
  color: var(--tag-text);
  border: 1px solid var(--tag-border);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 12px;
  font-size: 0.8rem;
}

.una-product-tag.more {
  background: rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.1);
  color: var(--text-secondary);
}

/* ACTION OVERLAY */
.una-product-action-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  padding: var(--spacing-md);
  opacity: 0;
  transform: translateY(20px);
  transition: all var(--transition-normal);
  pointer-events: none;
}

.una-product-card:hover .una-product-action-overlay {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

.una-action-buttons {
  display: flex;
  justify-content: center;
  gap: var(--spacing-sm);
}

.una-action-button {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.5);
  color: var(--text-primary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all var(--transition-fast);
  backdrop-filter: blur(4px);
}

.una-action-button.primary {
  background: rgba(0, 255, 157, 0.2);
  border-color: rgba(0, 255, 157, 0.4);
  color: var(--neon-green, #00ff9d);
}

.una-action-button:hover {
  transform: translateY(-2px);
}

.una-action-button.primary:hover {
  background: rgba(0, 255, 157, 0.3);
  box-shadow: var(--neon-green-glow);
}

/* EMPTY STATE */
.una-products-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-lg);
  text-align: center;
}

.una-products-empty-state .empty-icon {
  font-size: 3rem;
  color: var(--neon-cyan, #00ffff);
  margin-bottom: var(--spacing-lg);
  opacity: 0.6;
}

.una-products-empty-state p {
  color: var(--text-secondary);
  font-size: 1.1rem;
  margin-bottom: var(--spacing-md);
}

.una-clear-filters {
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.una-clear-filters:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--neon-cyan-glow);
}

/* FOOTER */
.una-products-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background: var(--toolbar-bg);
  border-radius: var(--border-radius-lg);
  margin-top: var(--spacing-md);
}

.una-showing-count {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.una-clear-filters-btn {
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-md);
  font-size: 0.85rem;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.una-clear-filters-btn:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--neon-cyan-glow);
}

/* LOADING SCREEN */
.una-product-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-loading-content {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

.una-loading-icons {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.una-loading-icons svg {
  position: absolute;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 30px;
  color: var(--neon-cyan, #00ffff);
  animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green, #00ff9d);
  animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta, #ff00ff);
  animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
  color: var(--neon-cyan, #00ffff);
  text-shadow: var(--neon-cyan-glow);
  font-size: 2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.una-loading-progress {
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan, #00ffff);
  animation: progress 2s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan, #00ffff), transparent);
  animation: glow 1.5s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 30px;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan, #00ffff);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-metric-item:nth-child(4) { animation-delay: 0.8s; }

/* RESPONSIVE ADJUSTMENTS */
@media (max-width: 768px) {
  .una-products-header {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
  
  .una-products-actions {
    width: 100%;
    justify-content: space-between;
  }
  
  .una-products-toolbar {
    flex-direction: column;
  }
  
  .una-filters {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .una-filter-group {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .una-filter-select {
    width: 100%;
  }
  
  .una-products-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

@keyframes floatIcon {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes glow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}