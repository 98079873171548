.una-create-product-container {
  padding: 20px;
  background: var(--gradient-bg);
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;
}

/* Header Section */
.una-create-product-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.una-create-product-header svg {
  color: var(--neon-cyan);
  font-size: 2rem;
  filter: drop-shadow(var(--glow-cyan));
}

.una-create-product-header h2 {
  color: var(--neon-cyan);
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
}

/* Form Sections */
.una-create-product-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.una-form-section {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 25px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
}

.una-form-section h3 {
  color: var(--neon-green);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-shadow: var(--glow-green);
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-form-section h3 svg {
  color: var(--neon-cyan);
  filter: drop-shadow(var(--glow-cyan));
}

/* Form Inputs */
.una-form-section input[type="text"],
.una-form-section input[type="number"],
.una-form-section textarea {
  width: 100%;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 10px;
  color: var(--text-color);
  margin-bottom: 15px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.una-form-section input[type="text"]:focus,
.una-form-section input[type="number"]:focus,
.una-form-section textarea:focus {
  outline: none;
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  background: rgba(0, 255, 255, 0.05);
}

.una-form-section textarea {
  min-height: 120px;
  resize: vertical;
}

/* Variant Section */
.una-variant-inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  background: rgba(26, 26, 46, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  transition: all 0.3s ease;
}

.una-variant-inputs:hover {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
}

.una-add-variant-button {
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-add-variant-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

/* Image Upload Section */
.una-file-input {
  display: none;
}

.una-image-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.una-preview-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  transition: all 0.3s ease;
}

.una-preview-image:hover {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  transform: scale(1.05);
}

/* Submit Button */
.una-submit-button {
  background: transparent;
  border: 2px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: 15px 30px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 20px;
  align-self: center;
}

.una-submit-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.una-submit-button:disabled {
  border-color: rgba(0, 255, 255, 0.3);
  color: rgba(0, 255, 255, 0.3);
  cursor: not-allowed;
  pointer-events: none;
}

/* Error Message */
.una-error-message {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid #ff4444;
  color: #ff4444;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .una-variant-inputs {
    grid-template-columns: 1fr;
  }
  
  .una-create-product-header {
    flex-direction: column;
    text-align: center;
  }
  
  .una-create-product-header h2 {
    font-size: 1.5rem;
  }
  
  .una-form-section {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .una-create-product-container {
    width: 95%;
    padding: 10px;
  }
  
  .una-submit-button {
    width: 100%;
  }
}
