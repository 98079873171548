.custom-button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  /* Remove focus outline */
  .custom-button:focus {
    outline: none;
  }
  
  /* Primary variant */
  .custom-button--primary {
    background-color: var(--primary-color);
    color: var(--background-color);
  }
  
  .custom-button--primary:hover {
    background-color: var(--primary-light);
    transform: translateY(-2px);
  }
  
  /* Secondary variant */
  .custom-button--secondary {
    background-color: var(--secondary-color);
    color: var(--background-color);
  }
  
  .custom-button--secondary:hover {
    background-color: var(--secondary-light);
    transform: translateY(-2px);
  }
  
  /* Tertiary variant: outline style */
  .custom-button--tertiary {
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  
  .custom-button--tertiary:hover {
    background-color: rgba(0, 255, 157, 0.1);
    transform: translateY(-2px);
  }
  
  /* Disabled state */
  .custom-button--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }
  