/* UserStatisticsPanel.css */

.user-statistics-panel {
  padding: 24px;
  background-color: rgba(13, 14, 26, 0.7);
  min-height: 100vh;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.panel-title {
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
}

.panel-title .icon {
  color: #00ff9d;
  filter: drop-shadow(0 0 8px rgba(0, 255, 157, 0.4));
}

.period-selector {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  background: rgba(13, 14, 26, 0.5);
  padding: 4px;
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  width: fit-content;
}

.period-selector button {
  padding: 10px 20px;
  background: transparent;
  color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
}

.period-selector button:hover {
  background: rgba(0, 255, 157, 0.1);
  transform: translateY(-1px);
}

.period-selector button.active {
  background: rgba(0, 255, 157, 0.15);
  color: var(--neon-green, #00ff9d);
  border-color: rgba(0, 255, 157, 0.3);
  box-shadow: 0 0 12px rgba(0, 255, 157, 0.2);
}

.charts-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.line-charts {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sentiment-statistics-section,
.resolved-statistics-section,
.tags-statistics-section {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease;
}

.sentiment-statistics-section:hover,
.resolved-statistics-section:hover,
.tags-statistics-section:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
}

.sentiment-statistics-section h3,
.resolved-statistics-section h3,
.tags-statistics-section h3 {
  padding: 16px 20px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
  background-color: rgba(13, 14, 26, 0.8);
}

.chart-container {
  padding: 20px;
  height: 300px;
  background: rgba(13, 14, 26, 0.5);
}

h3 {
  font-size: 1.4em;
  margin-bottom: 20px;
  color: #00ffff;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
}

.period-selector button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #8000ff, 0 0 0 4px rgba(128, 0, 255, 0.3);
}

.loading {
  color: #00ff9d;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.4);
}

.error-message {
  color: #ff00ff;
  background: rgba(255, 0, 255, 0.1);
  border-left: 4px solid #ff00ff;
  padding: 12px 20px;
  margin: 20px 0;
  border-radius: 0 4px 4px 0;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.4);
}

.una-sentiment-loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 48px);
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
}

.una-loading-content {
  max-width: 600px;
  padding: 40px;
  text-align: center;
}

.una-loading-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 32px;
}

.una-loading-icons svg {
  font-size: 32px;
  opacity: 0.8;
  animation: floatIcon 3s ease-in-out infinite;
}

.una-loading-icons .icon-primary {
  color: var(--neon-green, #00ff9d);
  animation-delay: 0s;
}

.una-loading-icons .icon-secondary {
  color: var(--neon-cyan, #00ffff);
  animation-delay: 0.3s;
}

.una-loading-icons .icon-tertiary {
  color: var(--neon-magenta, #ff00ff);
  animation-delay: 0.6s;
}

.una-loading-content h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
  color: #fff;
  letter-spacing: 0.5px;
}

.una-loading-progress {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
  margin-bottom: 32px;
  overflow: hidden;
  position: relative;
}

.una-progress-bar {
  height: 100%;
  background: linear-gradient(90deg, var(--neon-cyan), var(--neon-green));
  border-radius: 3px;
  animation: progress 2s infinite;
  position: relative;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  animation: glow 2s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.una-metric-item {
  padding: 16px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-metric-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes floatIcon {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes progress {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes glow {
  0% { left: -50%; }
  100% { left: 100%; }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .charts-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .user-statistics-panel {
    padding: 16px;
  }
  
  .period-selector {
    width: 100%;
    justify-content: center;
  }
  
  .chart-container {
    height: 250px;
  }
  
  .una-loading-icons svg {
    font-size: 28px;
  }
  
  .una-loading-content h2 {
    font-size: 20px;
  }
}

/* Dashboard Grid Layout */
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(220px, auto);
  gap: 16px;
  margin-bottom: 24px;
}

.dashboard-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.dashboard-card:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
}

.dashboard-card h3 {
  padding: 12px 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
  background-color: rgba(13, 14, 26, 0.8);
}

.small-chart {
  padding: 12px;
  flex: 1;
  min-height: 0;
  height: auto;
}

/* Card Sizes */
.sentiment-card, .resolved-card {
  grid-column: span 2;
}

.carriers-card, .satisfaction-card, .issues-card, 
.resolution-counts-card, .resolution-times-card, .geo-card {
  grid-column: span 2;
}

.clients-volume-card, .client-issues-card {
  grid-column: span 2;
}

/* Compact Tables */
.compact-table {
  padding: 8px;
  overflow-y: auto;
  max-height: 180px;
}

.compact-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.compact-table th, .compact-table td {
  padding: 6px 8px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.compact-table th {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.2);
}

.compact-table td {
  color: rgba(255, 255, 255, 0.7);
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .dashboard-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1100px) {
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .sentiment-card, .resolved-card, 
  .carriers-card, .satisfaction-card, .issues-card, 
  .resolution-counts-card, .resolution-times-card, .geo-card,
  .clients-volume-card, .client-issues-card {
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(200px, auto);
  }
  
  .user-statistics-panel {
    padding: 12px;
  }
  
  .period-selector {
    margin-bottom: 16px;
  }
  
  .dashboard-card h3 {
    font-size: 13px;
    padding: 10px 12px;
  }
  
  .small-chart {
    padding: 8px;
  }
}