/* src/components/AIAgentsList.css */

.agents-list-container {
    background: var(--gradient-card);
    backdrop-filter: blur(10px);
    padding: 25px;
    border-radius: 20px;
    border: 1px solid var(--border-color);
    box-shadow: var(--glow-green);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .agents-list-container h3 {
    font-size: 24px;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-shadow: var(--glow-green);
  }
  
  .agents-list-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    gap: 20px;
  }
  
  .agents-list-loading p {
    color: var(--primary-color);
    font-size: 16px;
    animation: pulse 2s infinite;
  }
  
  .run-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
  }
  
  .run-button:hover {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
  }
  
  .run-button.running {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
  }
  
  .run-button.running:hover {
    background: rgba(255, 0, 255, 0.1);
    box-shadow: var(--glow-magenta);
  }

/* Agent Cards Styling */
.agent-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.agent-card {
  background: var(--gradient-card);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 220px;
  position: relative;
  overflow: hidden;
}

.agent-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--glow-green);
  border-color: var(--primary-color);
}

.agent-card.running {
  border-color: var(--secondary-color);
  box-shadow: var(--glow-magenta);
}

.agent-card.running:hover {
  box-shadow: 0 0 25px rgba(255, 0, 255, 0.4);
}

.agent-card-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.agent-card-title {
  flex: 1;
}

.agent-card-title h4 {
  color: var(--text-color);
  font-size: 18px;
  margin: 0 0 5px 0;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.running-badge {
  font-size: 12px;
  padding: 3px 8px;
  background: rgba(255, 0, 255, 0.15);
  border-radius: 10px;
  color: var(--secondary-color);
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.running-badge::before {
  content: '';
  width: 6px;
  height: 6px;
  background: var(--secondary-color);
  border-radius: 50%;
  box-shadow: var(--glow-magenta);
  animation: pulse 2s infinite;
}

.agent-card-prompt {
  margin-bottom: 15px;
}

.agent-card-prompt h5 {
  color: var(--primary-light);
  font-size: 14px;
  margin: 0 0 5px 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.agent-card-prompt p {
  color: var(--text-color);
  font-size: 14px;
  margin: 0;
  opacity: 0.9;
  line-height: 1.4;
  max-height: 60px;
  overflow: hidden;
  background: rgba(26, 26, 46, 0.5);
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.agent-card-tools {
  margin-bottom: 15px;
}

.agent-card-tools h5 {
  color: var(--primary-light);
  font-size: 14px;
  margin: 0 0 8px 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.agent-tools-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.agent-tool-badge {
  font-size: 12px;
  padding: 4px 10px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 10px;
  color: var(--primary-color);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.agent-tool-badge.more {
  background: rgba(0, 255, 255, 0.1);
  color: var(--primary-light);
  border-color: rgba(0, 255, 255, 0.2);
}

.agent-card-actions {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.agent-card .run-button {
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.agent-card .run-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
}

.agent-card .run-button.running {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.agent-card .run-button.running:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
}

@media (max-width: 768px) {
  .agent-cards-grid {
    grid-template-columns: 1fr;
  }
}

.agents-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px;
}

/* Email Accounts Section */
.email-accounts-section {
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 15px 20px;
  margin: 20px 0;
}

.email-accounts-section h4 {
  color: var(--primary-light);
  font-size: 16px;
  margin: 0 0 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-accounts-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.email-account-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: rgba(26, 26, 46, 0.5);
  border-radius: 10px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.email-provider {
  font-size: 13px;
  font-weight: 500;
  padding: 3px 8px;
  border-radius: 8px;
  text-transform: uppercase;
}

.email-provider.gmail {
  background: rgba(219, 68, 55, 0.2);
  color: #DB4437;
  border: 1px solid rgba(219, 68, 55, 0.3);
}

.email-provider.outlook {
  background: rgba(0, 120, 212, 0.2);
  color: #0078D4;
  border: 1px solid rgba(0, 120, 212, 0.3);
}

.email-address {
  color: var(--text-color);
  font-size: 14px;
}

/* Email Association */
.agent-email-association {
  margin-bottom: 15px;
}

.agent-email-association h5 {
  color: var(--primary-light);
  font-size: 14px;
  margin: 0 0 5px 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.associated-email {
  color: var(--text-color);
  font-size: 14px;
  background: rgba(26, 26, 46, 0.5);
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.email-button {
  background: transparent;
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.email-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  border-color: var(--primary-light);
}

/* Email Selector Modal */
.email-selector-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 14, 26, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.email-selector-content {
  background: var(--gradient-card);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  padding: 25px;
  width: 90%;
  max-width: 500px;
  box-shadow: var(--glow-green);
}

.email-selector-content h3 {
  color: var(--primary-color);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: var(--glow-green);
}

.email-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.email-option-button {
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 15px;
  color: var(--text-color);
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}

.email-option-button:hover {
  background: rgba(0, 255, 157, 0.1);
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: var(--glow-green);
}

.email-option-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.association-success {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(0, 200, 83, 0.15);
  border: 1px solid rgba(0, 200, 83, 0.3);
  border-radius: 10px;
  color: #00C853;
  margin-bottom: 20px;
}

.association-error {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(255, 0, 0, 0.15);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 10px;
  color: #FF3D00;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .agent-cards-grid {
    grid-template-columns: 1fr;
  }
  
  .email-accounts-list {
    flex-direction: column;
  }
}

/* Remove background container and create a cleaner layout */
.agents-list-wrapper {
  padding: 25px;
  max-width: 1200px;
  margin: 0 auto;
}

.agents-list-wrapper h3 {
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: var(--glow-green);
}

/* Agent Cards Grid - Enhanced */
.agent-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.agent-card {
  background: var(--gradient-card);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 220px;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

/* Email circles at bottom of page */
.email-circles-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 250px;
  position: relative;
  z-index: 1;
}

.email-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(13, 14, 26, 0.8);
  border: 2px solid var(--primary-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-light);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  position: relative;
  transition: all 0.3s ease;
  cursor: default;
}

.email-circle svg {
  font-size: 22px;
  margin-bottom: 5px;
}

.email-text {
  font-size: 0;
  transition: all 0.3s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
  opacity: 0;
  position: absolute;
  bottom: -25px;
}

.email-circle:hover {
  transform: scale(1.1);
}

.email-circle:hover .email-text {
  font-size: 12px;
  opacity: 1;
}

/* Pulsating connection between agent and email */
.agent-email-link {
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 4px;
  background: linear-gradient(to bottom, var(--primary-color), transparent);
  height: 200px;
  z-index: -1;
  transform-origin: top;
  opacity: 0.7;
  animation: pulseLink 2s infinite;
}

.has-email-connection::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: 0 0 10px var(--primary-color);
  z-index: 3;
  animation: pulseNode 2s infinite;
}

@keyframes pulseLink {
  0%, 100% {
    opacity: 0.3;
    box-shadow: 0 0 5px var(--primary-color);
  }
  50% {
    opacity: 0.8;
    box-shadow: 0 0 15px var(--primary-color);
  }
}

@keyframes pulseNode {
  0%, 100% {
    box-shadow: 0 0 5px var(--primary-color);
    transform: translateX(-50%) scale(1);
  }
  50% {
    box-shadow: 0 0 15px var(--primary-color);
    transform: translateX(-50%) scale(1.3);
  }
}

/* Add dynamic connecting lines using JavaScript */
.agent-card.has-email-connection {
  position: relative;
  z-index: 2;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .agent-cards-grid {
    grid-template-columns: 1fr;
  }
  
  .email-circles-container {
    flex-direction: row;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 20px 0;
    margin-top: 30px;
  }
  
  .email-circle {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
  
  .email-circle svg {
    font-size: 18px;
  }
}

/* Create the actual connections with JavaScript - these styles support that */
@media (max-width: 1200px) {
  .agent-cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1600px) {
  .agent-cards-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Add JavaScript initialization to create the connections dynamically */

/* Pulsating connection line between agent and email circle */
.pulsating-path {
  animation: dashOffset 30s linear infinite, pulsePath 2s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes dashOffset {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes pulsePath {
  0%, 100% {
    stroke-opacity: 0.3;
    stroke-width: 2px;
    filter: drop-shadow(0 0 2px var(--primary-color));
  }
  50% {
    stroke-opacity: 0.8;
    stroke-width: 3px;
    filter: drop-shadow(0 0 5px var(--primary-color));
  }
}

/* Channel selector modal */
.channel-selector-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.channel-selector-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.channel-options {
  margin: 20px 0;
  max-height: 300px;
  overflow-y: auto;
}

.channel-option {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.channel-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin-right: 15px;
}

.channel-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.channel-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 26px;
}

.channel-toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .channel-toggle-slider {
  background-color: var(--primary-color);
}

input:focus + .channel-toggle-slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .channel-toggle-slider:before {
  transform: translateX(24px);
}

.channel-name {
  font-weight: 500;
}

/* Channel button and badges */
.channel-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.3s;
}

.channel-button:hover {
  background-color: #e0e0e0;
}

.agent-card-channels {
  margin-top: 10px;
}

.agent-channels-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.agent-channel-badge {
  background-color: var(--primary-color-light);
  color: var(--primary-color-dark);
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.agent-channel-badge.more {
  background-color: #f0f0f0;
  color: #666;
}

/* Combined connections container */
.connections-container {
  margin-top: 100px;
  position: relative;
  z-index: 1;
  padding: 20px;
}

.connections-heading {
  color: var(--text-color);
  font-size: 18px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.circles-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  position: relative;
}

/* Base styles for all connection circles */
.connection-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(13, 14, 26, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;
  cursor: default;
}

.connection-circle .circle-text {
  font-size: 0;
  transition: all 0.3s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
  opacity: 0;
  position: absolute;
  bottom: -25px;
}

.connection-circle:hover {
  transform: scale(1.1);
}

.connection-circle:hover .circle-text {
  font-size: 12px;
  opacity: 1;
}

/* Email circle specific styles */
.email-circle {
  border: 2px solid var(--primary-light);
  color: var(--primary-light);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
}

.email-circle svg {
  font-size: 22px;
  margin-bottom: 5px;
}

/* Channel circle specific styles */
.channel-circle {
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
  box-shadow: 0 0 15px rgba(255, 0, 255, 0.3);
}

/* Updated channel icon styling to better accommodate Font Awesome icons */
.channel-icon {
  font-size: 22px;
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 0, 255, 0.1);
  color: var(--secondary-color);
}

.channel-icon svg {
  font-size: 22px;
}

/* Connection indicators on agent cards */
.has-email-connection::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: 0 0 10px var(--primary-color);
  z-index: 3;
  animation: pulseNode 2s infinite;
}

.has-channel-connection::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: calc(50% + 5px);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  box-shadow: 0 0 10px var(--secondary-color);
  z-index: 3;
  animation: pulseChannelNode 2s infinite;
}

/* Pulsating SVG paths */
.pulsating-path {
  animation: dashOffset 30s linear infinite, pulsePath 2s ease-in-out infinite;
  stroke-linecap: round;
}

/* Different pulse animations for different connection types */
@keyframes pulsePath {
  0%, 100% {
    stroke-opacity: 0.3;
    stroke-width: 2px;
  }
  50% {
    stroke-opacity: 0.8;
    stroke-width: 3px;
  }
}

@keyframes pulseNode {
  0%, 100% {
    box-shadow: 0 0 5px var(--primary-color);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 15px var(--primary-color);
    transform: scale(1.3);
  }
}

@keyframes pulseChannelNode {
  0%, 100% {
    box-shadow: 0 0 5px var(--secondary-color);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 15px var(--secondary-color);
    transform: scale(1.3);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .circles-wrapper {
    flex-direction: row;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 10px 0;
  }
  
  .connection-circle {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
  
  .email-circle svg, .channel-icon svg {
    font-size: 18px;
  }
  
  .channel-icon {
    width: 30px;
    height: 30px;
  }
}