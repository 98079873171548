:root {
  /* Primary Neons */
  --neon-green: #00ff9d;
  --neon-cyan: #00ffff;
  --neon-magenta: #ff00ff;

  /* Backgrounds */
  --bg-dark-base: #1a1a2e;
  --bg-dark-secondary: #16213e;
  --bg-card: rgba(13, 14, 26, 0.95);
  --bg-card-hover: rgba(13, 14, 26, 0.7);

  /* Gradients */
  --gradient-bg: linear-gradient(135deg, var(--bg-dark-base) 0%, var(--bg-dark-secondary) 100%);
  --gradient-button: linear-gradient(45deg, var(--neon-green), var(--neon-cyan));

  /* Glows & Shadows */
  --glow-green: 0 0 15px rgba(0, 255, 157, 0.5);
  --glow-cyan: 0 0 10px rgba(0, 255, 255, 0.4);
  --glow-magenta: 0 0 10px rgba(255, 0, 255, 0.4);
}

.unanimity-ticket-archive-wrapper {
  min-height: 100vh;
  padding: 30px;
  color: #fff;
}

.unanimity-ticket-archive-header {
  margin-bottom: 30px;
  padding-left: 30px;
}

.unanimity-ticket-archive-title {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 2.2em;
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.unanimity-ticket-archive-title-icon {
  color: var(--neon-green);
  filter: drop-shadow(var(--glow-green));
}

.unanimity-ticket-archive-container {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 25px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.unanimity-ticket-archive-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.unanimity-ticket-archive-filter {
  display: flex;
  gap: 16px;
  align-items: center;
}

.unanimity-ticket-archive-filter-label {
  color: var(--neon-cyan);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.unanimity-ticket-archive-filter-select {
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s ease;
  min-width: 180px;
}

.unanimity-ticket-archive-filter-select:focus {
  border-color: var(--neon-green);
  box-shadow: 0 0 0 2px rgba(0, 255, 157, 0.2);
  outline: none;
}

.unanimity-ticket-archive-search {
  display: flex;
  align-items: center;
  gap: 8px;
}

.unanimity-ticket-archive-search-input {
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s ease;
  min-width: 180px;
}

.unanimity-ticket-archive-search-input:focus {
  border-color: var(--neon-green);
  box-shadow: 0 0 0 2px rgba(0, 255, 157, 0.2);
  outline: none;
}

.unanimity-ticket-archive-search-button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.unanimity-ticket-archive-search-button:hover:not(:disabled) {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-1px);
}

.unanimity-ticket-archive-search-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.unanimity-ticket-archive-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 16px;
  font-size: 14px;
}

.unanimity-ticket-archive-table thead {
  background: rgba(13, 14, 26, 0.7);
  position: sticky;
  top: 0;
  z-index: 10;
}

.unanimity-ticket-archive-table-header {
  text-align: left;
  padding: 16px;
  color: var(--neon-cyan);
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(0, 255, 255, 0.2);
  white-space: nowrap;
  font-size: 13px;
  text-transform: uppercase;
}

.unanimity-ticket-archive-table-cell {
  padding: 16px;
  background: rgba(13, 14, 26, 0.5);
  color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 255, 157, 0.05);
  text-align: left;
  vertical-align: middle;
  transition: background 0.2s ease;
}

.unanimity-ticket-archive-table-row:hover .unanimity-ticket-archive-table-cell {
  background: rgba(0, 255, 157, 0.05);
  cursor: pointer;
}

.unanimity-ticket-archive-status {
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.7px;
  display: inline-block;
  text-align: center;
  min-width: 110px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.unanimity-ticket-archive-status-closed {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4d4d;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.unanimity-ticket-archive-status-resolved {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.unanimity-ticket-archive-status-ai-resolved {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.unanimity-ticket-archive-sentiment {
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  min-width: 60px;
  letter-spacing: 0.5px;
  font-size: 13px;
}

.unanimity-ticket-archive-sentiment-low {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4d4d;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.unanimity-ticket-archive-sentiment-medium {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

.unanimity-ticket-archive-sentiment-high {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.unanimity-ticket-archive-sentiment-null {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 1200px) {
  .unanimity-ticket-archive-controls {
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .unanimity-ticket-archive-filter {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .unanimity-ticket-archive-table {
    display: block;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .unanimity-ticket-archive-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .unanimity-ticket-archive-filter,
  .unanimity-ticket-archive-search {
    width: 100%;
    margin-bottom: 12px;
  }
  
  .unanimity-ticket-archive-filter-select,
  .unanimity-ticket-archive-search-input {
    width: 100%;
  }
  
  .unanimity-ticket-archive-table-header,
  .unanimity-ticket-archive-table-cell {
    padding: 12px 10px;
    font-size: 14px;
  }
}