.live-session-container {
  padding: 20px;
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;
}

/* Header Section */
.live-session-header {
  margin-bottom: 20px;
}

.live-session-title {
  color: var(--neon-cyan);
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
}

/* Summary Section */
.session-summary {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.summary-item {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 15px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transition: all 0.3s ease;
}

.summary-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--glow-cyan);
}

.summary-item h3 {
  color: var(--text-color);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  opacity: 0.8;
}

.summary-item p {
  color: var(--neon-green);
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: var(--glow-green);
}

/* Grid Container */
.live-list-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.live-list-grid-column {
  background: var(--gradient-card);
  border-radius: 15px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
}

.live-list-grid-column h2 {
  color: var(--neon-cyan);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-shadow: var(--glow-cyan);
}

/* Orders Section */
.orders-scroll-container, .customers-scroll-container {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.orders-scroll-container::-webkit-scrollbar,
.customers-scroll-container::-webkit-scrollbar {
  width: 5px;
}

.orders-scroll-container::-webkit-scrollbar-track,
.customers-scroll-container::-webkit-scrollbar-track {
  background: rgba(26, 26, 46, 0.7);
}

.orders-scroll-container::-webkit-scrollbar-thumb,
.customers-scroll-container::-webkit-scrollbar-thumb {
  background: var(--neon-green);
  border-radius: 3px;
}

.order-card, .customer-card {
  background: rgba(26, 26, 46, 0.9);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.order-card:hover, .customer-card:hover {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
  transform: translateX(5px);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.order-id {
  color: var(--neon-cyan);
  font-size: 0.9rem;
  text-shadow: var(--glow-cyan);
}

.order-total {
  color: var(--neon-green);
  font-weight: 600;
  text-shadow: var(--glow-green);
}

.order-info p, .customer-card p {
  color: var(--text-color);
  font-size: 0.85rem;
  margin: 5px 0;
  opacity: 0.8;
}

.order-items {
  margin-top: 10px;
}

.order-item {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  padding: 8px;
  background: rgba(26, 26, 46, 0.7);
  border-radius: 8px;
}

.item-image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.item-details p {
  color: var(--text-color);
  font-size: 0.85rem;
  margin: 2px 0;
}

/* Action Buttons */
.complete-orders-btn, .complete-order-btn {
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
}

.complete-orders-btn:hover, .complete-order-btn:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.completing {
  border-color: var(--neon-cyan);
  color: var(--neon-cyan);
  pointer-events: none;
}

/* Status Messages */
.completion-status, .order-status {
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  font-size: 0.8rem;
}

.success {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid var(--neon-green);
}

.error {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4444;
  border: 1px solid #ff4444;
}

/* Search Input */
.search-container {
  margin-bottom: 15px;
}

.search-input {
  width: 100%;
  padding: 10px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .session-summary {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .live-list-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .session-summary {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .live-list-grid-container {
    grid-template-columns: 1fr;
  }
  
  .live-list-grid-column {
    height: auto;
    min-height: 400px;
  }
}
