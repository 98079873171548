.una-marketplace-container {
  min-height: 100vh;
  padding: 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.una-marketplace-content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.una-marketplace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.una-marketplace-header h2 {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 1.8rem;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.una-publish-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: 1px solid var(--neon-green, #00ff9d);
  color: var(--neon-green, #00ff9d);
  border-radius: 12px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.una-publish-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: rgba(0, 255, 157, 0.3);
  color: rgba(0, 255, 157, 0.5);
}

.una-publish-button:hover:not(:disabled) {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.3);
  transform: translateY(-2px);
}

.una-marketplace-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 28px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  align-items: center;
}

.una-search-group {
  position: relative;
  flex: 1;
  min-width: 200px;
}

.una-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--neon-cyan, #00ffff);
  font-size: 16px;
  opacity: 0.7;
}

.una-search-input {
  width: 100%;
  padding: 12px 16px 12px 36px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
}

.una-search-input:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-search-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.una-filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-filter-label {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--neon-cyan, #00ffff);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.una-filter-select {
  padding: 10px 16px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  min-width: 160px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.una-filter-select:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-marketplace-sections {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.una-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--neon-cyan, #00ffff);
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.2);
}

.una-marketplace-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

.una-marketplace-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.una-marketplace-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 255, 157, 0.2);
  border-color: rgba(0, 255, 157, 0.2);
}

.una-product-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
  background: rgba(13, 14, 26, 0.5);
}

.una-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.una-marketplace-card:hover .una-product-image {
  transform: scale(1.1);
}

.una-product-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.3);
  font-size: 2.5rem;
}

.una-featured-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(255, 215, 0, 0.2);
  color: #FFD700;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid rgba(255, 215, 0, 0.3);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
  backdrop-filter: blur(4px);
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  letter-spacing: 0.5px;
  z-index: 10;
}

.una-product-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.una-product-details h4 {
  margin-top: 0;
  margin-bottom: 12px;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.una-product-meta {
  margin-bottom: 16px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  min-height: 110px;
}

.una-product-price {
  font-weight: 700;
  font-size: 18px;
  color: var(--neon-cyan, #00ffff);
  margin: 8px 0;
  letter-spacing: 0.5px;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.3);
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.2;
}

.una-product-commission {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 8px 0;
  line-height: 1.2;
}

.una-product-expiry {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 8px 0;
  line-height: 1.2;
}

.una-product-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  min-height: 36px;
}

.una-category-tag {
  display: flex;
  align-items: center;
  gap: 5px;
  background: rgba(121, 80, 242, 0.1);
  color: #9d75ff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid rgba(121, 80, 242, 0.2);
  transition: all 0.2s ease;
  white-space: nowrap;
}

.una-category-tag:hover {
  background: rgba(121, 80, 242, 0.2);
  box-shadow: 0 0 8px rgba(121, 80, 242, 0.3);
}

.una-remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  background: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff5b5b;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: auto;
}

.una-remove-button:hover {
  background: rgba(255, 0, 0, 0.15);
  border-color: rgba(255, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
  transform: translateY(-2px);
}

.una-products-selection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

.una-product-selection-card {
  position: relative;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.una-product-selection-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 255, 157, 0.2);
  border-color: rgba(0, 255, 157, 0.2);
}

.una-product-checkbox-wrapper {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
}

.una-product-checkbox {
  appearance: none;
  width: 22px;
  height: 22px;
  border: 2px solid rgba(0, 255, 157, 0.4);
  background: rgba(13, 14, 26, 0.7);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.una-product-checkbox:checked {
  background: var(--neon-green, #00ff9d);
  border-color: var(--neon-green, #00ff9d);
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
}

.una-product-checkbox:checked:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 12px;
  border: solid rgba(13, 14, 26, 0.9);
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 7px;
  transform: rotate(45deg);
}

.una-product-checkbox:hover {
  border-color: var(--neon-green, #00ff9d);
  box-shadow: 0 0 8px rgba(0, 255, 157, 0.2);
}

.una-product-content {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.una-product-image-wrapper {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  background: rgba(13, 14, 26, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.una-product-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.una-product-selection-card:hover .una-product-thumbnail {
  transform: scale(1.1);
}

.una-product-info {
  flex: 1;
  min-width: 0;
  padding-top: 4px;
}

.una-product-info h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #fff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.3;
  letter-spacing: 0.3px;
  height: auto;
  font-weight: 600;
  max-height: 42px;
}

.una-product-info .una-product-price {
  font-size: 16px;
  color: var(--neon-cyan, #00ffff);
  font-weight: 700;
  margin: 0 0 10px 0;
  letter-spacing: 0.5px;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.3);
}

.una-product-status {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 8px;
  background: rgba(121, 80, 242, 0.1);
  color: #9d75ff;
  border: 1px solid rgba(121, 80, 242, 0.2);
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-top: 8px;
}

.una-product-status.active {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green, #00ff9d);
  border-color: rgba(0, 255, 157, 0.2);
}

.una-product-status.archived {
  background: rgba(255, 0, 0, 0.1);
  color: #ff5b5b;
  border-color: rgba(255, 0, 0, 0.2);
}

.una-product-status.draft {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border-color: rgba(255, 165, 0, 0.2);
}

.una-product-quantity {
  margin-top: 16px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  padding: 10px;
  border: 1px solid rgba(0, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.una-product-quantity label {
  color: var(--neon-cyan, #00ffff);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.una-quantity-input {
  width: 60px;
  padding: 6px 10px;
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 6px;
  background: rgba(13, 14, 26, 0.7);
  color: #fff;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s ease;
}

.una-quantity-input:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 8px rgba(0, 255, 255, 0.2);
}

.una-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.una-empty-state svg {
  font-size: 3rem;
  margin-bottom: 20px;
  color: var(--neon-cyan, #00ffff);
  opacity: 0.8;
}

.una-empty-state p {
  font-size: 18px;
  margin-bottom: 20px;
  max-width: 400px;
  line-height: 1.5;
}

.una-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.una-modal {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.95) 0%, rgba(19, 21, 40, 0.95) 100%);
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 255, 157, 0.15);
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.una-modal h3 {
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--neon-cyan, #00ffff);
  font-size: 24px;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
  letter-spacing: 0.5px;
  font-weight: 600;
}

.una-modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.una-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.una-form-group label {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-range-input-group {
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.una-range-input-group input[type="range"] {
  flex: 1;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  outline: none;
}

.una-range-input-group input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--neon-green, #00ff9d);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.5);
  transition: all 0.2s ease;
}

.una-range-input-group input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.una-range-value {
  font-weight: 700;
  color: var(--neon-green, #00ff9d);
  min-width: 50px;
  text-align: center;
  font-size: 16px;
  text-shadow: 0 0 8px rgba(0, 255, 157, 0.5);
  letter-spacing: 0.5px;
}

.una-helper-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  margin: 0;
  font-style: italic;
  letter-spacing: 0.3px;
}

.una-categories-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 16px;
  background: rgba(13, 14, 26, 0.5);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  max-height: 180px;
  overflow-y: auto;
}

.una-category-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  background: rgba(121, 80, 242, 0.05);
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid rgba(121, 80, 242, 0.1);
  transition: all 0.2s ease;
  color: rgba(255, 255, 255, 0.8);
}

.una-category-checkbox:hover {
  background: rgba(121, 80, 242, 0.1);
  color: #fff;
}

.una-category-checkbox input {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(121, 80, 242, 0.4);
  background: rgba(13, 14, 26, 0.7);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.una-category-checkbox input:checked {
  background: #9d75ff;
  border-color: #9d75ff;
}

.una-category-checkbox input:checked:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 9px;
  border: solid rgba(13, 14, 26, 0.9);
  border-width: 0 2px 2px 0;
  top: 1px;
  left: 5px;
  transform: rotate(45deg);
}

.una-add-category-button {
  background: transparent;
  border: 1px dashed rgba(0, 255, 255, 0.3);
  color: var(--neon-cyan, #00ffff);
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
  align-self: flex-start;
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.una-add-category-button:hover {
  background: rgba(0, 255, 255, 0.05);
  border-color: rgba(0, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: rgba(255, 215, 0, 0.05);
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 215, 0, 0.1);
  transition: all 0.2s ease;
}

.una-checkbox-label:hover {
  background: rgba(255, 215, 0, 0.1);
  border-color: rgba(255, 215, 0, 0.2);
}

.una-checkbox-label input {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 215, 0, 0.4);
  background: rgba(13, 14, 26, 0.7);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.una-checkbox-label input:checked {
  background: #FFD700;
  border-color: #FFD700;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.una-checkbox-label input:checked:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid rgba(13, 14, 26, 0.9);
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 6px;
  transform: rotate(45deg);
}

.una-form-group input[type="date"] {
  padding: 12px 16px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  width: 100%;
  cursor: pointer;
}

.una-form-group input[type="date"]:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-form-group input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.7;
  cursor: pointer;
}

.una-form-group input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.una-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 30px;
}

.una-cancel-button {
  padding: 10px 20px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.una-cancel-button:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.una-submit-button {
  padding: 10px 24px;
  background: transparent;
  border: 1px solid var(--neon-green, #00ff9d);
  color: var(--neon-green, #00ff9d);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.una-submit-button:hover:not(:disabled) {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.3);
  transform: translateY(-2px);
}

.una-submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: rgba(0, 255, 157, 0.3);
  color: rgba(0, 255, 157, 0.5);
}

.una-categories-selection::-webkit-scrollbar {
  width: 6px;
}

.una-categories-selection::-webkit-scrollbar-track {
  background: rgba(13, 14, 26, 0.5);
  border-radius: 3px;
}

.una-categories-selection::-webkit-scrollbar-thumb {
  background: rgba(121, 80, 242, 0.3);
  border-radius: 3px;
}

.una-categories-selection::-webkit-scrollbar-thumb:hover {
  background: rgba(121, 80, 242, 0.5);
}

.una-modal::-webkit-scrollbar {
  width: 6px;
}

.una-modal::-webkit-scrollbar-track {
  background: rgba(13, 14, 26, 0.5);
  border-radius: 3px;
}

.una-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 157, 0.3);
  border-radius: 3px;
}

.una-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 255, 157, 0.5);
}

@media (max-width: 1200px) {
  .una-marketplace-filters {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .una-search-group {
    width: 100%;
  }
  
  .una-filter-group {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .una-marketplace-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .una-section h3 {
    font-size: 1.2rem;
  }
  
  .una-marketplace-grid, 
  .una-products-selection-grid {
    grid-template-columns: 1fr;
  }
  
  .una-modal {
    padding: 20px;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .una-product-content {
    flex-direction: column;
  }
  
  .una-product-image-wrapper {
    width: 100%;
    height: 140px;
  }
  
  .una-range-input-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .una-range-input-group input[type="range"] {
    width: 100%;
  }
  
  .una-modal-footer {
    flex-direction: column;
    gap: 10px;
  }
  
  .una-cancel-button, 
  .una-submit-button {
    width: 100%;
    text-align: center;
  }
}

.una-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.una-section-header h3 {
  margin: 0;
}

.una-marketplace-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
  width: 100%;
}