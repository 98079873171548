:root {
  --card-bg: rgba(13, 14, 26, 0.85);
  --card-bg-hover: rgba(20, 22, 40, 0.9);
  --card-border: rgba(0, 255, 157, 0.15);
  --card-border-hover: rgba(0, 255, 157, 0.3);
  --card-shadow: 0 4px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 255, 157, 0.1);
  --header-border: rgba(0, 255, 255, 0.2);
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-tertiary: rgba(255, 255, 255, 0.5);
  --neon-green: #00ff9d;
  --neon-cyan: #00ffff;
  --neon-magenta: #ff00ff;
  --neon-gold: #ffd700;
  --neon-green-glow: 0 0 8px rgba(0, 255, 157, 0.4);
  --neon-cyan-glow: 0 0 8px rgba(0, 255, 255, 0.4);
  --neon-gold-glow: 0 0 8px rgba(255, 215, 0, 0.4);
  --status-success: rgba(0, 255, 157, 0.9);
  --status-warning: rgba(255, 165, 0, 0.9);
  --status-danger: rgba(255, 0, 85, 0.9);
  --button-bg: rgba(0, 255, 157, 0.1);
  --button-border: rgba(0, 255, 157, 0.3);
  --button-hover: rgba(0, 255, 157, 0.2);
  --badge-bg: rgba(0, 0, 0, 0.4);
  --input-bg: rgba(20, 22, 40, 0.7);
  --input-border: rgba(0, 255, 157, 0.2);
  --input-focus-border: rgba(0, 255, 255, 0.5);
  --divider: rgba(0, 255, 255, 0.1);
}

/* Main Container */
.una-order-details-container {
  padding: 24px;
  background-color: rgba(13, 14, 26, 0.7);
  min-height: 100vh;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  box-sizing: border-box;
  color: var(--text-primary);
  font-family: 'Inter', 'Roboto', -apple-system, sans-serif;
}

/* Back Button */
.una-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  margin-bottom: 20px;
  text-shadow: var(--neon-cyan-glow);
}

.una-back-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--neon-cyan-glow);
  transform: translateY(-2px);
}

/* Header with actions */
.una-order-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.una-order-actions {
  display: flex;
  gap: 12px;
}

.una-action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--button-bg);
  border: 1px solid var(--button-border);
  color: var(--neon-green);
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.una-action-button:hover {
  background: var(--button-hover);
  box-shadow: var(--neon-green-glow);
  transform: translateY(-2px);
}

/* Order Overview Section */
.una-order-overview {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: var(--card-shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.una-order-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.una-order-title h1 {
  color: var(--neon-cyan);
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  text-shadow: var(--neon-cyan-glow);
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-order-date {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 14px;
}

.una-order-status-section {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.una-status-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 120px;
  flex: 1;
  max-width: 200px;
}

.una-status-label {
  color: var(--text-secondary);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-bottom: 2px;
}

.una-order-status-badge {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  background: var(--badge-bg);
  color: var(--text-secondary);
  border: 1px solid transparent;
  white-space: nowrap;
}

.una-order-status-badge.una-status-paid {
  background: rgba(0, 255, 157, 0.1);
  color: var(--status-success);
  border-color: rgba(0, 255, 157, 0.3);
}

.una-order-status-badge.una-status-pending {
  background: rgba(255, 165, 0, 0.1);
  color: var(--status-warning);
  border-color: rgba(255, 165, 0, 0.3);
}

.una-order-status-badge.una-status-refunded {
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
  border-color: rgba(255, 0, 255, 0.3);
}

.una-order-status-badge.una-status-unfulfilled {
  background: rgba(255, 0, 85, 0.1);
  color: var(--status-danger);
  border-color: rgba(255, 0, 85, 0.3);
}

.una-order-status-badge.una-status-fulfilled {
  background: rgba(0, 255, 157, 0.1);
  color: var(--status-success);
  border-color: rgba(0, 255, 157, 0.3);
}

.una-order-status-badge.una-status-cancelled {
  background: rgba(255, 0, 85, 0.1);
  color: var(--status-danger);
  border-color: rgba(255, 0, 85, 0.3);
}

/* Tabs Navigation */
.una-order-tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  background: var(--card-bg);
  border-radius: 12px;
  padding: 8px;
  border: 1px solid var(--card-border);
  overflow-x: auto;
  scrollbar-width: thin;
}

.una-tab {
  padding: 12px 18px;
  border-radius: 8px;
  background: transparent;
  border: none;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
}

.una-tab:hover {
  color: var(--text-primary);
  background: rgba(0, 255, 255, 0.05);
}

.una-tab.active {
  background: linear-gradient(to right, rgba(0, 255, 255, 0.1), rgba(0, 255, 157, 0.1));
  color: var(--neon-cyan);
  box-shadow: var(--neon-cyan-glow);
}

.una-tab-panel {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Panel Headers */
.una-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.una-panel-header h2 {
  color: var(--neon-cyan);
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-shadow: var(--neon-cyan-glow);
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Cards for content sections */
.una-items-card, 
.una-order-summary-card,
.una-payment-card,
.una-customer-card,
.una-shipping-card,
.una-notes-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
}

/* Table Headers */
.una-items-table-header {
  display: grid;
  grid-template-columns: 30px 3fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px 15px;
  color: var(--neon-cyan);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 1px solid var(--divider);
}

/* Item Rows */
.una-item {
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid var(--divider);
  transition: background 0.2s ease;
}

.una-item:last-child {
  border-bottom: none;
}

.una-item-content {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  flex: 1;
  align-items: center;
}

.una-item-details h4 {
  margin: 0 0 5px 0;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary);
}

.una-item-meta {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--text-secondary);
}

.una-quantity-control {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-item-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.una-item-quantity, 
.una-item-price,
.una-item-total {
  font-size: 14px;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.una-item-price {
  color: var(--neon-green);
  font-weight: 600;
}

.una-discount {
  color: var(--neon-magenta);
  font-size: 13px;
}

.una-item-total {
  font-weight: 600;
  color: var(--neon-gold);
  text-shadow: var(--neon-gold-glow);
}

/* Summary Grid */
.una-summary-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

/* Summary Rows */
.una-summary-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid var(--divider);
  font-size: 14px;
  color: var(--text-primary);
}

.una-summary-row.discount {
  color: var(--neon-magenta);
}

.una-total-row {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid var(--divider);
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
}

.una-total-row.total {
  color: var(--neon-gold);
  font-size: 18px;
  font-weight: 700;
  text-shadow: var(--neon-gold-glow);
  border-bottom: none;
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid var(--header-border);
}

.una-total-row.paid {
  color: var(--neon-green);
}

.una-total-row.refunded {
  color: var(--neon-magenta);
}

/* Customer Details */
.una-customer-details {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.una-customer-avatar {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: linear-gradient(135deg, rgba(0, 255, 255, 0.1), rgba(0, 255, 157, 0.1));
  border: 1px solid var(--card-border);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: var(--neon-cyan);
  text-shadow: var(--neon-cyan-glow);
  flex-shrink: 0;
}

.una-customer-info h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 600;
}

.una-customer-contact {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.una-customer-email, 
.una-customer-phone {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 14px;
}

/* Customer Stats */
.una-customer-stats {
  display: flex;
  gap: 30px;
  margin: 20px 0;
  padding: 15px 0;
  border-top: 1px solid var(--divider);
}

.una-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.una-stat-value {
  font-size: 22px;
  font-weight: 700;
  color: var(--neon-green);
  text-shadow: var(--neon-green-glow);
  margin-bottom: 5px;
}

.una-stat-label {
  font-size: 12px;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Address Sections */
.una-address-section h3 {
  color: var(--neon-cyan);
  margin: 0 0 15px 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  text-shadow: var(--neon-cyan-glow);
}

.una-address {
  color: var(--text-secondary);
  line-height: 1.5;
}

.una-address p {
  margin: 5px 0;
}

/* Shipping Method */
.una-shipping-method {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid var(--divider);
}

.una-shipping-method:last-child {
  border-bottom: none;
}

.una-shipping-method-name {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 15px;
  margin-bottom: 5px;
}

.una-shipping-carrier, 
.una-shipping-category {
  font-size: 12px;
  color: var(--text-secondary);
}

.una-shipping-price {
  font-weight: 600;
  color: var(--neon-green);
  font-size: 16px;
}

/* Fulfillment */
.una-fulfillment {
  padding: 15px 0;
  border-bottom: 1px solid var(--divider);
}

.una-fulfillment:last-child {
  border-bottom: none;
}

.una-fulfillment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.una-fulfillment-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-fulfillment-date {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: var(--text-secondary);
}

/* Tracking Info */
.una-tracking-info {
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  padding: 12px;
  font-size: 13px;
  color: var(--text-secondary);
  margin-top: 10px;
  border: 1px solid var(--card-border);
}

.una-tracking-link {
  display: inline-block;
  margin-top: 10px;
  color: var(--neon-cyan);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
}

.una-tracking-link:hover {
  text-shadow: var(--neon-cyan-glow);
}

/* Notes */
.una-note {
  padding: 15px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  border: 1px solid var(--card-border);
}

/* Tags */
.una-tags-section h3 {
  color: var(--neon-cyan);
  margin: 20px 0 15px 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  text-shadow: var(--neon-cyan-glow);
}

.una-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.una-tag {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.3);
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
}

/* Refund Button */
.una-refund-button {
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
  border: 1px solid rgba(255, 0, 255, 0.3);
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-refund-button:hover:not(:disabled) {
  background: rgba(255, 0, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.3);
}

.una-refund-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Modal */
.una-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  backdrop-filter: blur(5px);
}

.una-modal {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: var(--card-shadow), 0 0 20px rgba(0, 255, 157, 0.2);
  animation: scaleIn 0.3s ease;
}

@keyframes scaleIn {
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.una-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--divider);
}

.una-modal-header h2 {
  color: var(--neon-cyan);
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-shadow: var(--neon-cyan-glow);
  letter-spacing: 0.5px;
}

.una-modal-close {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.una-modal-close:hover {
  color: var(--neon-cyan);
  background: rgba(0, 255, 255, 0.1);
}

.una-modal-summary {
  padding: 20px;
  border-bottom: 1px solid var(--divider);
}

.una-modal-summary h3 {
  color: var(--neon-magenta);
  margin: 0 0 15px 0;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(255, 0, 255, 0.4);
  letter-spacing: 0.5px;
}

.una-refund-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.una-refund-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--text-secondary);
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 0, 255, 0.1);
}

.una-refund-item:last-child {
  border-bottom: none;
}

.una-refund-total {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: var(--neon-magenta);
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 0, 255, 0.2);
}

.una-form-group {
  padding: 20px;
  border-bottom: 1px solid var(--divider);
}

.una-form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 600;
}

.una-checkbox-group label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.una-checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
}

.una-modal-content {
  max-height: 500px;
  overflow-y: auto;
}

.una-modal-content textarea {
  width: 100%;
  height: 100px;
  background: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 8px;
  padding: 12px;
  color: var(--text-primary);
  font-size: 14px;
  resize: vertical;
  font-family: inherit;
  transition: all 0.3s ease;
}

.una-modal-content textarea:focus {
  outline: none;
  border-color: var(--input-focus-border);
  box-shadow: var(--neon-cyan-glow);
}

.una-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
  border-top: 1px solid var(--divider);
}

.una-modal-actions button {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.una-modal-actions button:first-child {
  background: transparent;
  border: 1px solid var(--text-tertiary);
  color: var(--text-tertiary);
}

.una-modal-actions button:first-child:hover {
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-primary);
  border-color: var(--text-primary);
}

.una-modal-actions button:last-child {
  background: rgba(255, 0, 255, 0.1);
  border: 1px solid rgba(255, 0, 255, 0.3);
  color: var(--neon-magenta);
}

.una-modal-actions button:last-child:hover {
  background: rgba(255, 0, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.3);
}

/* Error Message */
.una-error {
  background: rgba(255, 0, 85, 0.1);
  border: 1px solid rgba(255, 0, 85, 0.3);
  color: var(--status-danger);
  border-radius: 8px;
  padding: 12px 15px;
  margin: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Item Checkbox */
.una-item-checkbox {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.una-item input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 0, 255, 0.4);
  background: rgba(13, 14, 26, 0.7);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.una-item input[type="checkbox"]:checked {
  background: var(--neon-magenta);
  border-color: var(--neon-magenta);
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.3);
}

.una-item input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid rgba(13, 14, 26, 0.9);
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 6px;
  transform: rotate(45deg);
}

/* Number Input */
.una-item input[type="number"] {
  width: 60px;
  background: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 6px;
  padding: 6px 8px;
  color: var(--text-primary);
  font-size: 14px;
  text-align: center;
  -moz-appearance: textfield; /* Firefox */
}

.una-item input[type="number"]::-webkit-outer-spin-button,
.una-item input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.una-item input[type="number"]:focus {
  outline: none;
  border-color: var(--input-focus-border);
  box-shadow: var(--neon-cyan-glow);
}

/* Error and Loading */
.una-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 0, 85, 0.05);
  border: 1px solid rgba(255, 0, 85, 0.2);
  border-radius: 12px;
  gap: 15px;
  margin: 30px auto;
  max-width: 500px;
  text-align: center;
}

.una-error-container svg {
  color: var(--status-danger);
  font-size: 40px;
  margin-bottom: 10px;
}

.una-error-container h2 {
  color: var(--status-danger);
  margin: 0;
  font-size: 20px;
}

.una-error-container p {
  color: var(--text-secondary);
  margin-bottom: 20px;
}

.una-retry-button {
  background: var(--button-bg);
  border: 1px solid var(--button-border);
  color: var(--neon-green);
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.una-retry-button:hover {
  background: var(--button-hover);
  box-shadow: var(--neon-green-glow);
}

/* Cancelled Card */
.una-cancelled-card {
  background: rgba(255, 0, 85, 0.05);
  border: 1px solid rgba(255, 0, 85, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
}

.una-cancelled-card h3 {
  color: var(--status-danger);
  margin: 0 0 15px 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .una-order-grid {
    grid-template-columns: 1fr;
  }
  
  .una-summary-grid {
    grid-template-columns: 1fr;
  }
  
  .una-items-table-header, 
  .una-item-content {
    grid-template-columns: 3fr 1fr 1fr 1fr;
  }
  
  .una-th-discount,
  .una-discount {
    display: none;
  }
}

@media (max-width: 768px) {
  .una-order-details-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .una-order-actions {
    width: 100%;
    justify-content: flex-start;
  }
  
  .una-order-overview {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .una-order-status-section {
    width: 100%;
    justify-content: space-between;
  }
  
  .una-order-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
  }
  
  .una-tab {
    flex: 0 0 auto;
    white-space: nowrap;
  }
  
  .una-items-table-header, 
  .una-item-content {
    grid-template-columns: 2fr 1fr;
  }
  
  .una-th-price,
  .una-th-quantity,
  .una-item-quantity,
  .una-item-price {
    display: none;
  }
  
  .una-item-total {
    justify-content: flex-end;
  }
  
  .una-customer-details {
    flex-direction: column;
  }
  
  .una-customer-stats {
    flex-wrap: wrap;
    gap: 20px;
  }
}

/* Tracking Timeline Styles */
.una-tracking-detail-container {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.una-tracking-detail-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

.una-tracking-info-summary {
  flex: 1;
  margin: 0 15px;
}

.una-tracking-timeline {
  padding: 20px;
}

.una-timeline-current-status {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
  color: #4a4a4a;
  font-size: 1.1em;
}

.una-timeline-current-status svg {
  margin-right: 8px;
  color: #3182ce;
}

.una-timeline-events {
  position: relative;
}

.una-timeline-events::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  width: 2px;
  background-color: #e0e0e0;
}

.una-timeline-event {
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}

.una-timeline-dot {
  position: absolute;
  left: 0;
  top: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #3182ce;
  z-index: 1;
}

.una-timeline-content {
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.una-timeline-date {
  font-size: 0.9em;
  font-weight: 600;
  color: #3182ce;
  margin-bottom: 5px;
}

.una-timeline-description {
  font-size: 0.95em;
  margin-bottom: 5px;
}

.una-timeline-location {
  display: flex;
  align-items: center;
  font-size: 0.85em;
  color: #666;
}

.una-timeline-location svg {
  margin-right: 5px;
  font-size: 0.9em;
}

.una-no-tracking-events {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.una-no-tracking-events svg {
  margin-right: 8px;
  color: #3182ce;
}
