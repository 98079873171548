.manual-carrier-conversation-form {
    display: flex;
    flex-direction: column;
    padding: 25px;
    max-height: 80vh;
    overflow-y: auto;
}

.manual-carrier-conversation-form h2 {
    font-size: 24px;
    color: var(--neon-cyan);
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 25px;
    text-shadow: var(--glow-cyan);
}

.form-group {
    margin-bottom: 20px;
    background: rgba(26, 26, 46, 0.7);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    transition: all 0.3s ease;
}

.form-group:hover {
    background: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
}

.form-group label {
    display: block;
    color: var(--neon-magenta);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 12px;
    font-size: 0.9rem;
    text-shadow: var(--glow-magenta);
    font-weight: 500;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 12px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
}

.form-group input:focus,
.form-group select:focus {
    outline: none;
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    background: rgba(26, 26, 46, 0.9);
}

.param-item {
    margin: 15px 0;
    padding: 15px;
    background: rgba(26, 26, 46, 0.7);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.param-item:hover {
    background: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
}

.param-label {
    display: block;
    color: var(--neon-magenta);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    text-shadow: var(--glow-magenta);
    font-weight: 500;
}

.param-input {
    width: 100%;
    padding: 12px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
}

.param-input:focus {
    outline: none;
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    background: rgba(26, 26, 46, 0.9);
}

.error-message {
    padding: 15px;
    margin: 15px 0;
    background: rgba(255, 0, 85, 0.1);
    border: 1px solid var(--neon-magenta);
    border-radius: 12px;
    color: var(--neon-magenta);
    text-shadow: var(--glow-magenta);
    font-weight: 500;
    letter-spacing: 0.5px;
}

.success-message {
    padding: 15px;
    margin: 15px 0;
    background: rgba(0, 255, 157, 0.1);
    border: 1px solid var(--neon-green);
    border-radius: 12px;
    color: var(--neon-green);
    text-shadow: var(--glow-green);
    font-weight: 500;
    letter-spacing: 0.5px;
}

/* Submit and Select Carrier buttons styling */
.manual-carrier-conversation-form button {
    padding: 12px 24px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 12px;
    color: var(--neon-cyan);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    backdrop-filter: blur(5px);
}

.manual-carrier-conversation-form button:hover:not(:disabled) {
    background: rgba(0, 255, 255, 0.1);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
}

.manual-carrier-conversation-form button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Submit button specific styling */
button[type="submit"] {
    border-color: var(--neon-green);
    color: var(--neon-green);
    text-shadow: var(--glow-green);
}

button[type="submit"]:hover:not(:disabled) {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
}

/* Attached files styling */
.attached-files {
    margin-top: 20px;
    padding: 20px;
    background: rgba(26, 26, 46, 0.7);
    border: 1px solid var(--neon-cyan);
    border-radius: 12px;
    box-shadow: var(--glow-cyan);
}

.attached-files h3 {
    color: var(--neon-cyan);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-shadow: var(--glow-cyan);
}

.attached-files-grid {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 0;
}

.file-indicator {
    padding: 8px 16px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 12px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    backdrop-filter: blur(5px) !important;
    transition: all 0.3s ease !important;
    cursor: pointer;
    position: relative;
}

.file-indicator.pdf {
    border-color: var(--neon-magenta) !important;
}

.file-indicator.pdf svg {
    color: var(--neon-magenta) !important;
}

.file-indicator.image {
    border-color: var(--neon-cyan) !important;
}

.file-indicator.image svg {
    color: var(--neon-cyan) !important;
}

.file-indicator.document {
    border-color: var(--neon-blue) !important;
}

.file-indicator.document svg {
    color: var(--neon-blue) !important;
}

.file-indicator:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.2) !important;
}

.file-tooltip {
    position: absolute !important;
    bottom: calc(100% + 10px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 8px !important;
    padding: 8px 12px !important;
    font-size: 12px !important;
    white-space: nowrap !important;
    opacity: 0 !important;
    visibility: hidden !important;
    transition: all 0.3s ease !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(5px) !important;
    z-index: 1000 !important;
}

.file-indicator:hover .file-tooltip {
    opacity: 1 !important;
    visibility: visible !important;
}

/* File input styling */
input[type="file"] {
    display: block;
    width: 100%;
    padding: 10px;
    background: rgba(26, 26, 46, 0.6);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 6px;
    color: var(--neon-cyan);
    padding: 8px 12px;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

input[type="file"]::-webkit-file-upload-button:hover {
    background: rgba(0, 255, 255, 0.1);
    box-shadow: var(--glow-cyan);
}

/* Chronopost conditional select styling */
select.param-input {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300ffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    padding-right: 40px;
}

/* Scrollbar styling */
.manual-carrier-conversation-form {
    scrollbar-width: thin;
    scrollbar-color: var(--neon-cyan) rgba(26, 26, 46, 0.8);
}

.manual-carrier-conversation-form::-webkit-scrollbar {
    width: 8px;
}

.manual-carrier-conversation-form::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.8);
    border-radius: 4px;
}

.manual-carrier-conversation-form::-webkit-scrollbar-thumb {
    background: var(--neon-cyan);
    border-radius: 4px;
    border: 2px solid rgba(26, 26, 46, 0.8);
}

.manual-carrier-conversation-form::-webkit-scrollbar-thumb:hover {
    background: var(--neon-magenta);
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Form group select styling */
.form-group select,
.param-item select {
    width: 100%;
    padding: 12px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300ffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    padding-right: 40px;
}

.form-group select:focus,
.param-item select:focus {
    outline: none;
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    background-color: rgba(26, 26, 46, 0.9);
}

.form-group select:hover,
.param-item select:hover {
    background-color: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
}

/* Submit button styling */
.manual-carrier-conversation-form form button[type="submit"] {
    width: 100%;
    margin-top: 20px;
    padding: 15px 30px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-green);
    border-radius: 12px;
    color: var(--neon-green);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: var(--glow-green);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.manual-carrier-conversation-form form button[type="submit"]:hover:not(:disabled) {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
    transform: translateY(-2px);
}

.manual-carrier-conversation-form form button[type="submit"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Option styling within selects */
.form-group select option,
.param-item select option {
    background: rgba(26, 26, 46, 0.95);
    color: var(--text-color);
    padding: 12px;
}

/* Carrier select styling */
.carrier-select {
    width: 100%;
    padding: 12px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300ffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    padding-right: 40px;
}

.carrier-select:hover {
    background-color: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
}

.carrier-select:focus {
    outline: none;
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    background-color: rgba(26, 26, 46, 0.9);
}

/* Submit button styling */
.submit-button {
    width: 100%;
    margin-top: 20px;
    padding: 15px 30px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-green);
    border-radius: 12px;
    color: var(--neon-green);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: var(--glow-green);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.submit-button:hover:not(:disabled) {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
    transform: translateY(-2px);
}

.submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}
