/* src/components/AIAssistantManagement.css */

:root {
  --primary-color: #00ff9d;
  --primary-light: #00ffff;
  --primary-dark: #1a1a2e;
  --secondary-color: #ff00ff;
  --secondary-light: #ff60ff;
  --secondary-dark: #b100b1;
  --background-color: #1a1a2e;
  --text-color: #ffffff;
  --border-color: rgba(0, 255, 157, 0.2);
  
  /* Gradients & Effects */
  --gradient-bg: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  --gradient-card: linear-gradient(180deg, rgba(26, 26, 46, 0.9) 0%, rgba(22, 33, 62, 0.9) 100%);
  --glow-green: 0 0 20px rgba(0, 255, 157, 0.3);
  --glow-cyan: 0 0 20px rgba(0, 255, 255, 0.3);
  --glow-magenta: 0 0 20px rgba(255, 0, 255, 0.3);
}

.ai-assistant-management {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: var(--gradient-bg);
  min-height: 100vh;
}

.assistants-list {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 20px;
  box-shadow: var(--glow-green);
  border: 1px solid var(--border-color);
  margin-bottom: 40px;
}

.assistants-list h3 {
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-green);
}

.assistants-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.assistant-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 15px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  backdrop-filter: blur(5px);
}

.assistant-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--glow-cyan);
  border-color: var(--primary-light);
}

.assistant-item.selected {
  background: rgba(0, 255, 157, 0.1);
  border-left: 4px solid var(--primary-color);
  box-shadow: var(--glow-green);
}

.assistant-name {
  font-weight: 500;
  color: var(--text-color);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.assistant-tools {
  font-size: 14px;
  color: var(--primary-light);
  opacity: 0.8;
}

.assistant-actions {
  display: flex;
  gap: 15px;
}

.icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: var(--text-color);
  transition: all 0.3s ease;
  padding: 8px;
  border-radius: 8px;
}

.icon-button:hover {
  color: var(--primary-color);
  text-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.set-running-button:hover {
  color: var(--secondary-color);
  text-shadow: var(--glow-magenta);
}

.delete-button:hover {
  color: #ff0055;
  text-shadow: 0 0 20px rgba(255, 0, 85, 0.4);
}

.running-status {
  color: var(--secondary-color);
  font-weight: 500;
  text-shadow: var(--glow-magenta);
  display: flex;
  align-items: center;
  gap: 8px;
}

.running-status::before {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--secondary-color);
  border-radius: 50%;
  box-shadow: var(--glow-magenta);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.left-form,
.right-tools {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  box-shadow: var(--glow-green);
}

.left-form h3,
.right-tools h3 {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: var(--glow-green);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--primary-light);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  text-shadow: var(--glow-cyan);
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  font-size: 14px;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

.prompt-preview {
  padding: 15px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--text-color);
  backdrop-filter: blur(5px);
}

.prompt-preview:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 25px;
}

.primary-button,
.secondary-button {
  padding: 12px 24px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.primary-button {
  background: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
  text-shadow: var(--glow-green);
}

.primary-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.secondary-button {
  background: transparent;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  text-shadow: var(--glow-magenta);
}

.secondary-button:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

.tabs-container {
  margin-top: 40px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
  border-radius: 12px 12px 0 0;
  padding: 5px;
}

.tab-button {
  padding: 12px 24px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.tab-button:hover {
  color: var(--primary-color);
  text-shadow: var(--glow-green);
}

.tab-button.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
  text-shadow: var(--glow-green);
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
  box-shadow: var(--glow-green);
}

.modalai {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(13, 14, 26, 0.95);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
  will-change: opacity;
}

.modal-content {
  background: var(--gradient-card);
  padding: 30px;
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.2);
  animation: modalAppear 0.3s ease;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-green);
}

.modal-content .form-group {
  margin-bottom: 20px;
  width: 100%;
}

.modal-content textarea {
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  resize: vertical;
  padding: 15px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  color: var(--text-color);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s ease;
}

.modal-content textarea:focus {
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

.modal-content .button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  gap: 15px;
  margin-top: 25px;
}

.modal-content .button-group button {
  padding: 12px 24px !important;
  border: 1px solid transparent !important;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 120px;
}

.modal-content .primary-button {
  background: transparent;
  border-color: var(--primary-color) !important;
  color: var(--primary-color);
  text-shadow: var(--glow-green);
}

.modal-content .primary-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.modal-content .secondary-button {
  background: transparent;
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color);
  text-shadow: var(--glow-magenta);
}

.modal-content .secondary-button:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

.modal-content .cancel-button {
  background: transparent;
  border-color: #ff0055 !important;
  color: #ff0055;
  text-shadow: 0 0 20px rgba(255, 0, 85, 0.4);
}

.modal-content .cancel-button:hover {
  background: rgba(255, 0, 85, 0.1);
  box-shadow: 0 0 20px rgba(255, 0, 85, 0.4);
  transform: translateY(-2px);
}

.modal-content .generate-button {
  background: transparent;
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color);
  text-shadow: var(--glow-magenta);
}

.modal-content .generate-button:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

.generate-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate-button svg {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .form-container {
    grid-template-columns: 1fr;
  }
}

.tools-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 15px;
  background: rgba(26, 26, 46, 0.7);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(5px);
}

.checkbox-group {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.checkbox-group:hover {
  background: rgba(0, 255, 157, 0.05);
}

.checkbox-group input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.checkbox-group input[type="checkbox"]:checked {
  background: var(--primary-color);
  box-shadow: var(--glow-green);
}

.checkbox-group input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  color: var(--background-color);
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-group label {
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.checkbox-group:hover label {
  color: var(--primary-color);
  text-shadow: var(--glow-green);
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.automation-toggle {
  display: flex;
  align-items: center;
}

.toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button.active {
  background-color: #4a0066;
  color: white;
}

.toggle-button svg {
  font-size: 1.2em;
}

.toggle-button:hover {
  background-color: #6200ea;
  color: white;
}

.ai-assistant-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.panels-wrapper {
  display: flex;
  width: 100%;
}

.left-panel {
  width: 400px;
  padding: 25px;
}

.right-panel {
  flex: 1;
  padding: 20px;
}

.chatbot-test-container {
  width: 100%;
  margin-top: 20px;
}

.agent-selector {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 20px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.agent-selector:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.agent-circle {
  width: 65px;
  height: 65px;
  background: linear-gradient(135deg, var(--primary-color), var(--primary-light));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
  font-size: 32px;
  box-shadow: var(--glow-green);
  border: 2px solid rgba(0, 255, 157, 0.3);
}

.agent-circle.small {
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.agent-info {
  flex: 1;
}

.agent-name {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
}

.running-badge {
  font-size: 12px;
  color: var(--secondary-color);
  text-shadow: var(--glow-magenta);
  display: flex;
  align-items: center;
  gap: 5px;
}

.running-badge::before {
  content: '';
  width: 6px;
  height: 6px;
  background: var(--secondary-color);
  border-radius: 50%;
  box-shadow: var(--glow-magenta);
  animation: pulse 2s infinite;
}

.tabs-section {
  height: 100%;
}

.agent-selector-modal,
.create-agent-modal {
  max-width: 500px;
}

.agent-list {
  max-height: 300px;
  overflow-y: auto;
  margin: 20px 0;
}

.agent-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  transition: all 0.3s ease;
}

.agent-item-content {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  flex: 1;
}

.agent-item-info {
  display: flex;
  flex-direction: column;
}

.agent-item-name {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
}

.agent-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
}

.agent-item:hover .agent-item-name {
  color: var(--primary-color);
  text-shadow: var(--glow-green);
  transform: translateX(5px);
}

.agent-item.running .agent-item-name {
  color: var(--secondary-color);
  text-shadow: var(--glow-magenta);
}

.running-badge {
  font-size: 12px;
  color: var(--secondary-color);
  margin-left: auto;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
}

.tool-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  min-height: 60px;
  height: auto;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.tool-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
}

.tool-icon {
  color: var(--primary-color);
  font-size: 24px;
  filter: drop-shadow(var(--glow-green));
  flex-shrink: 0;
}

.tool-item span {
  flex: 1;
  font-size: 15px;
  line-height: 1.4;
  word-wrap: break-word;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.agent-details {
  margin-top: 30px;
  padding: 25px;
}

.agent-details h3 {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: var(--glow-green);
}

.agent-details h4 {
  font-size: 16px;
  color: var(--primary-light);
  margin: 15px 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-cyan);
}

.tools-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tool-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 16px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  font-size: 15px;
  color: var(--text-color);
  transition: all 0.3s ease;
  min-height: 50px;
  height: auto;
}

.tool-badge:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  transform: translateX(5px);
}

.ai-agent-modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(13, 14, 26, 0.8);
  backdrop-filter: blur(10px);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
}

.ai-agent-modal-content {
  background: var(--gradient-card);
  padding: 30px;
  border-radius: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  box-shadow: var(--glow-green);
  animation: modalAppear 0.3s ease;
}

.ai-agent-modal-content .form-group {
  margin-bottom: 20px;
}

.ai-agent-modal-content textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  color: var(--text-color);
  padding: 12px;
  transition: all 0.3s ease;
}

.ai-agent-modal-content textarea:focus {
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

.ai-agent-tools-section {
  margin: 20px 0;
  padding: 15px;
}

.tools-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.add-tools-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-tools-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
}

.active-tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.tools-overlay {
  z-index: 1100;
}

.tools-overlay .modal-content {
  max-width: 800px;
}

.tools-overlay-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin: 20px 0;
  max-height: 60vh;
  overflow-y: auto;
}

@media (max-width: 1400px) {
  .left-panel {
    width: 350px;
  }
}

@media (max-width: 1200px) {
  .left-panel {
    width: 320px;
  }
  
  .tools-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1024px) {
  .panels-container {
    flex-direction: row;
  }
  
  .left-panel {
    width: 320px;
  }
  
  .agent-circle {
    width: 60px;
    height: 60px;
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .assistant-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .assistant-actions {
    width: 100%;
    justify-content: flex-end;
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .button-group button {
    width: 100%;
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(26, 26, 46, 0.7);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-light);
  box-shadow: var(--glow-green);
}

.ai-agent-input {
  width: 100%;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  font-size: 14px;
  transition: all 0.3s ease;
}

.ai-agent-input:focus {
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

.ai-agent-textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  font-size: 14px;
  transition: all 0.3s ease;
  resize: vertical;
}

.ai-agent-textarea:focus {
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

.agent-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
}

.ai-agent-value {
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  min-height: 42px;
}

.prompt-value {
  min-height: 100px;
  white-space: pre-wrap;
}

.tool-status {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-check {
  color: var(--primary-color);
  filter: drop-shadow(var(--glow-green));
}

.ai-agent-tool-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.ai-agent-tool-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
}

.una-order-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-loading-content {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

.una-loading-icons {
  position: relative;
  height: 120px;
  margin-bottom: 30px;
}

.una-loading-icons svg {
  position: absolute;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 30px;
  color: var(--neon-cyan);
  animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green);
  animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta);
  animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.una-loading-progress {
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  border-radius: 2px;
  animation: progress 3s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 255, 255, 0.4), transparent);
  animation: glow 2s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }

@keyframes floatIcon {
  0%, 100% { 
    transform: translateY(0) scale(1); 
    opacity: 1;
  }
  50% { 
    transform: translateY(-10px) scale(1.1); 
    opacity: 0.8;
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

.clickable {
  cursor: pointer;
}

.prompt-modal {
  max-width: 800px;
  width: 90%;
}

.prompt-preview-content {
  background: rgba(26, 26, 46, 0.95);
  padding: 15px;
  border-radius: 12px;
  max-height: 60vh;
  overflow-y: auto;
  white-space: pre-wrap;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

.prompt-value {
  padding: 12px;
  background: rgba(26, 26, 46, 0.95);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  min-height: 42px;
  transition: transform 0.3s ease;
  transform: translateZ(0);
}

.prompt-value:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.2);
  transform: translateY(-2px) translateZ(0);
}

.tools-overlay .ai-agent-tool-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.tools-overlay .ai-agent-tool-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
}

.tools-overlay .ai-agent-tool-item label {
  color: var(--text-color);
  cursor: pointer;
  flex: 1;
}

.tools-overlay .ai-agent-tool-item input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  background: transparent;
}

.tools-overlay .ai-agent-tool-item input[type="checkbox"]:checked {
  background: var(--primary-color);
  box-shadow: var(--glow-green);
}

.tools-overlay .ai-agent-tool-item input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  color: var(--background-color);
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tools-overlay .ai-agent-tool-item:hover input[type="checkbox"] {
  border-color: var(--primary-light);
}

.active-tools-grid .ai-agent-tool-item label {
  color: var(--text-color);
}
