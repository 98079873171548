.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.status-filter label,
.company-filter label {
  margin-right: 10px;
  color: var(--neon-cyan);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.status-filter select,
.company-filter input {
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s ease;
  min-width: 180px;
}

.status-filter select:focus,
.company-filter input:focus {
  border-color: var(--neon-green);
  box-shadow: 0 0 0 2px rgba(0, 255, 157, 0.2);
  outline: none;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
}

.pagination button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.pagination button:hover:not(:disabled) {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-1px);
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  color: var(--neon-cyan);
  padding: 8px 12px;
  border-radius: 8px;
  font-weight: 500;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 16px;
  font-size: 14px;
}

thead {
  background: rgba(13, 14, 26, 0.7);
  position: sticky;
  top: 0;
  z-index: 10;
}

th {
  text-align: left;
  padding: 16px;
  color: var(--neon-cyan);
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(0, 255, 255, 0.2);
  white-space: nowrap;
  font-size: 13px;
  text-transform: uppercase;
}

td {
  padding: 16px;
  background: rgba(13, 14, 26, 0.5);
  color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 255, 157, 0.05);
  text-align: left;
  vertical-align: middle;
  transition: background 0.2s ease;
}

/* Primary information - Subject */
td.primary-info {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0.2px;
}

/* Secondary information - Company, Carrier */
td.secondary-info {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

/* Tertiary information - Channel, Updated */
td.tertiary-info {
  font-weight: 400;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}

/* Visual indicators */
.visual-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

/* Channel styling */
.channel-icon-dashboard {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
  opacity: 0.85;
}

td:first-child .visual-indicator {
  display: flex;
  align-items: center;
}

/* Improved sentiment score */
.sentiment-score {
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  min-width: 60px;
  letter-spacing: 0.5px;
  font-size: 13px;
}

.sentiment-score.low {
  background: rgba(255, 0, 0, 0.1);
  color: #ff5b5b;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.sentiment-score.medium {
  background: rgba(255, 255, 0, 0.1);
  color: #ffdd5b;
  border: 1px solid rgba(255, 255, 0, 0.2);
}

.sentiment-score.high {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

/* Improved status badge */
.status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.7px;
  display: inline-block;
  text-align: center;
  min-width: 110px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Updated date styling */
.date-info {
  white-space: nowrap;
  font-size: 12px;
  letter-spacing: 0.3px;
}

/* Row hover effect */
tr:hover td {
  background: rgba(0, 255, 157, 0.05);
  cursor: pointer;
}

/* Row selected effect - can be added with JavaScript */
tr.selected td {
  background: rgba(0, 255, 255, 0.07);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.status-badge.compensation,
.status-badge.compensation_invoice_made,
.status-badge.compensation_in_progress,
.status-badge.to_compensate {
  background-color: rgba(156, 39, 176, 0.2);
  color: #e490ff;
  border: 1px solid rgba(156, 39, 176, 0.2);
}

.status-badge.waiting_carrier_commercial_response {
  background-color: rgba(117, 96, 139, 0.2);
  color: #b9a1d9;
  border: 1px solid rgba(117, 96, 139, 0.2);
}

.status-badge.return_pending,
.status-badge.pending {
  background-color: rgba(96, 125, 139, 0.2);
  color: #a1c0cf;
  border: 1px solid rgba(96, 125, 139, 0.2);
}

.status-badge.processing {
  background-color: rgba(33, 150, 243, 0.2);
  color: #67b3ff;
  border: 1px solid rgba(33, 150, 243, 0.2);
}

.status-badge.automated {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.status-badge.waiting_carrier_response {
  background: rgba(255, 165, 0, 0.1);
  color: #ffc15b;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

.status-badge.npec_yourstore {
  background: rgba(255, 0, 225, 0.1);
  color: #ff5beb;
  border: 1px solid rgba(255, 0, 225, 0.2);
}

.status-badge.new {
  background: rgba(147, 112, 219, 0.1);
  color: #b997eb;
  border: 1px solid rgba(147, 112, 219, 0.2);
}

.status-badge.affected {
  background: rgba(255, 215, 0, 0.1);
  color: #ffe15b;
  border: 1px solid rgba(255, 215, 0, 0.2);
}

.status-badge.waiting_client_response {
  background: rgba(216, 93, 247, 0.1);
  color: #e587ff;
  border: 1px solid rgba(216, 93, 247, 0.2);
}

.assigned-icon {
  font-size: 18px;
}

.assigned-icon.human {
  color: #FFD700;
}

.assigned-icon.robot {
  color: var(--neon-cyan);
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1200px) {
  .table-controls {
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .filters-container {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  table {
    display: block;
    overflow-x: auto;
  }
  
  .truncate {
    max-width: 150px;
  }
}

@media (max-width: 768px) {
  .table-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .status-filter,
  .company-filter {
    width: 100%;
    margin-bottom: 12px;
  }
  
  .status-filter select,
  .company-filter input {
    width: 100%;
  }
  
  .pagination {
    margin-top: 16px;
    width: 100%;
    justify-content: space-between;
  }
  
  th, td {
    padding: 12px 10px;
    font-size: 14px;
  }
  
  .truncate {
    max-width: 120px;
  }
}