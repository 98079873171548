/* Dashboard.css - Professional Cyberpunk UI */
.una-dashboard-container {
  padding: 24px;
  background-color: rgba(13, 14, 26, 0.7);
  min-height: 100vh;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Header Stats Section */
.una-dashboard-header {
  margin-bottom: 28px;
}

.una-dashboard-stats {
  width: 100%;
}

.una-stats-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 255, 157, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.una-stats-card:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
}

.una-stats-card-content {
  padding: 20px;
}

.una-stats-section {
  margin-bottom: 16px;
}

.una-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

/* Metric Items */
.una-metric-item {
  padding: 16px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 14px;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.una-metric-icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.una-metric-details {
  display: flex;
  flex-direction: column;
}

.una-metric-value {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.una-metric-label {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  letter-spacing: 0.3px;
}

/* Primary Metrics */
.una-metric-item.primary {
  background: linear-gradient(135deg, rgba(0, 255, 157, 0.1) 0%, rgba(13, 14, 26, 0.7) 100%);
  border: 1px solid rgba(0, 255, 157, 0.15);
}

.una-metric-icon.primary {
  background: rgba(0, 255, 157, 0.15);
  color: var(--neon-green, #00ff9d);
  box-shadow: 0 0 12px rgba(0, 255, 157, 0.2);
}

.una-metric-value.primary {
  color: var(--neon-green, #00ff9d);
  text-shadow: 0 0 8px rgba(0, 255, 157, 0.3);
}

/* Secondary Metrics */
.una-metric-item.secondary {
  background: linear-gradient(135deg, rgba(0, 255, 255, 0.1) 0%, rgba(13, 14, 26, 0.7) 100%);
  border: 1px solid rgba(0, 255, 255, 0.15);
}

.una-metric-icon.secondary {
  background: rgba(0, 255, 255, 0.15);
  color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 12px rgba(0, 255, 255, 0.2);
}

.una-metric-value.secondary {
  color: var(--neon-cyan, #00ffff);
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.3);
}

/* Tertiary Metrics */
.una-metric-item.tertiary {
  background: linear-gradient(135deg, rgba(255, 0, 255, 0.1) 0%, rgba(13, 14, 26, 0.7) 100%);
  border: 1px solid rgba(255, 0, 255, 0.15);
}

.una-metric-icon.tertiary {
  background: rgba(255, 0, 255, 0.15);
  color: var(--neon-magenta, #ff00ff);
  box-shadow: 0 0 12px rgba(255, 0, 255, 0.2);
}

.una-metric-value.tertiary {
  color: var(--neon-magenta, #ff00ff);
  text-shadow: 0 0 8px rgba(255, 0, 255, 0.3);
}

/* Hover Effects */
.una-metric-item.primary:hover {
  box-shadow: 0 0 12px rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
}

.una-metric-item.secondary:hover {
  box-shadow: 0 0 12px rgba(0, 255, 255, 0.2);
  transform: translateY(-2px);
}

.una-metric-item.tertiary:hover {
  box-shadow: 0 0 12px rgba(255, 0, 255, 0.2);
  transform: translateY(-2px);
}

/* Main Dashboard Section */
.una-dashboard-main {
  margin-bottom: 28px;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Ensure all three sections have equal space */
.una-dashboard-grid {
  display: grid;
  grid-template-columns: 1fr; /* Single column for tickets */
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;
}

/* Activity Section - Ensure equal space for orders and returns */
.una-activity-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Equal space for orders and returns */
  gap: 24px;
  width: 100%;
}

/* Cards Design */
.una-dashboard-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0; /* Prevent overflow */
}

.una-dashboard-card:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 255, 157, 0.15);
}

.una-card-header {
  background-color: rgba(13, 14, 26, 0.8);
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.una-card-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 12px;
}

.una-card-header svg {
  font-size: 18px;
  margin-right: 10px;
}

.una-card-header .tickets-icon {
  color: var(--neon-green, #00ff9d);
}

.una-card-header .orders-icon {
  color: var(--neon-cyan, #00ffff);
}

.una-card-header .returns-icon {
  color: var(--neon-magenta, #ff00ff);
}

.una-view-all {
  background: transparent;
  border: 1px solid rgba(0, 255, 157, 0.3);
  color: var(--neon-green, #00ff9d);
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.una-view-all:hover {
  background: rgba(0, 255, 157, 0.1);
  transform: translateY(-1px);
}

.una-card-content {
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  max-height: 400px;
}

/* List Items */
.una-list-item {
  padding: 14px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden; /* Prevent content overflow */
}

.una-list-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
  cursor: pointer;
}

/* Ticket Info */
.una-ticket-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 80%;
  min-width: 0; /* Prevent flex item overflow */
}

.una-ticket-subject {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%; /* Use percentage instead of fixed width */
  letter-spacing: 0.3px;
}

/* Order Info */
.una-order-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 70%;
  min-width: 0; /* Prevent flex item overflow */
}

.una-order-main {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.una-order-number {
  font-weight: 600;
  font-size: 14px;
  color: var(--neon-cyan, #00ffff);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.3px;
}

.una-order-customer {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.2px;
}

.una-order-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
}

.una-order-price {
  font-weight: 600;
  font-size: 14px;
  color: var(--neon-green, #00ff9d);
  letter-spacing: 0.3px;
}

/* Status Badges */
.una-status-badge-dashboard {
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.7px;
  display: inline-block;
  text-align: center;
  min-width: 90px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin-left: 45%;
}

.una-status-badge-dashboard.automated {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green, #00ff9d);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.una-status-badge-dashboard.partially_paid {
  background: rgba(255, 213, 0, 0.1);
  color: #ffd500;
  border: 1px solid rgba(255, 213, 0, 0.2);
}

.una-status-badge-dashboard.paid {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green, #00ff9d);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.una-status-badge-dashboard.pending {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

.una-status-badge-dashboard.refunded {
  background: rgba(255, 0, 0, 0.1);
  color: #ff5b5b;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.una-status-badge-dashboard.label-created {
  background: rgba(147, 112, 219, 0.1);
  color: #b997eb;
  border: 1px solid rgba(147, 112, 219, 0.2);
}

.una-status-badge-dashboard.completed {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green, #00ff9d);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.una-status-badge-dashboard.cancelled {
  background: rgba(255, 0, 0, 0.1);
  color: #ff5b5b;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.una-status-badge-dashboard.processing {
  background: rgba(33, 150, 243, 0.1);
  color: #67b3ff;
  border: 1px solid rgba(33, 150, 243, 0.2);
}

.una-status-badge-dashboard.shipped {
  background: rgba(138, 43, 226, 0.1);
  color: #b76bff;
  border: 1px solid rgba(138, 43, 226, 0.2);
}

/* Return/activity items styling */
.una-activity-item {
  padding: 14px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.una-activity-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
  cursor: pointer;
}

.una-activity-item-main {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 70%;
  min-width: 0;
}

.una-activity-item-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
}

.una-activity-item-title {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.3px;
}

.una-activity-item-subtitle {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.2px;
}

.una-activity-item-side {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
}

.una-activity-item-price {
  font-weight: 600;
  font-size: 14px;
  color: var(--neon-magenta, #ff00ff);
  letter-spacing: 0.3px;
}

/* Attention Products Section */
.una-attention-section {
  margin-top: 24px;
  width: 100%;
}

.una-product-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 70%;
  min-width: 0;
}

.una-product-title {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  letter-spacing: 0.3px;
}

.una-product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.una-reason-tag {
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.una-reason-tag.out_of_stock {
  background: rgba(255, 0, 0, 0.1);
  color: #ff5b5b;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.una-reason-tag.low_stock {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

.una-reason-tag.low_sales_velocity {
  background: rgba(121, 80, 242, 0.1);
  color: #9d75ff;
  border: 1px solid rgba(121, 80, 242, 0.2);
}

.una-product-metrics {
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-metric {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  color: var(--neon-cyan, #00ffff);
}

.una-metric svg {
  font-size: 14px;
}

/* Scrollbars */
.una-card-content::-webkit-scrollbar {
  width: 6px;
}

.una-card-content::-webkit-scrollbar-track {
  background: rgba(13, 14, 26, 0.5);
}

.una-card-content::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 157, 0.3);
  border-radius: 6px;
}

/* Loading Screen */
.una-dashboard-loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: rgba(13, 14, 26, 0.7);
}

.una-loading-content {
  text-align: center;
  max-width: 600px;
  padding: 40px;
}

.una-loading-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 24px;
}

.una-loading-icons svg {
  font-size: 36px;
  opacity: 0.8;
  animation: floatIcon 3s ease-in-out infinite;
}

.una-loading-icons .icon-primary {
  color: var(--neon-green, #00ff9d);
  animation-delay: 0s;
}

.una-loading-icons .icon-secondary {
  color: var(--neon-cyan, #00ffff);
  animation-delay: 0.3s;
}

.una-loading-icons .icon-tertiary {
  color: var(--neon-magenta, #ff00ff);
  animation-delay: 0.6s;
}

.una-loading-content h2 {
  font-size: 24px;
  margin-bottom: 32px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
}

.una-loading-progress {
  width: 100%;
  margin-bottom: 32px;
  height: 6px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.una-progress-bar {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, var(--neon-cyan, #00ffff), var(--neon-green, #00ff9d));
  border-radius: 3px;
  animation: progress 2s infinite;
  position: relative;
  overflow: hidden;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  animation: glow 2s infinite;
}

.una-loading-metrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.una-loading-metrics .una-metric-item {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  min-width: 120px;
}

.una-loading-metrics .una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-loading-metrics .una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-loading-metrics .una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-loading-metrics .una-metric-item:nth-child(4) { animation-delay: 0.8s; }

/* Animations */
@keyframes progress {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes glow {
  0% { left: -50%; }
  100% { left: 100%; }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floatIcon {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Responsive Design */
@media (max-width: 1400px) {
  .una-stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .una-activity-section {
    grid-template-columns: 1fr;
  }
  
  .una-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .una-dashboard-container {
    padding: 16px;
  }
  
  .una-stats-grid {
    grid-template-columns: 1fr;
  }
  
  .una-card-header {
    padding: 12px 16px;
  }
  
  .una-card-content {
    padding: 12px;
  }
  
  .una-loading-icons svg {
    font-size: 28px;
  }
  
  .una-loading-content h2 {
    font-size: 20px;
  }
}

/* New Compact Dashboard for non-edn_api_account users */
.una-compact-dashboard {
  width: 100%;
  margin-top: 28px;
}

.una-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

/* Compact item styling */
.una-compact-item {
  padding: 12px;
  background: rgba(13, 14, 26, 0.5);
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.una-compact-item:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: rgba(0, 255, 157, 0.15);
  transform: translateY(-2px);
  cursor: pointer;
}

.una-compact-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 70%;
  min-width: 0;
}

.una-primary-text {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.3px;
}

.una-secondary-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.2px;
}

.una-compact-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  min-width: fit-content;
}

.una-price-tag {
  font-weight: 600;
  font-size: 13px;
  color: var(--neon-green, #00ff9d);
  letter-spacing: 0.3px;
}

.una-compact-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 2px;
}

.una-more-tag {
  padding: 2px 6px;
  border-radius: 6px;
  font-size: 9px;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}

.una-metric-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  color: var(--neon-cyan, #00ffff);
  padding: 3px 6px;
  border-radius: 6px;
  background: rgba(0, 255, 255, 0.1);
}

.una-metric-tag svg {
  font-size: 11px;
}

.una-chart-content {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

/* Card header icon colors */
.una-card-header .attention-icon {
  color: #ffa500; /* orange for attention */
}

.una-card-header .tags-icon {
  color: #b76bff; /* purple for tags */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .una-dashboard-grid {
    grid-template-columns: 1fr;
  }
}

/* Make sure the card heights are consistent */
.una-dashboard-card {
  min-height: 300px;
  max-height: 300px;
}

.una-card-content {
  max-height: 230px;
}

/* Change the status badges to be more compact */
..una-status-badge-dashboard {
  padding: 2px 6px;
  min-width: 60px;
  font-size: 9px;
  white-space: nowrap;
  position: static;
  display: inline-block;
}

/* Make the reason tags more compact */
.una-reason-tag {
  padding: 2px 6px;
  font-size: 9px;
}

/* New Business Dashboard for non-edn_api_account users */
.una-business-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.una-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
}

/* Optimize card heights for no-scroll view */
.una-dashboard-card {
  min-height: 260px;
  max-height: 260px;
}

.una-card-content {
  max-height: 200px;
}

/* Tickets section styling */
.una-tickets-section {
  margin-top: 10px;
}

.una-section-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  padding-left: 8px;
}

.una-section-header h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #fff;
  letter-spacing: 0.5px;
}

.una-section-header .tickets-icon {
  color: var(--neon-green, #00ff9d);
  font-size: 20px;
}

/* Responsive adjustments for the business dashboard */
@media (min-width: 1600px) {
  .una-dashboard-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) and (min-width: 769px) {
  .una-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .una-dashboard-grid {
    grid-template-columns: 1fr;
  }
  
  /* Allow scrolling on smaller devices */
  .una-dashboard-card {
    min-height: 220px;
    max-height: none;
  }
  
  .una-card-content {
    max-height: 260px;
  }
}

/* Make compact items more space-efficient */
.una-compact-item {
  position: relative;
  padding: 8px 10px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Ensure TicketTableDashboard shows a reasonable number of rows when in compact mode */
.una-business-dashboard .una-ticket-table {
  max-height: 300px;
  overflow-y: auto;
}

/* Store overview section for non-edn users */
.una-store-overview {
  margin-bottom: 24px;
}

/* Stats Grid from ShopStatistics */
.una-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
}

.una-stat-card {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.una-stat-card svg {
  font-size: 24px;
  opacity: 0.9;
}

.una-stat-content {
  display: flex;
  flex-direction: column;
}

.una-stat-content h3 {
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.5px;
}

.una-stat-content p {
  margin: 0 0 5px 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.una-stat-content span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

/* Card color variations */
.una-stat-card.revenue {
  border-left: 3px solid var(--neon-green, #00ff9d);
}

.una-stat-card.revenue svg {
  color: var(--neon-green, #00ff9d);
}

.una-stat-card.orders {
  border-left: 3px solid var(--neon-cyan, #00ffff);
}

.una-stat-card.orders svg {
  color: var(--neon-cyan, #00ffff);
}

.una-stat-card.customers {
  border-left: 3px solid var(--neon-magenta, #ff00ff);
}

.una-stat-card.customers svg {
  color: var(--neon-magenta, #ff00ff);
}

.una-stat-card.products {
  border-left: 3px solid #ffd500;
}

.una-stat-card.products svg {
  color: #ffd500;
}

/* Hover effects */
.una-stat-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
}

/* Alerts Section */
.una-alerts-section {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
}

.una-alert {
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  font-weight: 600;
}

.una-alert svg {
  font-size: 14px;
}

.una-alert.out-of-stock {
  background: rgba(255, 0, 0, 0.1);
  color: #ff5b5b;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.una-alert.low-stock {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

/* Bestseller list styling */
.una-bestseller-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.una-rank {
  font-weight: 700;
  display: inline-block;
  margin-right: 8px;
  color: var(--neon-cyan, #00ffff);
}

.una-card-header .bestsellers-icon {
  color: #ffd500; /* gold for bestsellers */
}

/* Adjust spacing for the business dashboard */
.una-business-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Optimize sizes for a no-scroll experience */
.una-dashboard-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
}

.una-dashboard-card {
  min-height: 240px;
  max-height: 240px;
}

.una-card-content {
  max-height: 180px;
}

/* Responsive adjustments for the new elements */
@media (max-width: 1200px) {
  .una-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .una-stats-grid {
    grid-template-columns: 1fr;
  }
  
  .una-stat-card {
    padding: 15px;
  }
  
  .una-stat-content p {
    font-size: 20px;
  }
}

/* Optimized full-content cards to show exactly 5 items without scrolling */
.una-dashboard-card.una-full-content {
  min-height: auto;
  max-height: none;
}

.una-full-content .una-card-content {
  max-height: none;
  padding: 8px 16px;
}

/* More compact item design */
.una-compact-item {
  padding: 8px 10px;
  margin-bottom: 4px;
  min-height: 0;
}

/* Smaller header for cards */
.una-card-header {
  padding: 10px 16px;
}

.una-card-header h3 {
  font-size: 14px;
}

/* Smaller status badges */
..una-status-badge-dashboard {
  padding: 2px 6px;
  min-width: 60px;
  font-size: 9px;
}

/* Smaller reason tags */
.una-reason-tag {
  padding: 1px 5px;
  font-size: 8px;
}

.una-more-tag {
  padding: 1px 4px;
  font-size: 8px;
}

/* Smaller text elements */
.una-primary-text {
  font-size: 12px;
}

.una-secondary-text {
  font-size: 11px;
}

.una-price-tag {
  font-size: 12px;
}

.una-metric-tag {
  font-size: 11px;
  padding: 2px 5px;
}

.una-metric-tag svg {
  font-size: 10px;
}

/* Optimize grid layout to be more space-efficient */
.una-dashboard-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
}

/* Adjust stats cards to be more compact */
.una-stat-card {
  padding: 15px;
}

.una-stat-content h3 {
  font-size: 13px;
  margin-bottom: 6px;
}

.una-stat-content p {
  font-size: 20px;
  margin-bottom: 3px;
}

.una-stat-content span {
  font-size: 11px;
}

/* Make alerts more compact */
.una-alert {
  padding: 6px 10px;
  font-size: 12px;
}

.una-alert svg {
  font-size: 12px;
}

/* Ensure spacing is optimized */
.una-store-overview {
  margin-bottom: 16px;
}

.una-stats-grid {
  gap: 12px;
  margin-bottom: 12px;
}

.una-business-dashboard {
  gap: 12px;
}

.una-section-header {
  margin-bottom: 12px;
}

/* Adjust space between cards and ticket section */
.una-tickets-section {
  margin-top: 16px;
}

/* Responsive adjustments to ensure no-scroll design on different screens */
@media (min-width: 1600px) {
  .una-dashboard-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Unconfigured Shop Welcome Section */
.una-unconfigured-shop {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  padding: 40px;
  margin-top: 100px;
}

.una-welcome-message {
  text-align: center;
  max-width: 500px;
  padding: 40px;
  animation: fadeIn 0.5s ease-out;
}

.una-welcome-icon {
  font-size: 48px;
  color: var(--neon-cyan, #00ffff);
  margin-bottom: 24px;
  filter: drop-shadow(var(--glow-cyan));
  animation: floatIcon 3s ease-in-out infinite;
}

.una-welcome-message h2 {
  font-size: 28px;
  color: var(--text-color);
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.una-welcome-message p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 32px;
  letter-spacing: 0.5px;
}

.una-welcome-message .una-primary-button {
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
  padding: 12px 28px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: var(--glow-cyan);
}

.una-welcome-message .una-primary-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

