:root {
  --card-bg: rgba(13, 14, 26, 0.85);
  --card-bg-hover: rgba(20, 22, 40, 0.9);
  --card-border: rgba(0, 255, 157, 0.15);
  --card-border-hover: rgba(0, 255, 157, 0.3);
  --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  --header-border: rgba(0, 255, 255, 0.2);
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-tertiary: rgba(255, 255, 255, 0.5);
  --neon-green: #00ff9d;
  --neon-cyan: #00ffff;
  --neon-magenta: #ff00ff;
  --neon-green-glow: 0 0 5px rgba(0, 255, 157, 0.4);
  --neon-cyan-glow: 0 0 5px rgba(0, 255, 255, 0.4);
  --neon-magenta-glow: 0 0 10px rgba(255, 0, 255, 0.5);
  --status-success: rgba(0, 255, 157, 0.9);
  --status-warning: rgba(255, 165, 0, 0.9);
  --status-danger: rgba(255, 0, 85, 0.9);
  --button-bg: rgba(0, 255, 157, 0.1);
  --button-border: rgba(0, 255, 157, 0.3);
  --button-hover: rgba(0, 255, 157, 0.2);
  --badge-bg: rgba(0, 0, 0, 0.4);
  --input-bg: rgba(20, 22, 40, 0.7);
  --input-border: rgba(0, 255, 157, 0.2);
  --input-focus-border: rgba(0, 255, 255, 0.5);
  --tag-bg: rgba(0, 255, 255, 0.1);
  --tag-border: rgba(0, 255, 255, 0.3);
  --tag-text: var(--neon-cyan);
  --option-bg: rgba(255, 0, 255, 0.1);
  --option-border: rgba(255, 0, 255, 0.3);
  --option-text: var(--neon-magenta);
  --border-radius-sm: 4px;
  --border-radius-md: 6px;
  --border-radius-lg: 8px;
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
}

.una-product-details-container {
  padding: var(--spacing-md);
  max-width: 1400px;
  margin: 0 auto;
  font-family: 'Inter', 'Roboto', -apple-system, sans-serif;
}

/* Main container */
.una-product-hero {
  display: flex;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  overflow: hidden;
  grid-column: 1 / -1;
}

.una-product-hero-media {
  width: 40%;
  min-width: 300px;
  padding: var(--spacing-md);
  border-right: 1px solid var(--card-border);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.una-product-hero-info {
  flex: 1;
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

/* Product headline with title */
.una-product-headline {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  flex-wrap: wrap;
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid rgba(0, 255, 255, 0.1);
}

.una-product-title {
  color: var(--neon-cyan);
  text-shadow: var(--neon-cyan-glow);
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.5px;
  flex: 1;
}

.una-product-status-badge {
  background: var(--badge-bg);
  color: var(--text-secondary);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid rgba(0, 255, 255, 0.2);
}

/* PRODUCT META - horizontal layout in hero section */
.una-product-meta {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: var(--spacing-md);
}

.una-meta-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.una-meta-label {
  color: var(--text-secondary);
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.una-meta-value {
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
}

/* MEDIA SECTION */
.una-product-image-container {
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--card-border);
}

.una-product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.una-product-image-container:hover .una-product-image {
  transform: scale(1.03);
}

.una-product-no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  color: var(--text-tertiary);
  height: 100%;
  width: 100%;
}

.una-product-no-image svg {
  font-size: 2rem;
  opacity: 0.5;
}

/* Horizontal thumbnails row */
.una-product-thumbnails {
  display: flex;
  gap: var(--spacing-xs);
  flex-wrap: wrap;
}

.una-thumbnail {
  width: 45px;
  height: 45px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  opacity: 0.7;
}

.una-thumbnail.active {
  border-color: var(--neon-cyan);
  opacity: 1;
}

.una-thumbnail:hover {
  opacity: 1;
}

.una-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* DESCRIPTION in hero section */
.una-product-description {
  flex: 1;
  border-top: 1px solid rgba(0, 255, 255, 0.1);
  padding-top: var(--spacing-md);
}

.una-product-description h3 {
  color: var(--neon-cyan);
  font-size: 0.85rem;
  margin: 0 0 var(--spacing-sm) 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.una-description-content {
  color: var(--text-secondary);
  line-height: 1.4;
  font-size: 0.85rem;
  max-height: 120px;
  overflow-y: auto;
}

.una-description-content p,
.una-description-content li,
.una-description-content span {
  margin-bottom: var(--spacing-sm);
}

/* PRODUCT SIDEBAR */
.una-product-media {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

/* Tags section */
.una-product-tags-section {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
}

.una-product-tags-section h3 {
  color: var(--neon-cyan);
  font-size: 0.85rem;
  margin: 0 0 var(--spacing-sm) 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.una-product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.una-product-tag {
  background: var(--tag-bg);
  color: var(--neon-cyan);
  border: 1px solid var(--tag-border);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border-radius: 8px;
  font-size: 0.7rem;
}

/* MAIN CONTENT AREA */
.una-product-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

/* OPTIONS SECTION */
.una-options-section {
  background: var(--card-bg);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border);
}

.una-options-section h3 {
  color: var(--neon-cyan);
  font-size: 0.85rem;
  margin: 0 0 var(--spacing-sm) 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.una-options-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.una-option-group {
  min-width: 150px;
  margin-bottom: var(--spacing-sm);
  flex: 1;
}

.una-option-group h4 {
  color: var(--neon-magenta);
  margin: 0 0 var(--spacing-xs) 0;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
}

.una-option-values {
  display: flex;
  gap: var(--spacing-xs);
  flex-wrap: wrap;
}

.una-option-value {
  background: var(--option-bg);
  color: var(--neon-magenta);
  border: 1px solid var(--option-border);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border-radius: 6px;
  font-size: 0.7rem;
}

/* VARIANTS SECTION - More efficient grid */
.una-variants-section {
  background: var(--card-bg);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border);
}

.una-variants-section h3 {
  color: var(--neon-cyan);
  font-size: 0.85rem;
  margin: 0 0 var(--spacing-sm) 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

/* Variants in a table view for better readability */
.una-variants-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-sm);
  max-height: 350px;
  overflow-y: auto;
}

.una-variant-card {
  background: rgba(13, 14, 26, 0.7);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--card-border);
  padding: var(--spacing-sm);
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.una-variant-card:hover {
  border-color: rgba(0, 255, 157, 0.3);
}

.una-variant-card.out-of-stock {
  border-left: 3px solid var(--status-danger);
}

.una-variant-card.low-stock {
  border-left: 3px solid var(--status-warning);
}

.una-variant-card.in-stock {
  border-left: 3px solid var(--status-success);
}

.una-variant-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-sm);
}

.una-variant-title {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--text-primary);
  flex: 1;
}

.una-variant-stock {
  font-size: 0.7rem;
  padding: var(--spacing-xxs) var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  white-space: nowrap;
}

.una-variant-stock.out-of-stock {
  background: rgba(255, 0, 85, 0.1);
  color: var(--status-danger);
}

.una-variant-stock.low-stock {
  background: rgba(255, 165, 0, 0.1);
  color: var(--status-warning);
}

.una-variant-stock.in-stock {
  background: rgba(0, 255, 157, 0.1);
  color: var(--status-success);
}

.una-variant-content {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
}

.una-variant-price-group {
  display: flex;
  gap: var(--spacing-md);
  align-items: flex-start;
}

.una-price-main, .una-price-compare {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.una-price-label {
  color: var(--text-secondary);
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.una-price-value {
  color: var(--neon-green);
  font-size: 0.9rem;
  font-weight: 600;
}

.una-price-compare-value {
  color: var(--text-secondary);
  text-decoration: line-through;
  font-size: 0.8rem;
  text-shadow: none;
}

.una-variant-details {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm) var(--spacing-md);
}

.una-variant-detail-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  min-width: 100px;
}

.una-detail-label {
  color: var(--text-secondary);
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.una-detail-value {
  color: var(--text-primary);
  font-size: 0.8rem;
}

/* ERROR & LOADING STATES */
.una-product-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-lg);
  background: rgba(255, 0, 85, 0.05);
  border: 1px solid rgba(255, 0, 85, 0.2);
  border-radius: var(--border-radius-md);
  gap: var(--spacing-sm);
  margin: var(--spacing-lg) auto;
  max-width: 500px;
  text-align: center;
}

.una-product-error svg {
  color: var(--status-danger);
  font-size: 2rem;
  margin-bottom: var(--spacing-sm);
}

.una-product-error h2 {
  color: var(--status-danger);
  margin: 0;
  font-size: 1.2rem;
}

.una-product-error p {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-sm);
  font-size: 0.9rem;
}

.una-product-loading-screen {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin: var(--spacing-lg) auto;
  max-width: 1200px;
}

.una-loading-content {
  text-align: center;
  padding: var(--spacing-lg);
  width: 100%;
  max-width: 500px;
}

.una-loading-icons {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto var(--spacing-lg);
}

.una-loading-icons svg {
  position: absolute;
  width: 40px;
  height: 40px;
  filter: drop-shadow(0 0 5px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 20px;
  color: var(--neon-cyan);
  animation: floatIcon 2s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green);
  animation: floatIcon 2s infinite 0.5s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta);
  animation: floatIcon 2s infinite 1s;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--neon-cyan-glow);
  font-size: 1.2rem;
  margin-bottom: var(--spacing-lg);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-loading-progress {
  width: 100%;
  height: 3px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: var(--spacing-lg) 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  animation: progress 1.5s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
  animation: glow 1.5s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: var(--spacing-sm);
  margin-top: var(--spacing-lg);
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm);
  background: rgba(13, 14, 26, 0.7);
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: var(--text-secondary);
  font-size: 0.8rem;
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 0.9rem;
}

.una-metric-item:nth-child(1) { animation-delay: 0.1s; }
.una-metric-item:nth-child(2) { animation-delay: 0.2s; }
.una-metric-item:nth-child(3) { animation-delay: 0.3s; }
.una-metric-item:nth-child(4) { animation-delay: 0.4s; }

/* RESPONSIVE ADJUSTMENTS */
@media (max-width: 1024px) {
  .una-product-hero {
    flex-direction: column;
  }
  
  .una-product-hero-media {
    width: 100%;
    min-width: auto;
    border-right: none;
    border-bottom: 1px solid var(--card-border);
  }
  
  .una-product-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .una-variants-grid {
    grid-template-columns: 1fr;
  }
  
  .una-product-meta {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .una-product-meta {
    grid-template-columns: 1fr;
  }
}

@keyframes floatIcon {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes progress {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}

@keyframes glow {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}