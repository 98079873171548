.una-shopify-register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 20px;
}

.una-shopify-register-card {
  width: 100%;
  max-width: 480px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 30px;
}

.una-register-header {
  text-align: center;
  margin-bottom: 24px;
}

.una-register-header .una-logo {
  width: 160px;
  margin-bottom: 16px;
}

.una-register-header h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}

.una-shopify-badge {
  display: inline-flex;
  align-items: center;
  background-color: #96bf48;
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  margin-top: 8px;
}

.una-shopify-badge svg {
  margin-right: 6px;
}

.una-register-error-message {
  display: flex;
  align-items: center;
  background-color: #ffebee;
  color: #d32f2f;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

.una-register-error-message svg {
  margin-right: 8px;
  flex-shrink: 0;
}

.una-register-form {
  margin-bottom: 24px;
}

.una-form-group {
  margin-bottom: 20px;
}

.una-form-group label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
}

.una-input-icon {
  margin-right: 8px;
  color: #666;
}

.una-form-group input {
  width: 100%;
  padding: 12px 16px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f9fafc;
  transition: all 0.2s ease;
}

.una-form-group input:focus {
  outline: none;
  border-color: #96bf48;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(150, 191, 72, 0.15);
}

.una-input-error {
  border-color: #d32f2f !important;
  background-color: #fff8f8 !important;
}

.una-password-error {
  display: flex;
  align-items: center;
  color: #d32f2f;
  font-size: 13px;
  margin-top: 6px;
}

.una-password-error svg {
  margin-right: 6px;
  flex-shrink: 0;
}

.una-password-requirements {
  background-color: #f5f7fa;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 24px;
  font-size: 13px;
}

.una-password-requirements p {
  margin-bottom: 8px;
  color: #555;
}

.una-password-requirements ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.una-password-requirements li {
  display: flex;
  align-items: center;
  color: #777;
  margin-bottom: 4px;
}

.una-password-requirements li svg {
  margin-right: 8px;
  font-size: 14px;
}

.una-requirement-met {
  color: #4caf50 !important;
}

.una-requirement-met svg {
  color: #4caf50;
}

.una-register-button {
  width: 100%;
  padding: 14px;
  background-color: #96bf48;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.una-register-button:hover {
  background-color: #85aa3d;
}

.una-register-button:disabled {
  background-color: #b8b8b8;
  cursor: not-allowed;
}

.una-button-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.una-loading-spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  margin-right: 10px;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.una-register-footer {
  text-align: center;
  font-size: 13px;
  color: #666;
}

.una-register-footer p {
  margin-bottom: 12px;
}

.una-help-links {
  display: flex;
  justify-content: center;
}

.una-help-links a {
  color: #96bf48;
  text-decoration: none;
  font-weight: 500;
}

.una-help-links a:hover {
  text-decoration: underline;
}

.una-shopify-register-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
}

.una-shopify-register-error .error-icon {
  font-size: 48px;
  color: #d32f2f;
  margin-bottom: 16px;
}

.una-shopify-register-error h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}

.una-shopify-register-error p {
  font-size: 16px;
  max-width: 400px;
  margin-bottom: 24px;
  color: #666;
}

.una-return-button {
  padding: 12px 24px;
  background-color: #96bf48;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.una-return-button:hover {
  background-color: #85aa3d;
}