/* Carrier-specific neon colors */
:root {
    --mondial-relay-color: var(--neon-magenta);
    --mondial-relay-light: rgba(233, 30, 99, 0.1);
    --colis-prive-color: var(--neon-purple);
    --colis-prive-light: rgba(156, 39, 176, 0.1);
    --colissimo-color: var(--neon-orange);
    --colissimo-light: rgba(255, 193, 7, 0.1);
    --chronopost-color: var(--neon-cyan);
    --chronopost-light: rgba(0, 188, 212, 0.1);
    --amazon-color: var(--neon-blue);
    --amazon-light: rgba(33, 150, 243, 0.1);
}

.carriers-conversation {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 0;
    flex: 1 1 auto;
    backdrop-filter: blur(10px);
    overflow: hidden;
    margin-bottom: 0;
}

.carriers-conversation-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    min-height: 0;
}

.carriers-conversation-messages {
    flex: 1;
    padding: 20px;
    position: relative;
    min-height: 0;
}

.carriers-conversation-messages::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
    pointer-events: none;
}

.carriers-conversation-message {
    max-width: 85%;
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
}

.carriers-conversation-message--sent {
    background: var(--mondial-relay-light);
    box-shadow: var(--glow-magenta);
    align-self: flex-end;
}

.carriers-conversation-message--received {
    background: rgba(26, 26, 46, 0.6);
    box-shadow: var(--glow-cyan);
    align-self: flex-start;
}

.carriers-conversation-message--final_response {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
    align-self: flex-end;
}

.carriers-conversation-message__sender {
    font-size: 12px;
    font-weight: 500;
    color: var(--neon-cyan);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-shadow: var(--glow-cyan);
}

.carriers-conversation-message__content {
    margin-bottom: 8px;
    line-height: 1.4;
    word-break: break-word;
    font-size: 14px;
}

.carriers-conversation-message__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #6c757d;
    margin-top: 4px;
}

.carriers-conversation-message__timestamp {
    color: #888;
}

.carriers-conversation-message__validation {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.carriers-conversation-tracking {
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgba(26, 26, 46, 0.95);
    padding: 8px 16px;
    margin-bottom: 16px;
    text-align: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
}

.tracking-badge {
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 12px;
    color: var(--text-color);
    letter-spacing: 1px;
    text-transform: uppercase;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    box-shadow: var(--glow-cyan);
}

.tracking-badge.carrier {
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    color: var(--neon-cyan);
}

.carriers-conversation-params {
    padding: 20px;
    background: rgba(26, 26, 46, 0.6);
    border-top: 1px solid var(--neon-cyan);
    backdrop-filter: blur(10px);
}

.param-item {
    margin: 10px 0;
    padding: 12px;
    background: rgba(26, 26, 46, 0.4);
    border: 1px solid rgba(0, 255, 255, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    transition: all 0.3s ease;
}

.param-item:hover {
    background: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
}

.param-label {
    min-width: 120px;
    font-weight: 500;
    font-size: 12px;
    color: var(--neon-magenta);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-magenta);
}

.param-value {
    flex: 1;
    word-break: break-word;
    font-size: 14px;
    color: var(--text-color);
}

.param-input {
    width: 100%;
    padding: 10px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
}

.param-input:focus {
    outline: none;
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    background: rgba(26, 26, 46, 0.9);
}

.tracking-badge.carrier.carrier-mondial-relay {
    background-color: var(--mondial-relay-light);
    color: var(--mondial-relay-color);
    border-color: var(--mondial-relay-color);
}

.tracking-badge.carrier.carrier-colis-prive {
    background-color: var(--colis-prive-light);
    color: var(--colis-prive-color);
    border-color: var(--colis-prive-color);
}

.tracking-badge.carrier.carrier-colissimo {
    background-color: var(--colissimo-light);
    color: var(--colissimo-color);
    border-color: var(--colissimo-color);
}

.tracking-badge.carrier.carrier-chronopost {
    background-color: var(--chronopost-light);
    color: var(--chronopost-color);
    border-color: var(--chronopost-color);
}

.tracking-badge.carrier.carrier-amazon {
    background-color: var(--amazon-light);
    color: var(--amazon-color);
    border-color: var(--amazon-color);
}

.carrier-mondial-relay .carriers-conversation-message--sent {
    background-color: var(--mondial-relay-light);
    margin-left: 20%;
}

.carrier-colis-prive .carriers-conversation-message--sent {
    background-color: var(--colis-prive-light);
    margin-left: 20%;
}

.carrier-colissimo .carriers-conversation-message--sent {
    background-color: var(--colissimo-light);
    margin-left: 20%;
}

.carrier-chronopost .carriers-conversation-message--sent {
    background-color: var(--chronopost-light);
    margin-left: 20%;
}

.carrier-amazon .carriers-conversation-message--sent {
    background-color: var(--amazon-light);
    margin-left: 20%;
}

.edit-actions {
    margin-top: 15px;
    display: flex;
    gap: 12px;
    padding: 15px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 20px;
    box-shadow: var(--glow-cyan);
    backdrop-filter: blur(10px);
}

.message-edit-textarea {
    width: 100%;
    min-height: 40px;
    max-height: 120px;
    padding: 12px 15px;
    background: rgba(26, 26, 46, 0.7);
    border: 1px solid var(--neon-cyan);
    border-radius: 12px;
    resize: none;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color);
    transition: all 0.3s ease;
    box-shadow: var(--glow-cyan);
}

.message-edit-textarea:focus {
    outline: none;
    background: rgba(26, 26, 46, 0.9);
    border-color: var(--neon-cyan);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
}

.scrollable {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
}

.params-content {
    padding-bottom: 10px;
}


.carrier-response__header {
    font-weight: 500;
    color: var(--neon-cyan);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-cyan);
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.carrier-response__meta {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
}

.carrier-response__date,
.carrier-response__status {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 8px;
    background: rgba(26, 26, 46, 0.4);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 255, 0.1);
}

.carrier-response__label {
    font-weight: 500;
    color: var(--neon-magenta);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-magenta);
    min-width: 80px;
}

.carrier-response__details {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(0, 255, 255, 0.2);
}

.carrier-response__details-label {
    font-weight: 500;
    color: var(--neon-green);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-green);
    margin-bottom: 8px;
}

.carrier-response__details-content {
    line-height: 1.6;
    color: var(--text-color);
    font-size: 14px;
    padding: 12px;
    background: rgba(26, 26, 46, 0.4);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 255, 0.1);
}
