/* Marketplace.css - Cyberpunk UI styling */
.una-marketplace-main-container {
  padding: 24px;
  background-color: rgba(13, 14, 26, 0.7);
  min-height: 100vh;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  box-sizing: border-box;
  color: #fff;
}

/* Header Section */
.una-marketplace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.una-marketplace-header h2 {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 1.8rem;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.una-marketplace-header-actions {
  display: flex;
  gap: 16px;
}

.una-header-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
  border-radius: 12px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.una-header-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  transform: translateY(-2px);
}

/* Filters and Content Layout */
.una-marketplace-filters-container {
  display: flex;
  gap: 24px;
}

.una-filter-panel {
  width: 240px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 255, 157, 0.1);
  border: 1px solid rgba(0, 255, 157, 0.1);
  align-self: flex-start;
  position: sticky;
  top: 24px;
}

.una-filter-panel h3 {
  color: var(--neon-cyan, #00ffff);
  font-size: 1.2rem;
  margin: 0 0 20px 0;
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 0.5px;
}

.una-filter-section {
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
}

.una-filter-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.una-filter-section h4 {
  font-size: 16px;
  color: #fff;
  margin: 0 0 16px 0;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-categories-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 8px;
}

.una-categories-list::-webkit-scrollbar {
  width: 5px;
}

.una-categories-list::-webkit-scrollbar-track {
  background: rgba(13, 14, 26, 0.5);
  border-radius: 3px;
}

.una-categories-list::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 157, 0.3);
  border-radius: 3px;
}

.una-categories-list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 255, 157, 0.5);
}

.una-category-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  transition: all 0.2s ease;
  padding: 8px 12px;
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.una-category-checkbox:hover {
  background: rgba(0, 255, 157, 0.05);
  border-color: rgba(0, 255, 157, 0.15);
  color: #fff;
}

.una-category-checkbox input {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(0, 255, 157, 0.4);
  background: rgba(13, 14, 26, 0.7);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.una-category-checkbox input:checked {
  background: var(--neon-green, #00ff9d);
  border-color: var(--neon-green, #00ff9d);
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
}

.una-category-checkbox input:checked:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid rgba(13, 14, 26, 0.9);
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 6px;
  transform: rotate(45deg);
}

.una-star-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(13, 14, 26, 0.6);
  border: 1px solid rgba(255, 215, 0, 0.3);
  color: rgba(255, 215, 0, 0.6);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 20px;
  position: relative;
  margin: 10px auto;
}

.una-star-toggle:hover {
  background: rgba(13, 14, 26, 0.8);
  border-color: rgba(255, 215, 0, 0.5);
  color: rgba(255, 215, 0, 0.8);
  transform: scale(1.05);
}

.una-star-toggle.active {
  background: rgba(13, 14, 26, 0.9);
  border-color: #FFD700;
  color: #FFD700;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.6), 0 0 30px rgba(255, 215, 0, 0.3);
}

.una-star-toggle-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.una-filter-section.star-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.una-marketplace-content {
  flex: 1;
}

/* Toolbar (Search and Sort) */
.una-marketplace-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 16px 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.una-search-container {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  max-width: 400px;
  min-width: 0;
}

.una-search-input {
  width: 100%;
  padding: 12px 16px 12px 42px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.una-search-input:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-search-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.una-search-button {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--neon-cyan, #00ffff);
  font-size: 16px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.una-search-button:hover {
  opacity: 1;
}

.una-sort-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.una-sort-label {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--neon-cyan, #00ffff);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.una-sort-select {
  padding: 10px 16px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  min-width: 180px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.una-sort-select:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

/* Table */
.una-marketplace-table-container {
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 255, 157, 0.1);
  margin-bottom: 24px;
  overflow-x: auto;
}

.una-marketplace-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.una-marketplace-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(13, 14, 26, 0.95);
}

.una-marketplace-table th {
  text-align: left;
  padding: 16px;
  color: var(--neon-cyan, #00ffff);
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(0, 255, 255, 0.2);
  white-space: nowrap;
  font-size: 13px;
  text-transform: uppercase;
}

.una-marketplace-table td {
  padding: 16px;
  background: rgba(13, 14, 26, 0.5);
  color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 255, 157, 0.05);
  text-align: left;
  vertical-align: middle;
  transition: background 0.2s ease;
}

.una-marketplace-table tr:hover td {
  background: rgba(0, 255, 157, 0.05);
  cursor: pointer;
}

.una-product-image-cell {
  position: relative;
  width: 80px;
}

.una-product-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.una-marketplace-table tr:hover .una-product-thumbnail {
  transform: scale(1.1);
}

.una-product-no-image {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.una-featured-indicator {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #FFD700;
  font-size: 16px;
  filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.5));
}

.una-product-title {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 6px;
  letter-spacing: 0.3px;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.una-commission-rate {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green, #00ff9d);
  padding: 5px 12px;
  border-radius: 12px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  border: 1px solid rgba(0, 255, 157, 0.2);
  text-shadow: 0 0 5px rgba(0, 255, 157, 0.3);
  letter-spacing: 0.5px;
}

.una-table-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.una-category-pill {
  background: rgba(121, 80, 242, 0.1);
  color: #9d75ff;
  padding: 4px 10px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  letter-spacing: 0.5px;
  border: 1px solid rgba(121, 80, 242, 0.2);
  white-space: nowrap;
  transition: all 0.2s ease;
}

.una-category-pill:hover {
  background: rgba(121, 80, 242, 0.2);
  box-shadow: 0 0 8px rgba(121, 80, 242, 0.3);
}

.una-expiry-date {
  color: var(--neon-cyan, #00ffff);
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: 0.3px;
}

.una-no-expiry {
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  font-size: 13px;
  letter-spacing: 0.3px;
}

/* Action Buttons */
.una-action-button {
  padding: 8px 12px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.una-view-button {
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
}

.una-view-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
  transform: translateY(-2px);
}

.una-select-button {
  background: rgba(0, 255, 157, 0.15);
  border: 1px solid var(--neon-green, #00ff9d);
  color: var(--neon-green, #00ff9d);
  margin-left: 8px;
}

.una-select-button:hover {
  background: rgba(0, 255, 157, 0.25);
  box-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
  transform: translateY(-2px);
}

/* Pagination */
.una-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 16px 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 255, 157, 0.1);
}

.una-pagination-info {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  letter-spacing: 0.3px;
}

.una-pagination-controls {
  display: flex;
  gap: 8px;
}

.una-pagination-button {
  background: transparent;
  border: 1px solid var(--neon-cyan, #00ffff);
  color: var(--neon-cyan, #00ffff);
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.una-pagination-button:hover:not(:disabled) {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: rgba(0, 255, 255, 0.3);
}

.una-pagination-button.active {
  background: var(--neon-cyan, #00ffff);
  color: rgba(13, 14, 26, 0.9);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.4);
  border-color: var(--neon-cyan, #00ffff);
  font-weight: 700;
}

.una-pagination-per-page {
  display: flex;
  align-items: center;
  gap: 8px;
}

.una-pagination-per-page select {
  padding: 8px 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  background: rgba(13, 14, 26, 0.5);
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.una-pagination-per-page select:focus {
  outline: none;
  border-color: var(--neon-cyan, #00ffff);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-pagination-per-page span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  white-space: nowrap;
}

/* Loading and Empty States */
.una-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  gap: 20px;
}

.una-loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 255, 157, 0.1);
  border-top: 3px solid var(--neon-green, #00ff9d);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.una-loading-text {
  color: var(--neon-green, #00ff9d);
  font-size: 16px;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
}

.una-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  background: linear-gradient(180deg, rgba(13, 14, 26, 0.9) 0%, rgba(19, 21, 40, 0.8) 100%);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.una-empty-state svg {
  font-size: 3rem;
  margin-bottom: 20px;
  color: var(--neon-cyan, #00ffff);
  opacity: 0.8;
}

.una-empty-state p {
  font-size: 18px;
  margin-bottom: 0;
  max-width: 400px;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .una-marketplace-filters-container {
    flex-direction: column;
  }
  
  .una-filter-panel {
    width: 100%;
    position: static;
  }
  
  .una-categories-list {
    max-height: 200px;
  }
}

@media (max-width: 992px) {
  .una-marketplace-toolbar {
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .una-search-container {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .una-marketplace-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .una-marketplace-header-actions {
    width: 100%;
    justify-content: space-between;
  }
  
  .una-pagination-container {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  
  .una-pagination-controls {
    order: 1;
  }
  
  .una-pagination-info {
    order: 2;
  }
  
  .una-pagination-per-page {
    order: 3;
  }
}

@media (max-width: 576px) {
  .una-marketplace-main-container {
    padding: 16px;
  }
  
  .una-header-button {
    padding: 8px 12px;
    font-size: 12px;
  }
  
  .una-pagination-button {
    padding: 6px 10px;
    min-width: 32px;
    font-size: 13px;
  }
}