/* UserParameterRedesign.css */

:root {
  --primary-color: #00ff9d;
  --primary-light: #00ffff;
  --primary-dark: #1a1a2e;
  --danger-color: #ff00ff;
  --success-color: #00ff9d;
  --border-color: rgba(0, 255, 157, 0.2);
}

.user-parameter-container {
  padding: 30px;
  min-height: 100vh;
}

.user-parameter-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

/* Left Column */
.user-parameter-left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.user-info-section {
  padding: 30px;
}

.user-info-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 25px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
}

.info-icon {
  color: var(--neon-cyan);
  font-size: 24px;
  filter: drop-shadow(var(--glow-cyan));
}

.change-password-button {
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  padding: 12px 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
}

.change-password-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

/* Channels Section */
.integrations-section {
  border-radius: 20px;
  padding: 30px;
}

.integration-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.channel-circle {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.channel-circle:hover {
  transform: translateY(-2px);
  box-shadow: var(--glow-cyan);
}

.channel-circle.connected::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--neon-green);
  animation: pulse 2s infinite;
}

/* Update channel-specific colors to neon variants */
.channel-circle.instagram svg {
  color: #ff00ff;
  filter: drop-shadow(var(--glow-magenta));
}

.channel-circle.google svg {
  color: var(--neon-cyan);
  filter: drop-shadow(var(--glow-cyan));
}

.channel-circle.whatsapp svg {
  color: var(--neon-green);
  filter: drop-shadow(var(--glow-green));
}

.channel-circle.chatbot svg {
  color: var(--neon-magenta);
  filter: drop-shadow(var(--glow-magenta));
}

.channel-circle.wms svg {
  color: var(--neon-cyan);
  filter: drop-shadow(var(--glow-cyan));
}

.channel-circle.outlook {
  background-color: #0078d4;
  color: white;
}

.channel-circle.outlook:hover {
  background-color: #106ebe;
}

.channel-circle.outlook.connected {
  background-color: #106ebe;
}

.channel-label {
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* Right Column */
.user-parameter-right {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 20px;
  padding: 30px;
  box-shadow: var(--glow-green);
}

/* Tabs Container */
.tabs-header {
  display: flex;
  gap: 2px;
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(0, 255, 157, 0.2);
  width: 100%;
  table-layout: fixed;
}

/* Make tabs equal width */
.tab-button {
  flex: 1 0 auto;  /* Changed from flex: 1 */
  width: 0;  /* This helps with equal distribution */
  min-width: max-content;  /* This ensures the tab takes the width of its content */
  padding: 15px 30px;
  background: transparent;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  white-space: nowrap;  /* Prevents text wrapping */
  text-align: center;   /* Centers the text */
}

.tab-button:hover {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.tab-button.active {
  color: var(--neon-green);
  border-bottom-color: var(--neon-green);
  text-shadow: var(--glow-green);
}

/* Form elements within tabs */
.tab-content .form-group {
  margin-bottom: 20px;
}

.tab-content label {
  display: block;
  margin-bottom: 8px;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9em;
  text-shadow: var(--glow-cyan);
}

.tab-content input {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 10px;
  color: #fff;
  transition: all 0.3s ease;
}

.tab-content input:focus {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
  outline: none;
}

/* Danger Zone */
.danger-zone {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 0, 255, 0.2);
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.2);
}

.delete-account-button {
  width: 100%;
  background: transparent;
  border: 1px solid var(--neon-magenta);
  color: var(--neon-magenta);
  padding: 12px 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-shadow: var(--glow-magenta);
}

.delete-account-button:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .user-parameter-content {
    grid-template-columns: 1fr;
  }
}


.edn-status {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
}

.edn-status p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.edn-account {
  display: inline-block;
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  margin-left: 8px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  text-shadow: var(--glow-cyan);
}

.edn-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.edn-connect {
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  text-shadow: var(--glow-green);
}

.edn-connect:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.edn-disconnect {
  background: transparent;
  border: 1px solid var(--neon-magenta);
  color: var(--neon-magenta);
  text-shadow: var(--glow-magenta);
}

.edn-disconnect:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

/* EDN Form Modal */
.edn-form {
  background: var(--bg-card);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 20px;
  padding: 25px;
  box-shadow: var(--glow-green);
}

.edn-form .form-group {
  margin-bottom: 20px;
}

.edn-form label {
  display: block;
  margin-bottom: 8px;
  color: var(--neon-cyan);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: var(--glow-cyan);
}

.edn-form input {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 10px;
  color: #fff;
  transition: all 0.3s ease;
}

.edn-form input:focus {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

/* Endpoint Section */
.endpoint-section {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.endpoint-form,
.endpoint-advanced-testing {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 20px;
  padding: 25px;
  box-shadow: var(--glow-green);
}

.endpoint-test-content {
  padding: 0;
  background: none;
  box-shadow: none;
}

.endpoint-test-content .form-group {
  margin-bottom: 20px;
}

.endpoint-status {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.endpoint-url-display {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  padding: 12px;
  margin: 10px 0;
  font-family: monospace;
  word-break: break-all;
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.2);
  text-shadow: var(--glow-cyan);
}

.endpoint-actions {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.endpoint-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 150px;
  justify-content: center;
}

.endpoint-button.primary {
  background-color: var(--primary-color);
  color: white;
}

.endpoint-button.primary:hover {
  background-color: var(--primary-dark);
}

.endpoint-button.secondary {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.endpoint-button.secondary:hover {
  background-color: var(--primary-light);
  color: white;
}

.endpoint-test-result {
  margin-top: 20px;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.endpoint-test-result.success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
}

.endpoint-test-result.error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  color: #ff4d4f;
}

.endpoint-form-group {
  margin-bottom: 20px;
}

.endpoint-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
}

.endpoint-form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.endpoint-form-group input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.1);
}

/* Carriers Table Section */
.carriers-section {
  padding: 25px;
}

.carriers-table-container {
  background: var(--bg-card);
  border-radius: 20px;
  box-shadow: var(--glow-green);
  overflow: hidden;
  border: 1px solid rgba(0, 255, 157, 0.1);
  backdrop-filter: blur(10px);
}

.carriers-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 400px; /* Reduced from 600px since we're removing columns */
}

.carriers-table th {
  background: rgba(13, 14, 26, 0.95);
  padding: 16px;
  text-align: left;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  text-shadow: var(--glow-cyan);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.carriers-table td {
  padding: 16px;
  color: #fff;
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
}

.carriers-table tr {
  transition: all 0.3s ease;
}

.carriers-table tr:hover {
  background: rgba(0, 255, 157, 0.05);
  box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.1);
}

.carriers-table .actions-cell {
  width: 100px;
  text-align: right;
  white-space: nowrap;
}

.icon-button {
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon-button.edit {
  color: var(--neon-cyan);
}

.icon-button.edit:hover {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.icon-button.delete {
  color: var(--neon-magenta);
}

.icon-button.delete:hover {
  color: var(--neon-magenta);
  text-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

.add-carrier-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.add-carrier-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

/* Status Indicators */
.carrier-status {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.carrier-status.active {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
  text-shadow: var(--glow-green);
}

.carrier-status.inactive {
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
  border: 1px solid rgba(255, 0, 255, 0.2);
  text-shadow: var(--glow-magenta);
}

/* Carrier Modal */
.carrier-modal {
  background: var(--bg-card);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 20px;
  padding: 30px;
  box-shadow: var(--glow-green);
}

.carrier-modal-header {
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-cyan);
  margin-bottom: 20px;
}

.carrier-form-group {
  margin-bottom: 20px;
}

.carrier-form-group label {
  display: block;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  text-shadow: var(--glow-cyan);
}

.carrier-form-group input,
.carrier-form-group select {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 10px;
  color: #fff;
  transition: all 0.3s ease;
}

.carrier-form-group input:focus,
.carrier-form-group select:focus {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

/* Optional: Add a minimum width to ensure tabs don't get too narrow */
.tabs-container {
  min-width: min-content;  /* Ensures container is at least as wide as the widest tab */
  width: 100%;
}

.billing-info-section {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.billing-info-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.manage-billing-button {
  background: linear-gradient(45deg, var(--neon-cyan), var(--neon-green));
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.manage-billing-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.3);
}

.manage-billing-button svg {
  font-size: 1.2em;
}

/* Add these styles to your existing CSS */

.credentials-display {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  padding: 15px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.credential-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.credential-label {
  color: var(--neon-cyan);
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: var(--glow-cyan);
  min-width: 80px;
}

.credential-value {
  background: rgba(255, 255, 255, 0.05);
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid rgba(0, 255, 157, 0.2);
  color: var(--neon-green);
  font-family: monospace;
  text-shadow: var(--glow-green);
  flex: 1;
}

.password-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.icon-button.eye {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon-button.eye:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.icon-button.edit-credentials {
  align-self: flex-end;
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon-button.edit-credentials:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.icon-button.add-credentials {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon-button.add-credentials:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

